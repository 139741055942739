import React from 'react';
import PropTypes from 'prop-types';
import MagicForm from '../components/magicForm';

class SocialReasonContainer extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		placeUpdate : PropTypes.func.isRequired,
	};

	constructor(props, context) {
		super(props, context);
		this.formFromReducer = this.formFromReducer.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onCancelEdit = this.onCancelEdit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.state = {
			editing : false,
			form : this.formFromReducer()
		};
	}

	formFromReducer(){
		return {
			name : this.context.place.socialReason.name || ""
			,address : this.context.place.socialReason.address || ""
			,zipCode : this.context.place.socialReason.zipCode || ""
			,city : this.context.place.socialReason.city || ""
		};
	}


	onChange(event){
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = event.target.value;
		this.setState(newState);
	}

	onEdit(event){
		event.preventDefault();
		this.setState({editing:true});

	}

	onCancelEdit(event){
		event.preventDefault();
		let newState = Object.assign({}, this.state);
		newState.editing = false;
		newState.form = this.formFromReducer();
		this.setState(newState);
	}

	onSubmit(event){
		event.preventDefault();
		let data = {
			name : this.state.form.name.trim(),
			address : this.state.form.address.trim(),
			zipCode : this.state.form.zipCode.trim(),
			city : this.state.form.city.trim(),
		};

		this.context.placeUpdate({socialReason : data})
		.then(reponse =>{
			this.setState({editing:false});
		})
		.catch(e => {
			console.error(e, e.stack);
		});
	}
	render() {
		return <SocialReason
					editing = {this.state.editing}
					onCancelEdit = {this.onCancelEdit}
					onSubmit = {this.onSubmit}
					onEdit = {!this.props.readOnly && this.onEdit}
					onChange = {this.onChange}
					name = {this.state.form.name}
					address = {this.state.form.address}
					zipCode = {this.state.form.zipCode}
					city = {this.state.form.city}
				/>;
	}
}

const SocialReason = props => {
	let title = props.fullname + ", " + props.job;
	let maxLength = 30;
	title.length > maxLength && (title = title.substring(0, maxLength) + "[...]");

	return (
		<section className="club-social-reason">
			<MagicForm
				title="Adresse de facturation"
				editing = {props.editing}
				onCancelEdit = {props.onCancelEdit}
				onSubmit = {props.onSubmit}
				onEdit={props.onEdit}
			>
				<div>
					<label>
						<span className="picto-club">Raison sociale</span>
						<input onChange = {props.onChange} type="text" name="name" value={props.name} placeholder="Raison sociale"/>
					</label>
					<label>
						<span className="picto-address">Adresse</span>
						<input onChange = {props.onChange} type="text" name="address" value={props.address} placeholder="Adresse"/>
						<div className="inputs-group">

							<input onChange = {props.onChange} type="text" name="zipCode" value={props.zipCode} placeholder="Code postal"/>

							<input onChange = {props.onChange} type="text" name="city" value={props.city} placeholder="Ville"/>
						</div>
					</label>
				</div>
			</MagicForm>
		</section>
	);
};

export default SocialReasonContainer;
