import React from 'react';

export default class FacebookOAuthRedirect extends React.Component{

	componentDidMount(){
		let origin = window.location.origin;
		if (!origin) {
			origin = window.location.protocol + "//"
				+ window.location.hostname
				+ (window.location.port ? ':' + window.location.port : '');
		}
		let baseUrl = origin;

		var hash = {};
		window.location.hash.split('&').forEach(p=>{
			var kv = p.replace(/^#/,'').split('=');
			hash[kv[0]] = kv[1];
		});

		if(!hash.state || !hash.access_token){
			return window.history.replaceState({}, "", `${baseUrl}/club/`);
		}

		var clubId = atob(decodeURIComponent(hash.state));
		let fullUrl = `${baseUrl}/club/${clubId}/avis/facebook?init#access_token=${hash.access_token}`
		window.location.href = fullUrl;
	}
	render(){
		return <p>Wait for redirection from Facebook</p>;
	}
}

