import React from 'react';

export default props => {
	let disabled = !props.editing;

	return (
		<form className={`sepaForm`}>
			<fieldset disabled={disabled}>
				{props.sepaOwner &&
				<label>
					<span>Titulaire</span>
					<input className={props.sepaOwner.invalid ? "inputInvalid" :""} type="text" name="sepaOwner" placeholder="Titulaire du compte" readOnly={disabled} value={props.sepaOwner.value} onChange={props.onChange} />
				</label>}
				{props.socialReasonName && <label>
					<span>Raison sociale</span>
					<input className={props.socialReasonName.invalid ? "inputInvalid" :""} type="text" name="socialReasonName" placeholder="Raison sociale" readOnly={disabled} value={props.socialReasonName.value} onChange={props.onChange} />
				</label>}
				{props.sepaIban && <label>
					<span>IBAN</span>
					<input className={props.sepaIban.invalid ? "inputInvalid" :""} type="text" name="sepaIban" placeholder="FRXX XXXX XXXX XXXX XXXX XXXX XXX" readOnly={disabled} value={props.sepaIban.value} onChange={props.onChange} />
				</label>}
				{props.onClick && <input type="submit" className="button-primary" value="Valider" onClick={props.onClick}/>}
			</fieldset>
		</form>
	)
}
