import React, { useState, useEffect, useContext, useCallback, Fragment } from 'react';
import { PlaceContext } from '../react_contexts';
import api from '../utils/api';
import MagicForm from '../components/magicForm';
import ScheduleDay from '../containers/ScheduleDay';
import Tooltip from '../components/Tooltip';
import { days, generateScheduleList } from '../utils/days.js';

import whiteAddIcon from '../static/icons/picto-add--white.svg';
import cancelIcon from '../static/icons/picto-cancel.svg';
import deleteIcon from '../static/icons/picto-delete.svg';
import whiteEditIcon from '../static/icons/picto-edit--white.svg';
import infoIcon from '../static/icons/picto-info.svg';


function micropriceToPrice(microprice) {
	return microprice / 1000000;
}

function durationToMin(duration) {
	return duration / 60;
}

const WorkingHours = ({ clubOpeningHours, refWorkingHours }) => {


	let hoursList = generateScheduleList(0, 24);

	let tempTable = days.map((day) => {
		let schedule = clubOpeningHours ? clubOpeningHours[day.enum] : null;
		return <ScheduleDay key={`schedule-${day.enum}`} schedule={schedule}
			ref={(r) => { refWorkingHours[day.enum] = r }}
			dayName={day.display}
			hoursList={hoursList} />
	});

	return <Fragment>
		<table>
			<thead>
				<tr>
					<th></th>
					<th colSpan="2">Créneau 1</th>
					<th colSpan="2">Créneau 2</th>
				</tr>
				<tr>
					<th>Jour</th>
					<th>de</th>
					<th>à</th>
					<th>de</th>
					<th>à</th>
				</tr>
			</thead>
			<tbody>
				{tempTable}
			</tbody>
		</table>
	</Fragment>
}

const ServiceModal = (props) => {
	const [state, setState] = useState({
		step: 'SERVICE_STEP_1',
		values: {},
		errorStep1: null,
		errorStep2: null
	})

	function onParamChange(e) {
		state.values[e.target.name] = e.target.value;
		setState(state);
	}


	const validateStep1 = () => {
		let values = state.values;
		let body = {
			microprice: values.microprice ? values.microprice * 1000000 : 0,
			duration: values.duration ? values.duration * 60 : 0,
			name: values.name ? values.name : undefined,
			description: values.description ? values.description : undefined,
			spots_total: parseInt(values.spots_total),
			full: false
		}

		setState({ ...state, step: 'SERVICE_SAVING_1', errorStep1: null });
		api.POST(`/api/lead/place/${props.selectPlace}/reserve/service`, body)
			.then(() => api.GET(`/user/me/club/${props.selectPlace}/schedule`))
			.then(res => {
				return setState({ ...state, step: 'SERVICE_STEP_2', openingHours: res });
			})
			.catch(error => {
				console.error(error);
				setState({ ...state, step: 'SERVICE_STEP_1', errorStep1: error })
			})
	}

	const validateStep2 = () => {
		let values = state.values;
		let workingHours = {};
		days.forEach(day => {
			workingHours[day.enum] = refWorkingHours[day.enum].stateToJson();
		});
		let body = {
			microprice: values.microprice ? values.microprice * 1000000 : 0,
			duration: values.duration ? values.duration * 60 : 0,
			name: values.name ? values.name : undefined,
			description: values.description ? values.description : undefined,
			workingHours: workingHours,
			spots_total: parseInt(values.spots_total),
			full: true
		}

		setState({ ...state, step: 'SERVICE_SAVING_2', errorStep2: null });
		api.POST(`/api/lead/place/${props.selectPlace}/reserve/service`, body)
			.then(() => {
				props.closeModal();
				props.reloadTable();
			})
			.catch(error => {
				setState({ ...state, step: 'SERVICE_STEP_2', errorStep2: error })
			})
	}

	const goToStep1 = () => {
		setState({ ...state, step: 'SERVICE_STEP_1' })
	}

	let refWorkingHours = {};
	let title, body;
	switch (state.step) {
		case 'SERVICE_STEP_2':
			title = `Renseignez les periodes d'activités`
			body = <Fragment>
				{state.errorStep2 && <p>Une erreur est survenue, veuillez saisir les créneaux avec une heure de début et une heure de fin</p>}
				<WorkingHours refWorkingHours={refWorkingHours} clubOpeningHours={state.openingHours} />
				<div>
					<button className="button-secondary" onClick={goToStep1}>Précedent</button>
					<button className="button-primary" onClick={validateStep2}>Suivant</button>
				</div>
			</Fragment>
			break
		case 'SERVICE_SAVING_1':
			title = 'Chargement'
			body = <div>Validation des données</div>
			break
		case 'SERVICE_SAVING_2':
			title = 'Enregistrement'
			body = <div>Enregistrement en cours</div>
			break
		case 'SERVICE_STEP_1':
		default:
			title = 'Ajouter une offre';
			body = <form>
				{state.errorStep1 && <p>Une erreur est survenue, veuillez saisir l'ensemble des champs</p>}
				<div className="inputs-group">
					<label>
						<span>Nom de l'offre<Tooltip text={`Le nom l’offre ne peut contenir sa durée . Par ex : un cours de fitness d'une 1 heure ne peut pas s'appeler "Cours de fitness d'1h" mais peut s'appeler cours de fitness et avoir une durée de 60 minutes`} position="right"><img src={infoIcon} alt="i" /></Tooltip></span>
						<input type="text" name="name" key="name" defaultValue={state.values.name} onChange={onParamChange} />
					</label>
				</div>
				<div className="inputs-group">
					<label>
						<span>Description<Tooltip text={`La description de l’offre doit être différente du nom de l’offre`} position="right"><img src={infoIcon} alt="i" /></Tooltip></span>
						<textarea type="text" name="description" key="description" defaultValue={state.values.description} onChange={onParamChange} />
					</label>
				</div>
				<div className="inputs-group">
					<label>
						<span>Prix (en €)</span>
						<input type="number" name="microprice" min="1" key="microprice" defaultValue={state.values.microprice} onChange={onParamChange} />
					</label>
					<label>
						<span>Durée (en minutes)</span>
						<input type="number" name="duration" min="15" step="5" key="duration" defaultValue={state.values.duration} onChange={onParamChange} />
					</label>
					<label>
						<span>Nombre de place</span>
						<input type="number" name="spots_total" min="1" key="spots_total" defaultValue={state.values.spots_total} onChange={onParamChange} />
					</label>
				</div>
				<div><button className="button-primary" onClick={validateStep1}>Suivant</button></div>
			</form>
			break
	}

	return (
		<div className='modal-mask'>
			<div className='modal reserve-services-add'>
				<header>
					<h2>{title}</h2>
					<img src={cancelIcon} alt="X" onClick={props.closeModal} />
				</header>
				<div className="modal-content">
					{body}
				</div>
			</div>
		</div>
	)
}

const ServiceDeleteModal = (props) => {

	const onDelete = () => {
		props.closeModal();
		props.reloadTable();
	}

	const deleteService = () => {
		let body = {
			status: 'KO'
		};
		api.PATCH(`/api/lead/place/${props.selectPlace}/reserve/service/${props.service.id}`, body)
			.then(onDelete);
	}


	return (
		<div className='modal-mask'>
			<div className='modal reserve-service-delete'>
				<header>
					<h2>Suppression du service</h2>
					<img src={cancelIcon} alt="X" onClick={props.closeModal} />
				</header>
				<div className="modal-content">
					Confirmez la suppression du service {props.service.name} ?
					<div>
						<button className="button-secondary" onClick={props.closeModal}>Annulez</button>
						<button className="button-primary" onClick={deleteService}>Validez</button>
					</div>
				</div>
			</div>
		</div>
	)
}

const StopRwgModal = (props) => {

	const stopRwg = () => {
		api.PUT(`/user/me/club/${props.selectPlace}/rwg`, {disabled : true}).then(() => {
			props.reloadTable();
		})
	}

	return (
		<div className='modal-mask'>
			<div className='modal stop-rwg-modal'>
				<header>
					<h2>Arrêt du service Reserve With Google</h2>
					<img src={cancelIcon} alt="X" onClick={props.closeModal} />
				</header>
				<div className="modal-content">
					Cette action entrainera l'arrêt du service Reserve With Google ainsi que la sortie de votre entreprise du listing envoyé à google.
					<br/>
					Souhaitez-vous confirmez ?
					<div style={{display :'flex', gap: "1rem", margin: "1rem 0 0 0", marginLeft:"auto"}}>
						<button className="button-secondary" onClick={props.closeModal}>Annulez</button>
						<button className="button-primary" onClick={stopRwg}>Stoppez le service</button>
					</div>
				</div>
			</div>
		</div>
	)
}


const ReserveServiceList = () => {

	const placeCtx = useContext(PlaceContext);
	let selectPlace = placeCtx.place.id;

	const [state, setState] = useState({
		step: 'INIT',
		services: [],
		displayAddModal: false,
		displayDeleteModal: false,
		values: {}
	});

	const loadService = useCallback(() => {
		if (state.step === 'LOADING') return;

		setState({
			...state,
			step: 'LOADING'
		});

		api
			.GET(`/api/lead/place/${selectPlace}/reserve/service`)
			.then(results => {
				setState({
					...state,
					step: 'VIEW',
					services: results.values
				});

			}).catch(error => {
				setState({
					...state,
					step: 'ERROR',
					error
				});
			});
	}, [selectPlace]);


	useEffect(loadService, [selectPlace]);


	const reloadTable = () => {
		loadService();
	}

	if (state.step === 'LOADING') return <p>Chargement en cours ...</p>
	if (state.step === 'EDIT') {

		let refWorkingHours = {};
		const cancelEditService = (e) => {
			setState({ ...state, service: null, values: null, step: 'OK' })
		}


		const saveEditService = (e) => {

			e.preventDefault();

			let workingHours = {};
			days.forEach(day => {
				workingHours[day.enum] = refWorkingHours[day.enum].stateToJson();
			});

			let body = {
				microprice: state.values.microprice ? state.values.microprice * 1000000 : 0,
				duration: state.values.duration ? state.values.duration * 60 : null,
				name: state.values.name,
				description: state.values.description,
				workingHours: workingHours,
				spots_total: parseInt(state.values.spots_total)
			}

			api.PATCH(`/api/lead/place/${selectPlace}/reserve/service/${state.service.id}`, body).then(() => {
				setState({ ...state, step: 'OK' });
				reloadTable();
			}).catch(err => {
				setState({ ...state, error: err });
			})
		}

		const onParamChange = (e) => {
			let values = { ...state.values };
			values[e.target.name] = e.target.value;
			setState({ ...state, values });
		}


		let hoursList = generateScheduleList(0, 24);

		return (
			<section className="reserve-services-page">
				<header>
					<h1>Modifier le service {state.service.name}</h1>
				</header>
				<div className="reserve-services-edit">
					<MagicForm
						editing={true}
						onCancelEdit={cancelEditService}
						onSubmit={saveEditService}
						className="reserve-services-form"
					>
						<div>
							<div className="inputs-group">
								<label>
									<span>Nom<Tooltip text={`Le nom l’offre ne peut contenir sa durée . Par ex : un cours de fitness d'une 1 heure ne peut pas s'appeler "Cours de fitness d'1h" mais peut s'appeler cours de fitness et avoir une durée de 60 minutes`} position="right"><img src={infoIcon} alt="i" /></Tooltip></span>
									<input type="text" name="name" key="name" defaultValue={state.values.name} onChange={onParamChange} />
								</label>
							</div>
							<div className="inputs-group">
								<label>
									<span>Description<Tooltip text={`La description de l’offre doit être différente du nom de l’offre`} position="right"><img src={infoIcon} alt="i" /></Tooltip></span>
									<textarea type="text" name="description" key="description" defaultValue={state.values.description} onChange={onParamChange} />
								</label>
							</div>
							<div className="inputs-group">
								<label>
									<span>Durée (en minutes)</span>
									<input type="number" name="duration" key="duration" min="15" step="5" defaultValue={state.values.duration} onChange={onParamChange} />
								</label>
								<label>
									<span>Prix (en €)</span>
									<input type="number" name="microprice" key="microprice" min="3" defaultValue={state.values.microprice} onChange={onParamChange} />
								</label>
								<label>
									<span>Nombre de place</span>
									<input type="number" name="spots_total" key="spots_total" min="1" defaultValue={state.values.spots_total} onChange={onParamChange} />
								</label>
							</div>
						</div>
						<div >
							<table>
								<thead>
									<tr>
										<th></th>
										<th colSpan="2">Créneau 1</th>
										<th colSpan="2">Créneau 2</th>
									</tr>
									<tr>
										<th>Jour</th>
										<th>de</th>
										<th>à</th>
										<th>de</th>
										<th>à</th>
									</tr>
								</thead>
								<tbody>
									{days.map((day) => {
										let schedule = state.values.workingHours ? state.values.workingHours[day.enum] : null;
										return <ScheduleDay key={`schedule-${day.enum}`} schedule={schedule}
											ref={(r) => { refWorkingHours[day.enum] = r }}
											dayName={day.display}
											hoursList={hoursList} />
									})}
								</tbody>
							</table>
						</div>
					</MagicForm>
				</div>
			</section>
		);
	}

	const serviceToValues = (s) => { return { ...s, microprice: micropriceToPrice(s.microprice), duration: durationToMin(s.duration) } }
	const addService = () => {
		setState({
			...state,
			displayAddModal: true
		});
	}

	const closeAddModal = () => {
		setState({
			...state,
			displayAddModal: false
		});
	}

	const closeDeleteModal = () => {
		setState({
			...state,
			displayDeleteModal: false,
			service: null
		});
	}

	const openStopRwgModal = () => {
		setState({
			...state,
			displayStopRwgModal: true
		});
	}

	const closeStopRwgModal = () => {
		setState({
			...state,
			displayStopRwgModal: false
		});
	}

	return (
		<section className="reserve-services-page">
			<header>
				<h1>Services Reserve With Google</h1>
				<button
					onClick={addService}
					className="button-add"
					title="Ajouter un service"
				>
					<img src={whiteAddIcon} alt="Ajouter" />
				</button>
			</header>
			<div className="reserve-services-content">
				<h3>Présentation</h3>
				<p>Reserve with Google est un service qui permet de générer des prospects & ventes grâce à votre fiche <a href="https://www.google.com/intl/fr_fr/business/">Google my Business</a>. C’est un nouveau canal d’acquisition proposé en exclusivité par Masalledesport. C’est inclus dans votre forfait de référencement, donc pas de cout additionnel pour votre club</p>
				<div>
					<p>Nous vous conseillons de paramétrer 3 à 5 offres qui seront donc visibles sur votre fiche Google My Business :</p>
					<ul>
						<li>Le prix de l’offre doit être compris entre 5€ et 300€ (pas d’offre gratuite)</li>
						<li>Le paiement s’effectue en club et pas en ligne. Pas de commission prélevée</li>
						<li>Les modifications peuvent mettre plus de 48h avant d'être effectives sur Google</li>
					</ul>
				</div>
				{!placeCtx?.place?.rwg?.disabled && 
					(
					<div style={{ margin: "1rem 0", display: "flex", justifyContent: "space-between" }}>
						<button className='button-primary stop-service' style={{ marginLeft: "auto" }} onClick={openStopRwgModal}>Se désinscrire de Reserve With Google</button>
					</div>
					)
				}
				{!!placeCtx?.place?.rwg?.disabled && 
					<div style={{margin : "1rem 0"}}>
						<b>Votre liaison avec le service Reserve With Google n'est pas active. Veuillez contacter le support si vous souhaitez profiter du service Reserve with Google.</b>
					</div> 
				}
				<table style={{margin : "1rem 0 0 0"}}>
					<thead>
						<tr><th>Nom</th><th>Description</th><th>Durée</th><th>Prix</th><th></th><th></th></tr>
					</thead>
					<tbody>
						{state.services.map((s, idx) => {

							return <tr key={`service_${idx}`}>
								<td>{s.name}</td>
								<td>{s.description}</td>
								<td>{durationToMin(s.duration)} min</td>
								<td>{micropriceToPrice(s.microprice)} €</td>
								<td>
									<button className="button-edit" onClick={() => setState({ ...state, step: 'EDIT', service: s, values: serviceToValues(s) })}>
										<img src={whiteEditIcon} alt="Modifier le service" />
									</button>
								</td>
								<td>
									<button className="button-delete" onClick={() => setState({ ...state, displayDeleteModal: true, service: s })} title="Supprimer">
										<img src={deleteIcon} alt="Supprimer" />
									</button>
								</td>
							</tr>
						})}
					</tbody>
				</table>
			</div>

			{state.displayAddModal && <ServiceModal closeModal={closeAddModal} reloadTable={reloadTable} selectPlace={selectPlace} />}
			{state.displayDeleteModal && <ServiceDeleteModal closeModal={closeDeleteModal} service={state.service} reloadTable={reloadTable} selectPlace={selectPlace} />}
			{state.displayStopRwgModal && <StopRwgModal closeModal={closeStopRwgModal} reloadTable={reloadTable} selectPlace={selectPlace} />}
		</section>
	)


}

export default ReserveServiceList;