import React from 'react';

import deleteIcon from '../static/icons/picto-delete.svg';
import whiteEditIcon from '../static/icons/picto-edit--white.svg';
import cancelIcon from '../static/icons/picto-cancel.svg';
import whiteSaveIcon from '../static/icons/picto-save--white.svg';

export default props => {
	let disabled = props.editing ? "" : "disabled";

	return (
		<form className={'magicForm '+(props.formClass||'')} onReset={props.onCancelEdit} onSubmit={props.onSubmit}>
			<header className="magic-form-header">
				<h2 className="magic-form-title">{props.title}</h2>
				<div className="magic-form-buttons">
					{props.onDelete && !props.editing &&
					<button className="button-delete" onClick={props.onDelete} title="Supprimer">
						<img src={deleteIcon} alt="Supprimer"/>
					</button>
					}
					{props.onEdit && !props.editing &&
					<button className="button-edit" onClick={props.onEdit} title="Modifier">
						<img src={whiteEditIcon} alt="Modifier"/>
					</button>
					}
					{props.editing &&
					<button className='button-cancel' type="reset" title="Annuler">
						<img src={cancelIcon} alt="Annuler"/>
					</button>
					}
					{props.editing &&
					<button className='button-valid' type="submit" title="Valider">
						<img src={whiteSaveIcon} alt="Valider"/>
					</button>
					}
				</div>
			</header>
			<fieldset className="magic-form-content" disabled={disabled}>
				{props.children}
			</fieldset>
		</form>
	);
}
