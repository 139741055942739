import React from 'react';
import Services from '../containers/Services';
import Conforts from '../containers/Conforts';
import Equipments from '../containers/Equipments';
import Planning from '../containers/Planning';

class Infra extends React.Component {
	render() {
		return (
			<div className="infra">
				<Planning/>
				<div className="infraGroup">
					<Services/>
					<Conforts/>
					<Equipments/>
				</div>
			</div>
		);
	}
}


export default Infra;
