import React, { useContext } from 'react';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';

import UnexpectedError from './containers/UnexpectedError';
import GoogleOAuthRedirect from './containers/GoogleOAuthRedirect';
import FacebookOAuthRedirect from './containers/FacebookOAuthRedirect';
import AppManager from './AppManager';

import ClubCreate from './containers/ClubCreate';

import ClubContainer from './containers/ClubContainer';
import MultiClubContainer from './multiclub/MultiClubContainer';

import VersionChecker from './containers/VersionChecker';
import TrackJS from './TrackJS';

import { PlaceContext } from './react_contexts';

const DELAY = 60 * 1000;

export default function App() {
	return (
		<VersionChecker
			initial={process.env.REACT_APP_VERSION}
			delay={DELAY}
			enabled={!module.hot /* disabled in development */}
		>
			<TrackJS
				enabled={process.env.REACT_APP_TRACKJS_ENABLED === 'true'}
				token={process.env.REACT_APP_TRACKJS_TOKEN}
				application={process.env.REACT_APP_TRACKJS_APPLICATION}
			>
				<BrowserRouter basename={process.env.REACT_APP_BASEPATH}>
					<Switch>
						<Route path="/offline">
							<UnexpectedError />
						</Route>
						<Route path="/oauth">
							<Switch>
								<Route path="/oauth/google" component={GoogleOAuthRedirect} />
								<Route
									path="/oauth/facebook"
									component={FacebookOAuthRedirect}
								/>
							</Switch>
						</Route>
						<Route path="*">
							<AppManager>
								<Switch>
									<Route path="/club">
										<Switch>
											<Route path="/club/createNew" component={ClubCreate} />
											<Route path="/club/:clubId">
												<ClubContainer />
											</Route>
											<Route exact path="">
												<Redirect to="/clubs" />
											</Route>
										</Switch>
									</Route>
									<Route path="/clubs" component={MultiClubContainer} />
									<Route path="*">
										<RedirectToClubOrClubs />
									</Route>
								</Switch>
							</AppManager>
						</Route>
					</Switch>
				</BrowserRouter>
			</TrackJS>
		</VersionChecker>
	);
}

function RedirectToClubOrClubs() {
	const { places } = useContext(PlaceContext);
	if (places.length === 1) return <Redirect to={`/club/${places[0].id}`} />;
	return <Redirect to="/clubs" />;
}
