import React from 'react';

export default props =>{
	return <section className="vendorDesc">
		<article>
			<header><h4 className="withLogo">Ma Salle de Sport</h4></header>
			<section>
				<table>
					<tbody>
						<tr><td>Commerçant</td><td>MASALLEDESPORT</td></tr>
						<tr><td>Identifiant Créancier SEPA</td><td>CR00004HBXF68W</td></tr>
					</tbody>
				</table>
			</section>
		</article>
		<article className="secured">
			<header><h4>Paiement sécurisé</h4></header>
			<section>
				<p>Nous utilisons la plateforme sécurisée de paiement GoCardless, établissement de paiement agréé par l'Autorité de Contrôle Prudentiel et de Résolution (ACPR) adossée à la Banque de France, pour la mise en place et le traitement de vos prélèvements</p>
			</section>
		</article>
	</section>
	;
}
