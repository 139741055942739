import React from 'react';
import SubscriptionPlan from '../components/SubscriptionPlan';

import telIcon from '../static/icons/picto-tel.svg';

//ORDER MATTERS !!!
const plans = [{
	title: "PREMIUM",
	display: "Basic",
	prices: {
		annual: 419,
		monthly: 39
	},
	features: [{
		title: "Fiche club",
		desc: "Gérez la fiche de votre club sur notre site pour recevoir 100 prospects par an en moyenne"
	}, {
		title: "Vente en ligne",
		desc: "Proposez aux internautes d’acheter en ligne des forfaits en paiement comptant"
	}],
	leads: "Environ 100 prospects / an"
}, {
	title: "PERFORMANCE",
	display: "Performance",
	prices: {
		annual: 959,
		monthly: 89,
	},
	ribbon : '+ Populaire',
	features: [{
		title: "Fiche club",
		desc: "Gérez la fiche de votre club sur notre site pour recevoir 100 prospects par an en moyenne"
	}, {
		title: "Vente en ligne",
		desc: "Proposez aux internautes d’acheter en ligne des forfaits en paiement comptant"
	}, {
		title: "Reserve with Google",
		desc: "Vos abonnements en achats rapides directement depuis la recherche Google"
	}, {
		title: "Avis / E-Réputation",
		desc: "Centralisez, répondez et modérez tous vos avis Facebook, Google et MaSalledeSport à partir du Back Office MaSalledeSport"
	}, {
		title: "Call tracking",
		desc: "Récupérez les numéros de portables des internautes qui appellent votre club"
	}, {
		title: "Bouton Facebook",
		desc: "Récupérez les contacts des internautes qui visitent votre page facebook"
	}, {
		title: "Widget site Internet",
		desc: "Proposez une séance d’essai aux internautes qui visitent votre site internet avec un format très efficace (+60% de conversion)"
	}, {
		title: "Stats consolidées",
		desc: "Si vous gérez plusieurs clubs, ayez une vision consolidée des contacts que nous générons pour vous"
	}],
	leads: "Environ 300 prospects / an + Avis"
}];


class PlanSelector extends React.Component {
	selectPlan = (plan, monthly) => {
		let isMonthly = monthly || false;
		let price = plans.filter((obj) => obj.title === plan)[0].prices[isMonthly ? "monthly" : "annual"];
		this.props.planSelected(plan, isMonthly, price);
	};

	render() {
		var actualIndex = plans.map(p=>p.title).indexOf(this.props.actual);
		return (
			<section className="plan-selector">
				<section>
					<p>
						Besoin d'assistance ?
						<br/>
						Notre équipe est là pour vous aider !
						<br/>
						<a href="tel:+33962684559">
							<img src={telIcon} alt="Tél:"/>
							09 62 68 45 59
						</a>
					</p>
					<div>Paiement mensuel</div>
					<div>
						Paiement comptant
						{this.props.reduction &&
						<em>({this.props.reduction}% de remise)</em>
						}
					</div>
				</section>
				<div>
				{
					plans.map((p, i) => {
						let prices = p.prices;
						let ribbon = p.ribbon;

						prices.monthlyAnnual = 12 * prices.monthly;
						if (actualIndex !== -1 && i > actualIndex) {
							prices = {
								annual  : "+ " + (prices.annual - plans[actualIndex].prices.annual),
								monthly : "+ " + (prices.monthly - plans[actualIndex].prices.monthly),
								monthlyAnnual : "+ " + 12*(prices.monthly - plans[actualIndex].prices.monthly)
							};
							ribbon = "Upgrade";
						}
						if (actualIndex === i)
							ribbon = "Actuelle";

						return <SubscriptionPlan
						  ribbon={ribbon}
						  key={i}
						  plan={p}
						  prices ={prices}
						  actif = { i === actualIndex}
						  selectable = { i > actualIndex }
						  monthly={this.props.monthly}
						  selected={this.props.plan === p.title}
						  planSelected={this.selectPlan}
						  annualDiff = {actualIndex === -1}
						/>
					})
				}
				</div>
			</section>
		)
	}
}

export default PlanSelector;