import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import PlanSelector from '../containers/PlanSelector';
import SepaForm from '../components/sepaForm';
import VendorDesc from '../components/vendorDesc';
import Loader from '../components/loader';
import SubscriptionBasket from '../containers/SubscriptionBasket';

class Subscribe extends React.Component {

	static contextTypes = {
		user: PropTypes.object.isRequired,
		place : PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props);
		this.state = {
			step: "CONFIGURE",
			configureStep: 1,
			plan: null,
			monthly : false,
			price: null
			, sepaIban: {value: ""}
			, sepaOwner: {value: ""}
			, socialReasonName: {value: ""}
		};
		this.sepaLength = 27;
	}

	componentDidMount() {
		if (this.context.user.isAdmin) {
			return;
		}
		API.POST(`/user/me/club/${this.context.place.id}/notification/onSubscribePage`)
		.catch(e => console.error(e, e.stack))
		;
	}

	onChange = (event) => {
		let update = {[event.target.name]: {value: event.target.value}};

		if (event.target.name === "sepaIban") {
			let value = event.target.value
				.replace(/\s+/g, "");
			value = value.substring(0, Math.min(this.sepaLength, value.length));
			value = this.chunk(value, 4);
			update = {[event.target.name]: {value: value}};
		}
		this.setState(update);
	};


	chunk = (str, n) => {
		let ret = [];

		for (let i = 0, len = str.length; i < len; i += n) {
			ret.push(str.substr(i, n))
		}

		return ret.join(" ");
	};


	selectPlan = (plan, monthly, price) => {
		this.setState({plan, monthly, price, configureStep: 2});
	};


	nextStep = (event) => {
		event && event.preventDefault();
		this.setState({
			configureStep: ++this.state.configureStep
		});
	};

	retry = (event) => {
		event.preventDefault();
		this.setState({step: "CONFIGURE"});
	};

	validateSepa = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let error = false;
		["socialReasonName", "sepaOwner", "sepaIban"].forEach((i) => {
			if (!this.state[i].value) {
				this.setState({
					[i]: {value: this.state[i].value, invalid: true}
				});
				error = true;
			}
		});

		if (this.state["sepaIban"].value.replace(/\s+/g, '').length !== this.sepaLength) {
			this.setState({
				sepaIban: {value: this.state.sepaIban.value, invalid: true}
			});
			error = true;
		}

		if (error) {
			return;
		}

		this.nextStep();
	};


	doSubscribe = () => {
		let selected = this.state.plan + "_";
		if (this.state.monthly) {
			selected += "MENSUEL"
		} else {
			selected += "COMPTANT"
		}
		let data = {
			type: selected
			, sepa: {
				iban: this.state.sepaIban.value.replace(/\s+/g, '')
				, owner: this.state.sepaOwner.value.trim()
			}
			, socialReason: {
				name: this.state.socialReasonName.value.trim()
			}
		};

		this.setState({step: "CREATING"});

		API.POST(`/user/me/club/${this.context.place.id}/subscription`, data)
		.then((res) => {
			this.setState({step: "SUCCESS"});
		})
		.catch((e) => {
			this.setState({step: "ERROR"});
			console.error(e, e.stack);
		})
		;
	};

	render() {

		if (this.state.step === "CREATING") {
			return <Loader className="msg-page">
				<p>Votre souscription est en cours de validation</p>
				<p>Veuillez ne pas fermer ni recharger cette page</p>
			</Loader>;
		}

		if (this.state.step === "SUCCESS") {
			return (
				<section className="club-subscription-form msg-page">
					<header>
						<h1 className="pageTitle">Abonnement</h1>
					</header>
					<div className="success">
						<h1>Votre souscription a été validée</h1>
						<p>Veuillez rechargez la page afin que tous les changements soient pris en compte</p>
					</div>
				</section>
			);
		}

		if (this.state.step === "ERROR") {
			return (
				<section className="club-subscription-form msg-page">
					<header>
						<h1 className="pageTitle">Abonnement</h1>
					</header>
					<div className="error">
						<h1>Une erreur est survenue</h1>
						<p>Veuillez vérifier votre IBAN.</p>
						<p>Si le problème persiste, n'hésitez pas à nous contacter via 'Nous contacter'</p>
						<p>
							<button onClick={this.retry} className="button-primary">Revenir au formulaire</button>
						</p>
					</div>
				</section>
			);
		}

		var planName = `${this.state.plan} - ${this.state.monthly?"Mensuel":"Comptant"}`;
		if(this.state.monthly){
			planName += ' (12 * '+this.state.price+' €HT)';
		}else{
			planName += ' (10% de réduction)';
		}

		let activation = 39;
		return (
			<div className="club-subscription-form">
				<header>
					<h1 className="pageTitle">Abonnement</h1>
				</header>
				<section className="plan-global">
					<div className="plan-selection">
						<h2><span>1</span> Choisissez votre formule</h2>
						<div className="club-subscription-plans">
							<PlanSelector
								actual = {null}
								plan={this.state.plan}
								monthly = {this.state.monthly}
								reduction={10}
								planSelected={this.selectPlan}
							/>
						</div>
					</div>
					<div className={"plan-payment" + (this.state.configureStep < 2 ? " inactive": "")}>
						<h2 className="pageTitle"><span>2</span> Entrez vos coordonnées bancaires</h2>
						<div>
							<SepaForm editing={true}
									  onClick={this.state.configureStep === 2 && this.validateSepa}
							          sepaOwner={this.state.sepaOwner}
							          socialReasonName={this.state.socialReasonName}
							          sepaIban={ this.state.sepaIban}
							          onChange={this.onChange}
							/>
							<VendorDesc/>
						</div>
					</div>
					<br/>
					<br/>

					<div className={"plan-basket" + (this.state.configureStep < 3 ? " inactive": "")}>
						<h2 className="pageTitle"><span>3</span> Validez votre panier</h2>
						{this.state.configureStep === 3 && <section>
							<SubscriptionBasket label="Souscrire" valid = {this.doSubscribe}
								plan={planName}
								activation = {activation}
								price = {this.state.price*(this.state.monthly ? 12 : 1)}
								total = {this.state.price*(this.state.monthly ? 12 : 1) + (activation ? 39 : 0)}
							/>
						</section>
						}
					</div>
				</section>
			</div>
		)
	}
}

export default Subscribe;
