import React from 'react';
import MultiClubs from './MultiClubs';

export default function MultiDashboard() {
	return (
		<div className="multi-dashboard-page">
			<MultiClubs />
		</div>
	);
}
