import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Loader from '../components/loader';
import MagicForm from '../components/magicForm';
import MustUpgrade from '../components/mustUpgrade';


class FacebookLinkConfiguration extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);

		this.state ={
			step : "LOADING",
			config : null,
			form : null,
			editing:false
		};
		this.reload = this.reload.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onCancelEdit = this.onCancelEdit.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
	}

	componentDidMount() {
		this.reload();
	}

	reload(){
		this.setState({step : "LOADING"});
		let clubId = this.context.place.id;

		return API.GET(`/user/me/club/${clubId}/facebookLink`)
		.then(config => {
			this.setState({
				step : "OK",
				config : config,
				form : {
					redirect : config.redirect || ""
				},
				editing:false
			});
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error(e, e.stack);
			this.setState({step:"ERROR", error :e});
		})
		;
	}

	onChange(event){
		var newState = Object.assign({}, this.state);
		newState.form[event.target.name] = event.target.value.trim();
		return this.setState(newState);
	}

	onEdit(event){
		event.preventDefault();
		this.setState({editing:true});
	}

	onCancelEdit(event){
		event.preventDefault();
		this.reload();
	}

	onSubmit(event){
		event.preventDefault();
		let clubId = this.context.place.id;
		return API.PUT(`/user/me/club/${clubId}/facebookLink`, this.state.form)
		.then(res => {
			this.reload();
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error(e, e.stack);
			this.setState({step:"ERROR", error :e});
		})
		;

	}

	render(){
		if(this.state.step === "LOADING"){
			return <Loader title="lien"/>;
		}
		if (this.state.step === "MUST_UPGRADE") {
			return <MustUpgrade title={this.props.title} plan={this.state.requiredPlan} clubId={this.context.place.id}/>
		}
		if (this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>
		}


		return (
			<MagicForm
					editing={this.state.editing}
					onEdit={this.onEdit}
					title="Configuration du lien"
					onCancelEdit={this.onCancelEdit}
					onSubmit={this.onSubmit}
					formClass="widget-settings-page"
			>
				<div>
					<label>
						<span>Url de redirection</span>
						<input type="text" name="redirect" value={this.state.form.redirect} onChange={this.onChange} placeholder='http://monsite.com/contact'/>
					</label>
				</div>
			</MagicForm>
		);
	}
}


export default FacebookLinkConfiguration;
