import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import SuperComponent from '../containers/SuperComponent';
import Source from '../components/source';
import queryString from 'query-string';
import moment from 'moment';

import checkIcon from '../static/icons/picto-check.svg';
import cancelIcon from '../static/icons/picto-cancel.svg';
import whiteSendIcon from '../static/icons/picto-send--white.svg';
import clockIcon from '../static/icons/picto-clock.svg';

function convertCivilite(civilite) {
	switch (civilite) {
		case "MADAME":
			return "Mme";
		case "MONSIEUR":
			return "M.";
		case "MADEMOISELLE":
			return "Mlle.";
		default:
			//TODO do a warn
			return ""
	}
}


const Presence = props => {
	if (props.essai.used === true) {
		return <span className="presenceYes"><img src={checkIcon} alt="Oui"/></span>;
	}
	if (props.essai.used === false) {
		return <span className="presenceNo"><img src={cancelIcon} alt="Non"/></span>;
	}
	return (
		<div className="trial-presence">
			<button data-id={props.essai.id} className="presence-yes-button" onClick={props.presenceToYes}>
				<img data-id={props.essai.id} src={checkIcon} alt="Oui"/>
			</button>
			<button data-id={props.essai.id} className="presence-no-button" onClick={props.presenceToNo}>
				<img data-id={props.essai.id} src={cancelIcon} alt="Non"/>
			</button>
		</div>
	);
};

const Recontact = props => {
	if (props.essai.recontact.retryable.permit) {
		return (
			<button data-id={props.essai.id} className="button-primary" onClick={props.presenceReask}>
				<img src={whiteSendIcon} alt="Envoyer"/>
			</button>
		)
	}
	return <span><img src={clockIcon} alt="En attente"/></span>;
};

class Essais extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = {page: queryString.parse(this.props.location.search).page || 1};
		this.doAction = this.doAction.bind(this);

		this.presenceToYes = this.presenceTo.bind(this, true);
		this.presenceToNo = this.presenceTo.bind(this, false);
		this.presenceReask = this.presenceReask.bind(this);
	}

	componentWillReceiveProps(nextProps, oldsProps) {
		if (nextProps.location.search && queryString.parse(nextProps.location.search).page) {
			if (this.state.page !== queryString.parse(nextProps.location.search).page) {
				this.setState({page: queryString.parse(nextProps.location.search).page});
			}
		}
	}

	doAction(page) {
		return API.GET(`/user/me/club/${this.context.place.id}/tryRequest?page=${page}`)
		.then(res=>{
			this.setState({essais:res.values});
			return new Promise( (resolve, reject)=>{
				return resolve(res);
			});
		});
	}


	presenceTo(presence, event) {
		let essaiId = event.target.dataset.id;
		let newState = Object.assign({}, this.state);
		// The disable is here for a reason, dataset id is a string, our data is an integer
		// eslint-disable-next-line eqeqeq
		var found = newState.essais.filter(e=>e.id == essaiId);
		if(found.length ===0){
			console.error("Essai not found:", essaiId);
			return;
		}
		var essai = found[0];
		essai.used = presence;

		var body = {
			presence: presence
		};
		API.PATCH(`/user/me/club/${this.context.place.id}/tryRequest/${essai.id}`, body)
		.then((res) => {
			this.superComp.setState(newState);
		})
		;
	}

	presenceReask(event) {
		let essaiId = event.currentTarget.dataset.id;
		let newState = Object.assign({}, this.state);
		// The disable is here for a reason, dataset id is a string, our data is an integer
		// eslint-disable-next-line eqeqeq
		var found = newState.essais.filter(e=>e.id == essaiId);
		if(found.length ===0){
			console.error("Essai not found:", essaiId);
			return;
		}
		var essai = found[0];
		essai.recontact.retryable.permit = false;
		essai.recontact.retryable.reason = "WAIT_A_MOMENT";

		API.PUT(`/user/me/club/${this.context.place.id}/tryRequest/${essaiId}`)
		.then(res => {
			this.superComp.setState(newState);
		})
		;
	}


	render() {
		let clubId = this.context.place.id;

		return (
			<SuperComponent
				ref={(superComp) => {
					this.superComp = superComp;
				}}
				clubId={clubId}
				title="Séances d'essai"
				page={this.state.page}
				specificClassName="trial-request-page"
				doAction={this.doAction}
				pagination={{baseUrl : `${this.props.match.url}?`}}
			>
				<SimpleContainer
					presenceToYes={this.presenceToYes}
					presenceToNo={this.presenceToNo}
					presenceReask={this.presenceReask}
				/>
			</SuperComponent>
		);
	}
}

const SimpleContainer = (props) => {
	let essais = props.values;

	if (props.values.length === 0) {
		return <p className="msdsAdvice">
			Vous n'avez pas encore reçu de demandes de séance d'essai.
		</p>;
	}
	return (
		<table className="trial-requests">
			<thead>
			<tr>
				<th/>
				<th>Date</th>
				<th>Nom, Prénom</th>
				<th>Date de naissance</th>
				<th>Intitulé</th>
				<th>Créneau</th>
				<th>Téléphone</th>
				<th>Email</th>
				<th>S'est présenté&nbsp;?</th>
				<th>Renvoi mail</th>
			</tr>
			</thead>
			<tbody>
			{
				essais.map((e) => {
					return <tr key={`line_essai_${e.id}`}>
						<td><Source source={e.source}/></td>
						<td>{moment(e.date).format("DD/MM/YYYY")}</td>
						<td>{convertCivilite(e.civilite)} {e.lastname} {e.firstname}</td>
						<td>{e.birthdate}</td>
						<td>{e.type}</td>
                        <td>{e.appointment ? `${e.appointment.date} ${e.appointment.time}` : 'NC'}</td>
						<td className='trial-user-phone'>{e.phone}</td>
						<td className='trial-user-email'>{e.email}</td>
						<td>{e.leadType === 'demande_essai' && <Presence
								essai={e}
								presenceToYes={props.presenceToYes}
								presenceToNo={props.presenceToNo}
							/>
							}
						</td>
						<td>
							{e.leadType === 'demande_essai' && <Recontact essai={e} presenceReask={props.presenceReask}/> }
						</td>
					</tr>
				})
			}
			</tbody>
		</table>
	);
};

export default Essais;
