import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import SuperComponent from '../containers/SuperComponent';
import {Link} from 'react-router-dom';
import queryString from 'query-string';
import moment from 'moment';

import visibleIcon from '../static/icons/picto-visible.svg'

class Abos extends React.Component {
	static contextTypes = {
		place: PropTypes.object.isRequired,
		applicationDatas : PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state =  { page: queryString.parse(this.props.location.search).page || 1 };
	}

	componentWillReceiveProps(nextProps, oldsProps) {
		if(nextProps.location.search && queryString.parse(nextProps.location.search).page){
			if(this.state.page !== queryString.parse(nextProps.location.search).page){
				this.setState({page:queryString.parse(nextProps.location.search).page});
			}
		}
	}

	doAction = (page) => {
		return API.GET(`/api/abo/abo?place.id=${this.context.place.id}&page=${this.state.page}`)
	};

	render() {
		let placeId = this.context.place.id;
		return (
			<SuperComponent
				clubId={placeId}
				title="Vos abonnements"
				page={this.state.page}
				specificClassName="club-abos-page"
				doAction ={this.doAction}
				pagination={{baseUrl : `${this.props.match.url}?`}}
			>
				<AbosContainer aboBasePath={`${this.props.match.url}`}/>
			</SuperComponent>
		);
	}

}

const AbosContainer = (props)=>{
	let abos = props.values;
	if(abos.length === 0){
		return <p className="msds-advice">Vous n'avez pas encore d'abonnement souscrit en ligne sur votre salle.</p>;
	}
	return <section>
		<table>
			<thead>
			<tr>
				<th>Status</th>
				<th>Date</th>
				<th>Client</th>
				<th>Email</th>
				<th>Téléphone</th>
				<th>Formule</th>
				<th>Actions</th>
			</tr>
			</thead>
			<tbody>
			{abos.map(abo =>{
				return <AboLine key={`abo_${abo.id}`} aboBasePath={props.aboBasePath} abo={abo} />
			})}
			</tbody>
		</table>
	</section>
		;
};

const AboLine = (props) => {
	const abo = props.abo;
	const client = abo.client;
	const plan = abo.plan;

	return <tr>
		<td>{abo.status}</td>
		<td>{moment(+abo.creation).format("DD/MM/YYYY")}</td>
		<td>{client.firstname} {client.lastname}</td>
		<td>{client.email}</td>
		<td>{client.phone}</td>
		<td>{plan.name}</td>
		<td><Link to={`${props.aboBasePath}/${abo.id}`}><img src={visibleIcon} alt="Oeil"/></Link></td>
	</tr>;
};


export default Abos;
