import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import API from './utils/api';
import Loader from './components/loader';
import SessionManager from './SessionManager';
import PlacesLoader from './PlacesLoader';
import Header from './containers/Header';

import { ApplicationDataContext } from './react_contexts';

export default class AppManager extends React.Component {
	// @TODO (sinewyk): clean deprecated context APIs
	static childContextTypes = {
		applicationDatas: PropTypes.object
	};

	getChildContext = () => {
		return {
			applicationDatas: this.state.applicationDatas
		};
	};

	state = {
		step: 'LOADING'
	};

	componentDidMount() {
		API.GET(`/datas`)
			.then(res => {
				this.setState({ step: 'OK', applicationDatas: res });
			})
			.catch(e => {
				console.error('[AppManager]', 'Cannot load datas', e);
				this.setState({ step: 'ERROR' });
			});
	}

	render() {
		if (this.state.step === 'LOADING') {
			return <Loader />;
		}

		if (this.state.step === 'ERROR') {
			return <p>Une erreur est survenue</p>;
		}

		return (
			<ApplicationDataContext.Provider value={this.state.applicationDatas}>
			<Switch>
				<Route path="*">
					<SessionManager>
						<PlacesLoader>
							<div className="rootContainer">
								<Header />
								{this.props.children}
							</div>
						</PlacesLoader>
					</SessionManager>
				</Route>
				</Switch>
			</ApplicationDataContext.Provider>
		);
	}
}
