export const civilites = [
{
	code : "m",
	name: "Monsieur"
},{
	code : "mme",
	name : "Madame"
},{
	code : "mlle",
	name : "Mademoiselle"
}];
