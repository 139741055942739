import React from 'react';

const AUTH_URL = process.env.REACT_APP_AUTH_URL;
const THIS_URL = process.env.REACT_APP_THIS_URL;

export default props => {
	if (props.step === "PENDING") {
		return (
			<section className="msg-page register-pending">
				<div className="loading">
					<h1>Votre club est en cours de création.</h1>
					<p>Veuillez ne pas fermer ni rafraichir cette page.</p>
				</div>
			</section>
		);
	}
	return (
		<div className="registerContainer" style={{width:'100%',margin:'auto'}}>
			<h1 style={{textAlign:'center'}}>Ajout de mon club</h1>
			<form className="registerForm" onSubmit={props.goValid} style={{width:'100%'}}>
				<div className="registerForm_inputs" style={{width:'100%'}}>
				<div className="registerForm_inputs_club">
					<label>
						<span className="picto-club">Nom du club</span>
						<input type="text" placeholder="Club" name="name" autoComplete="organization" value={props.inputs.name.value} onChange={props.onChange} className={props.inputs.name.invalid ? "inputInvalid" :""}/>
					</label>
					<label>
						<span className="picto-club-type">Type de club</span>
						<select name="type" onChange={props.onChange} defaultValue="" className={props.inputs.type.invalid ? "inputInvalid" :""}>
							<option key="placeholder" disabled value="">Sélectionner un type de club</option>
							{props.clubTypes.map((n)=>{
								return <option key={n.id} value={n.id}>{n.name}</option>
							})}
						</select>
					</label>
					<label>
						<span className="picto-network">Réseau</span>
						<select name="network" onChange={props.onChange} value={props.inputs.network.value} className={props.inputs.network.invalid ? "inputInvalid" :""}>
							<option key="placeholder" disabled value="">Sélectionner un réseau</option>
							<option key="independant" value="0">Indépendant</option>
							{props.networks.map((n)=>{
								return <option key={n.id} value={n.id}>{n.name}</option>
							})}
						</select>
					</label>
					<label>
						<span className="picto-address">Téléphone</span>
						<input type="text" name="phone" placeholder="Téléphone" value={props.inputs.phone.value} onChange={props.onChange} className={props.inputs.phone.invalid ? "inputInvalid" :""}/>
					</label>
					<label>
						<span className="picto-address">Adresse du club</span>
						<input type="text" name="street" placeholder="Adresse" autoComplete="address-line1" value={props.inputs.street.value} onChange={props.onChange} className={props.inputs.street.invalid ? "inputInvalid" :""}/>
					</label>
					<label>
						<input type="text" name="streetBis" placeholder="Adresse - suite (facultatif)" autoComplete="address-line2" value={props.inputs.streetBis.value} onChange={props.onChange} />
					</label>
					<label>
						<input type="text" name="zipCode" placeholder="Code postal" autoComplete="postal-code" value={props.inputs.zipCode.value} onChange={props.onChange} className={props.inputs.zipCode.invalid ? "inputInvalid" :""}/>
					</label>
					<label>
						<input type="text" name="city" placeholder="Ville" autoComplete="address-level2" value={props.inputs.city.value} onChange={props.onChange} className={props.inputs.city.invalid ? "inputInvalid" :""}/>
					</label>
				</div>
				</div>
				<div className="registerForm_actions form-buttons"  style={{width:'100%'}}>
					<button className="button-primary" onClick={props.goValid}>Valider</button>
				</div>
			</form>
		</div>
	);
}
