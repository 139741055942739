import React, { useContext } from 'react';
import API from '../utils/api';
import { PlaceContext } from '../react_contexts';

import CCimg from "../static/CC-short.svg";

export default () => {
	const { place } = useContext(PlaceContext);

	const onClick = () => {
		API.POST(`/user/me/club/${place.id}/notification/clubconnectMoreInfo`)
		.catch(e => console.error(e, e.stack))
		;
	};

	return <section className='MobileApp'>
		<h1 className="pageTitle">800 clubs en France utilisent déjà l’application mobile ClubConnect</h1>
		<div>
			<img src={CCimg} alt="Club Connect"/>
			<a onClick={onClick} href='http://data.masalledesport.com/prod/commercial/ClubConnect-Flyer.pdf' className='button-primary'>Plus d’infos</a>
		</div>
	</section>
};
