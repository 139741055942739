import React, { useContext, useState } from 'react';

import { PlaceContext, UserContext } from '../react_contexts';
import Dropdown from './dropdown';

import SupermanIcon from '../static/icons/su.svg';
import LogoLead from '../static/logos/logo-lead.svg';
import LogoCC from '../static/logos/logo-cc.svg';
import Logout from '../static/icons/logout.svg';

const ADMIN_URL = process.env.REACT_APP_ADMIN_URL;
const LEAD_URL = process.env.REACT_APP_LEAD_URL;
const CC_URL = process.env.REACT_APP_CC_URL;

const extractUserInitials = (user) => {
  return `${user.firstname}`.charAt(0) + `${user.lastname}`.charAt(0);
};

const UserAvatar = () => {
  const user = useContext(UserContext);
  const { place } = useContext(PlaceContext);
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  return (
    <div className="user-avatar">
      <p className="name">{user.firstname} {user.lastname}</p>
      <Dropdown
        isOpen={isDropdownOpen}
        button={
          <button
            type="button"
            className="rounded initials"
            onClick={() => setDropdownOpen(!isDropdownOpen)}
          >
            {extractUserInitials(user)}
          </button>
        }
        onClose={() => setDropdownOpen(false)}
      >
        <nav className="menu">
          {(user.isAdmin || user.isBlogger) && <a
            className="link"
            href={ADMIN_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={SupermanIcon}
              alt="Admin"
              style={{ marginRight: '.5rem' }}
              height="14"
            />
            <span>Admin</span>
          </a>}
          <a
            className="link"
            href={place ? `${LEAD_URL}/club/${place.id}` : LEAD_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LogoLead}
              alt="Lead"
              style={{ marginRight: '.5rem' }}
              height="14"
            />
            <span>Lead</span>
          </a>
          <a
            className="link"
            href={place && place.idCC != null ? `${CC_URL}/club/${place.idCC}` : CC_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={LogoCC}
              alt="Club Connect"
              style={{ marginRight: '.5rem' }}
              height="14"
            />
            <span>Club Connect</span>
          </a>
          <button
            type="button"
            className="logout"
            onClick={user.logout}
          >
            <img
              src={Logout}
              alt="Déconnexion"
              style={{ marginRight: '.5rem' }}
              height="14"
            />
            <span>Déconnexion</span>
          </button>
        </nav>
      </Dropdown>
    </div>
  );
};

export default UserAvatar;
