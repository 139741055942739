import { createContext } from 'react';

function notYetDefined() {
	throw new Error('Not yet defined');
}

const PlaceContext = createContext({
	places: [],
	place: null,
	selectPlace: notYetDefined,
	updatePlace: notYetDefined
});

const UserContext = createContext(null);

const AppVersionContext = createContext('N/A');

const ApplicationDataContext = createContext(null);

export {
	PlaceContext,
	UserContext,
	AppVersionContext,
	ApplicationDataContext,
};
