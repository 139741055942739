import React from 'react';

class ScheduleDay extends React.Component {

	constructor(props) {
		super(props);
		this.state = this.toState(props.schedule);
	}

	onChange = (event) => {
		var newState = Object.assign({}, this.state);
		var name = event.target.name;
		var creanaux = parseInt(name.split("_")[0]);
		var type      = name.split("_")[1];
		newState.schedule[creanaux][type] = event.target.value;
		this.setState(newState);
	};

	toState = (schedule) => {
		let result = [
			{from : "", to:""},
			{from : "", to:""}
		];
		if(!schedule){
			return {schedule : result};
		}

		if(schedule.length > 0) {
			result[0]= {from : schedule[0].from, to:schedule[0].to};
		}
		if(schedule.length > 1) {
			result[1]= {from : schedule[1].from, to:schedule[1].to};
		}
		return {schedule : result};
	};

	stateToJson = () => {
		var cleaned = this.state.schedule.filter(s=>{
			return s && s.from && s.to;
		});
		return cleaned;
	};

	render() {

		return (
			<ScheduleDayComponent  onChange={this.onChange} morningFrom={this.state.morningFrom}
					schedule={this.state.schedule}
					dayName ={this.props.dayName}
					hoursList={this.props.hoursList}
			/>
		);
	}
}

export default ScheduleDay;

const ScheduleDayComponent = (props) => {
	return <tr>
			<td>{props.dayName}</td>
			<OneTime name="0_from" onChange={props.onChange} selectedValue={props.schedule[0].from} list={props.hoursList} />
			<OneTime name="0_to" onChange={props.onChange} selectedValue={props.schedule[0].to} list={props.hoursList} />
			<OneTime name="1_from" onChange={props.onChange} selectedValue={props.schedule[1].from} list={props.hoursList} />
			<OneTime name="1_to" onChange={props.onChange} selectedValue={props.schedule[1].to} list={props.hoursList} />
	</tr>;
};

const OneTime = (props) => {
	return <td className="text-left">
		<select name={props.name} onChange={props.onChange} value={props.selectedValue}>
			{props.list.map((v)=>{
				return <option key={v} name={v}>{v}</option>
			})}
		</select>
	</td>;
};
