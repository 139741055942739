import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import SuperComponent from '../containers/SuperComponent';
import queryString from 'query-string';
import moment from 'moment';

class Calls extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = {page: queryString.parse(this.props.location.search).page || 1};
		this.doAction = this.doAction.bind(this);
	}

	componentWillReceiveProps(nextProps, oldsProps) {
		if (nextProps.location.search) {
			let change = {};
			if (queryString.parse(nextProps.location.search).page !== undefined) {
				change.page = queryString.parse(nextProps.location.search).page;
			}
			if (queryString.parse(nextProps.location.search).search !== undefined) {
				change.search = queryString.parse(nextProps.location.search).search;
			}
			this.setState(change);
		}
	}

	doAction(page) {
		return API.GET(`/user/me/club/${this.context.place.id}/call?page=${this.state.page}`)
		.then(res => {
			this.setState({calls:res.values});
			return new Promise( (resolve, reject)=>{
				return resolve(res);
			});
		})
		;
	}

	render() {
		let clubId = this.context.place.id;
		return <SuperComponent
				ref={(superComp) => {
					this.superComp = superComp;
				}}
				clubId={clubId}
				title="Appels"
				page={this.state.page}
				specificClassName="trial-request-page"
				doAction={this.doAction}
				pagination={{baseUrl : `${this.props.match.url}?`}}
			>
				<SimpleContainter/>
			</SuperComponent>;
	}

}


const SimpleContainter = (props) => {
	let calls = props.values;

	if (props.values.length === 0) {
		return <p className="msdsAdvice">Vous n'avez pas encore reçu d'appels</p>;
	}
	return (
		<table>
			<thead>
			<tr>
				<th>Date</th>
				<th>Numéro appelant</th>
				<th>Durée</th>
			</tr>
			</thead>
			<tbody>
			{
				calls.map((c) => {
					return <tr key={c.id}>
						<td>{moment(c.date).format("DD/MM/YYYY hh:ss")}</td>
						<td>{c.caller}</td>
						<td>{c.duration === 0 ? 'Appel manqué' : `${c.duration} secondes`}</td>
					</tr>
				})
			}
			</tbody>
		</table>
	);
};

export default Calls;
