import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Subscribe from '../containers/Subscribe';
import Loader from '../components/loader';

class SubscriptionActivate extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step : "LOADING",
			selectedPlan: ""
		};
	}

	componentDidMount(){
		this.reload();
	}

	reload = () => {
		this.setState({step:'LOADING'});
		API.GET(`/user/me/club/${this.context.place.id}/subscription`)
		.then(res=>{
			if(res.plan !== "ANNUAIRE")
				return this.props.history.replace(`/club/${this.context.place.id}/subscription`);

			this.setState({step: "SUBSCRIBE", subscription:res});
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e,e.stack);
		})
		;
	};

	render() {
		if (this.state.step === "LOADING") {
			return <Loader title="Votre abonnement"/>;
		}

		if (this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>;
		}

		if (this.state.step === "SUBSCRIBE") {
			return <Subscribe selectedPlan={this.state.selectedPlan}/>;
		}
	}
}

export default SubscriptionActivate;
