import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../components/Tabs';

class Prospects extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render() {
		const placeId = this.context.place.id;

		var menu = [
			{ path : 'essais'   , display: 'Séances d\'essais'},
			{ path : 'contacts' , display: 'Demandes d\'informations'},
			{ path : 'calls' , display: 'Appels'},
			{ path : 'export' , display: 'Exporter'}
		];

		if (['PREMIUM', 'PREMIUM_DEMO', 'PERFORMANCE', 'PERFORMANCE_DEMO'].includes(this.context.place.subscription.plan)) {
			menu.splice(1, 0, { path : 'bookings' , display: 'Réservations', new : true});
		}

		return (
			<Tabs baseUrl={`/club/${placeId}/prospects/`} className="club-prospects" items={menu}>
				{this.props.children}
			</Tabs>
		);
	}

}
export default Prospects;
