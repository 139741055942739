import React from 'react';

import msdsLogo from '../static/logos/logo.svg';
import websiteLogo from '../static/icons/website.svg';
import facebookLogo from '../static/logos/facebook.svg';
import googleLogo from '../static/logos/google.svg';

const sourcesIcons = {
	"MSDS": msdsLogo,
	"CDISCOUNT": msdsLogo,
	"WIDGET": websiteLogo,
	"FACEBOOK_LINK": facebookLogo,
	"FACEBOOK_PAGE" : facebookLogo,
	"GOOGLE_MYBUSINESS" : googleLogo,
	"MSDS_EXTERN_REFERER" : websiteLogo,
	"RESERVE_WITH_GOOGLE" : googleLogo
};

export default ({ source = 'MSDS' }) => {
	return sourcesIcons[source]
		? <img className="source-logo" src={sourcesIcons[source]} alt={source} title={source}/>
		: source;
};
