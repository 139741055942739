import React from 'react';
import PropTypes from 'prop-types';
import RegisterForm from '../components/register';
import API from '../utils/api';

class UserClubAdd extends React.Component {

	static contextTypes = {
		applicationDatas: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);
		this.goValid = this.goValid.bind(this);
		this.onChange = this.onChange.bind(this);

		this.state = {
			step : "CLUB",
			form : {
				name:{value:""}
				,type : {value:""}
				,phone: {value:""}
				,street:{value:""}
				,streetBis:{value:""}
				,zipCode:{value:""}
				,city:{value:""}
				,network:{value:""}
			}
		};
	}

	componentDidMount(){
		this.geocoder = new window.google.maps.Geocoder();
	}

	goValid(event){
		event.preventDefault();

		let change = Object.assign({}, this.state);
		let error = false;
		["name","phone","street","zipCode","city", "network", "type"].forEach((p)=>{
			if(change.form[p].value.trim() === ""){
				change.form[p].invalid = {};
				error = true;
			}
		});

		if(error){
			this.setState(change);
			return;
		}

		let formValues = {};

		Object.keys(this.state.form).forEach((k)=>{
			formValues[k] = this.state.form[k].value.trim();
		});
		formValues.network = (+formValues.network) || undefined;
		formValues.type = (+formValues.type) || undefined;

		let fullAddress = "";
		fullAddress += formValues.street;
		fullAddress += " " + (formValues.streetBis || "");
		fullAddress += " " + formValues.zipCode;
		fullAddress += " " + formValues.city;
		this.setState({step:"PENDING"});

		this.geocoder.geocode({ 'address' : fullAddress}, (results, status)=>{
			if (status !== window.google.maps.GeocoderStatus.OK || !results.length) {
				this.setState({step:"CLUB"});
				alert("Nous ne pouvons pas géolocaliser votre adresse");
				return;
			}

			results[0].address_components.forEach((c)=>{
				if(c.types.indexOf("locality")!==-1)
					formValues.city= c.long_name;
				if(c.types.indexOf("postal_code")!==-1)
					formValues.zipCode= c.long_name;
			});

			formValues['latitude'] = results[0].geometry.location.lat();
			formValues['longitude'] =  results[0].geometry.location.lng();

			API.POST('/api/pro/place', formValues)
			.then((res)=>{
				this.setState({step:"CONFIRM", createdId: res.id});
			})
			.catch((e)=>{
				if(e.restCode === "paramsValidation" && e.errors === "email already exists"){
					let newState = Object.assign({}, this.state);
					newState.step = "YOU";
					newState.form.email.invalid = {
						message : "Il y a déjà un compte avec cet email"
					};
					this.setState(newState);
					return;
				}
				console.error(e);
				this.setState({step:"CLUB"});
			})
			;
		});
	}

	onChange(event){
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = {
			value:event.target.value
		};
		if(event.target.name==="password"){
			newState.form['password2'].invalid = false;
		}
		this.setState(newState);
	}

	render() {

		if( this.state.step === 'CONFIRM') {
			return (
				<section className="registerPage">
					<h1>Votre club a été créé</h1>
					<br/>
					<p>Il vous suffit de recharger la page pour y accéder</p>
				</section>
			);
		}
		let networks = this.context.applicationDatas.networks;
		let clubTypes = this.context.applicationDatas.clubTypes;
		return (
			<section className="registerPage">
				<RegisterForm step={this.state.step} inputs={this.state.form} onChange={this.onChange}
					networks={networks.filter(n=>n.selectable).sort((a,b)=>{return a.name.localeCompare(b.name)})}
					clubTypes={clubTypes.filter(n=>n.selectable).sort((a,b)=>{return a.name.localeCompare(b.name)})}
					goValid = {this.goValid}
				/>
			</section>
		);
	}
}

export default UserClubAdd;
