import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import { Link } from 'react-router-dom';

class ClubCreate extends React.Component {

	static contextTypes = {
		applicationDatas: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);
		this.state = {
			step : "FORM"
			,form : {
				name : {value:""}
				,type: {value:""}
				,network : {value:""}
				,street : {value:""}
				,streetBis: {value:""}
				,zipCode : {value:""}
				,city : {value:""}
				,firstname : {value:""}
				,lastname  : {value:""}
				,email : {value:""}
				,phone : {value:""}
			}
		};
		this.onChange = this.onChange.bind(this);
		this.doCreate = this.doCreate.bind(this);
	}

	componentDidMount(){
		this.geocoder = new window.google.maps.Geocoder();
	}

	onChange(event){
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = {
			value:event.target.value
		};
		this.setState(newState);
	}

	doCreate(event){
		event.preventDefault();
		event.preventDefault();

		let change = Object.assign({}, this.state);
		let error = false;
		["name","street","zipCode","city", "network", "type", "firstname","lastname", "email"].forEach((p)=>{
			if(change.form[p].value.trim() === ""){
				change.form[p].invalid = {};
				error = true;
			}
		});

		if(error){
			this.setState(change);
			return;
		}

		let formValues = {};

		Object.keys(this.state.form).forEach((k)=>{
			formValues[k] = this.state.form[k].value.trim();
		});
		formValues.network = (+formValues.network) || undefined;
		formValues.type = (+formValues.type) || undefined;


		let fullAddress = "";
		fullAddress += formValues.street;
		fullAddress += " " + (formValues.streetBis || "");
		fullAddress += " " + formValues.zipCode;
		fullAddress += " " + formValues.city;

		this.setState({step:"PENDING"});

		this.geocoder.geocode({ 'address' : fullAddress}, (results, status)=>{
			if (status !== window.google.maps.GeocoderStatus.OK || !results.length) {
				this.setState({step:"FORM"});
				alert("Nous ne pouvons pas géolocaliser votre adresse");
				return;
			}

			results[0].address_components.forEach((c)=>{
				if(c.types.indexOf("locality")!==-1)
					formValues.city= c.long_name;
				if(c.types.indexOf("postal_code")!==-1)
					formValues.zipCode= c.long_name;
			});
			formValues['latitude'] = results[0].geometry.location.lat();
			formValues['longitude'] = results[0].geometry.location.lng();

			API.POST(`/api/admin/club`, formValues)
			.then(res=>{
				this.setState({step:"SUCCESS", createdId: res.id});
			})
			.catch((e)=>{
				console.error(e, e.stack);
				this.setState({step:"FORM"});
			})
			;
		});
	}

	render(){
		let networks = this.context.applicationDatas.networks;
		let clubTypes = this.context.applicationDatas.clubTypes;

		if(this.state.step === "PENDING"){
			return (
				<section className="msg-page add-club-pending page">
					<div className="loading">
						<h1>Votre compte est en cours de création.</h1>
						<p>Veuillez ne pas fermer ni rafraichir cette page.</p>
					</div>
				</section>
			);
		}
		if (this.state.step === "FORM") {
			return (
			<section className="add-new-club-page page">
				<h1 className="pageTitle">Création d'une nouvelle salle</h1>
				<form onSubmit={this.doCreate} className="add-new-club-form">
					<h3>Informations</h3>
					<section className="form-infos">
						Cette interface uniquement disponible pour les admins :
						<ul>
							<li>Ne créera pas d'utilisateurs associé à la salle</li>
							<li>Nécessite les informations du responsable (téléphone optionnel)</li>
							<li>L'email mis en responsable sera utilisé pour les offres d'essai</li>
						</ul>
					</section>
					<h3>Club</h3>
					<section>
						<label>
							<span className="picto-club">Nom du club</span>
							<input type="text" placeholder="Club" name="name" value={this.state.form.name.value} onChange={this.onChange} className={this.state.form.name.invalid ? "inputInvalid" :""}/>
						</label>

						<label>
							<span className="picto-club-type">Informations complémentaires</span>
							<div className="inputs-group club-infos">
								<select name="type" onChange={this.onChange} defaultValue="" className={this.state.form.type.invalid ? "inputInvalid" :""}>
									<option key="placeholder" disabled value="">Type de club</option>
									{clubTypes.map((n)=>{
										return <option key={n.id} value={n.id}>{n.name}</option>
									})}
								</select>
								<select name="network" onChange={this.onChange} value={this.state.form.network.value} className={this.state.form.network.invalid ? "inputInvalid" :""}>
									<option key="placeholder" disabled value="">Réseau</option>
									<option key="independant" value="0">Indépendant</option>
									{networks.map((n)=>{
										return <option key={n.id} value={n.id}>{n.name}</option>
									})}
								</select>
							</div>
						</label>

						<label>
							<span className="picto-address">Adresse du club</span>
							<input type="text" name="street" placeholder="Adresse" value={this.state.form.street.value} onChange={this.onChange} className={this.state.form.street.invalid ? "inputInvalid" :""}/>
							<input type="text" name="streetBis" placeholder="Adresse - suite (facultatif)" value={this.state.form.streetBis.value} onChange={this.onChange} />
							<div className="inputs-group club-address">
								<input type="text" name="zipCode" placeholder="Code postal" value={this.state.form.zipCode.value} onChange={this.onChange} className={this.state.form.zipCode.invalid ? "inputInvalid" :""}/>
								<input type="text" name="city" placeholder="Ville" value={this.state.form.city.value} onChange={this.onChange} className={this.state.form.city.invalid ? "inputInvalid" :""}/>
							</div>
						</label>
					</section>

					<h3>Responsable</h3>
					<section>
						<label>
							<span className="picto-perso">Contact</span>
							<div className="inputs-group">
								<input type="text" name="firstname" placeholder="Prénom" value={this.state.form.firstname.value} onChange={this.onChange} className={this.state.form.firstname.invalid ? "inputInvalid" :""}/>
								<input type="text" name="lastname" placeholder="Nom" value={this.state.form.lastname.value} onChange={this.onChange} className={this.state.form.lastname.invalid ? "inputInvalid" :""}/>
							</div>
						</label>
						<div className="inputs-group">
							<label>
								<span className="picto-mail">Email</span>
								<input type="email" name="email" placeholder="Email" value={this.state.form.email.value} onChange={this.onChange} className={this.state.form.email.invalid ? "inputInvalid" :""}/>
							</label>
							<label>
								<span className="picto-tel">Téléphone</span>
								<input type="tel" name="phone" placeholder="01 XX XX XX XX" value={this.state.form.phone.value} onChange={this.onChange} className={this.state.form.phone.invalid ? "inputInvalid" :""}/>
							</label>
						</div>
					</section>

					<div className="form-buttons">
						<Link tabIndex="2" to={"/club"} className="button-cancel">Annuler</Link>
						<button tabIndex="1" className="button-primary" type="submit" onClick={this.doCreate}>Créer</button>
					</div>
				</form>
			</section>
			);
		}
		if (this.state.step === "SUCCESS") {
			return (
				<section className="msg-page add-club-confirm page">
					<div className="success">
						<h1>Inscription terminée !</h1>
						<p>
							Votre salle a bien été ajoutée.<br/>
							<Link className="button-valid" to={`/club/${this.state.createdId}`}>
								{this.state.form.name.value}
							</Link>
						</p>
					</div>
				</section>
			);
		}
	}

}

export default ClubCreate;
