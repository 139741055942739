import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../components/Tabs';

class PresentationContainer extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render(){
		const placeId = this.context.place.id;

		var menu = [
			{ path : "text"			, display : "Texte" },
			{ path : "picture"		, display : "Photos" },
			{ path : "address"		, display : "Adresse & Accès" },
			{ path : "planning"		, display : "Horaires" },
			{ path : "activities"	, display : "Activités" },
			{ path : "services"		, display : "Planning & Services" },
			{ path : "actor"		, display : "Coachs" },
			{ path : "formules"		, display : "Tarifs / Abonnement" },
			{ path : "events"		, display : "Evénements" },
		];


		if (['PREMIUM', 'PREMIUM_DEMO', 'PERFORMANCE', 'PERFORMANCE_DEMO'].includes(this.context.place.subscription.plan)) {
			menu.push({ path : "reserveWithGoogle" , display : "Reserve with google", new : true });
		}

		return (
			<Tabs baseUrl={`/club/${placeId}/presentation/`} className="club-prospects" items={menu}>
				{this.props.children}
			</Tabs>
		);
	}
};

export default PresentationContainer;
