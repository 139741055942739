import React from 'react';
import Pagination from '../components/pagination';
import MustUpgrade from '../components/mustUpgrade';
import Loader from '../components/loader';
import {Link} from 'react-router-dom';

class SuperComponent extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			step: "LOADING",
			page: this.props.page,
			search: this.props.search
		};
		this.page = this.props.page;
		this.search = this.props.search;

		this.reload = this.reload.bind(this);
		this.onSearchChange = this.onSearchChange.bind(this);
	}

	onSearchChange(event) {
		this.setState({search: event.target.value.trim()});
	}

	componentWillReceiveProps(nextProps, oldsProps) {
		let page = nextProps.page;
		let search = nextProps.search;
		let reload = false;

		if (page !== undefined && page !== this.page) {
			this.page = page;
			reload = true;
		}
		if (search !== undefined && search !== this.search) {
			this.search = search;
			reload = true;
		}
		if (reload) {
			this.reload(page, search);
		}
	}

	componentDidMount() {
		this.reload(this.state.page, this.state.search);
	}

	reload(page, search) {
		this.setState({step: "LOADING", page: page, search: search});

		this.props.doAction(page, search)
			.then((response) => {
				if (this.props.pagination) {
					this.setState({step: "OK", values: response.values, pagination: response.pagination});
				} else {
					this.setState({step: "OK", values: response});
				}
			})
			.catch((e) => {
				if (e.restCode === "mustUpgradePlan") {
					return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
				}
				console.error(e);
				this.setState({step: "ERROR", values: null, pagination: null});
			});
	}

	render() {
		if (this.state.step === "LOADING") {
			return <Loader title={this.props.title}/>
		}
		if (this.state.step === "MUST_UPGRADE") {
			return <MustUpgrade title={this.props.title} plan={this.state.requiredPlan} clubId={this.props.clubId}/>
		}
		if (this.state.step === "ERROR") {
			return <p>Une erreur s'est produite</p>;
		}
		let baseUrl = this.props.pagination.baseUrl;
		if (this.props.searchable) {
			baseUrl += `search=${this.state.search}&`;
		}

		let paginationBody = '';
		if (this.props.customPaginationBody) {
			paginationBody = this.props.customPaginationBody;
		} else if (this.props.pagination) {
			paginationBody = <Pagination currentPage={this.state.pagination.page}
				maxPage={this.state.pagination.pagesCount}
				baseUrl={baseUrl}
				resultsCount={this.state.pagination.resultsCount}
			/>
		}

		return (
			<div className={this.props.specificClassName || "" }>
				<header>
					<div className="title-and-filter">
						<h1 className="pageTitle">{this.props.title}</h1>
						{this.props.searchable &&
						<div className="super-filter">
							<input type="text" value={this.state.search} onChange={this.onSearchChange} placeholder="Recherche..."/>
							<Link className="button-primary" to={baseUrl}>Filtrer</Link>
						</div>
						}
					</div>
					{this.props.pagination && paginationBody}
				</header>
				{React.cloneElement(this.props.children, {values: this.state.values}) }
				{this.props.pagination && this.props.pagination.paginationAtEnd && paginationBody}
			</div>
		);
	}
}

export default SuperComponent;
