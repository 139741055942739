import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Loader from '../components/loader';
import {Link} from 'react-router-dom';

class Subscription extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step : "LOADING",
			selectedPlan: ""
		};
	}

	componentDidMount(){
		this.reload(this.props.initialStep);
	}

	reload = (stepOnSuccess) => {
		this.setState({step:'LOADING'});
		API.GET(`/user/me/club/${this.context.place.id}/subscription`)
		.then(res=>{
			this.setState({step: stepOnSuccess || 'OK', subscription:res});
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e,e.stack);
		})
		;
	};

	render() {
		if (this.state.step === "LOADING") {
			return <Loader title="Votre abonnement"/>;
		}

		if (this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>;
		}

		let subscription = this.state.subscription;

		return <DisplaySubscription
			placeId = {this.context.place.id}
			plan={subscription.plan}
			planDisplay={subscription.display}
			to = {subscription.to}
			paymentMethod = {"Prélèvement SEPA : "+subscription.iban}
			subscribe={this.subscribe}
			upgrade={this.upgrade}
		/>
	}
}

export default Subscription;

const DisplaySubscription = (props) => {
	if(props.plan === 'NONE')			return <DisplaySubscriptionNone placeId = {props.placeId} />;
	if(props.plan === 'ANNUAIRE')		return <DisplaySubscriptionNone placeId = {props.placeId} subscribe = {true} />;
	if(props.plan === 'PREMIUM')		return <DisplaySubscriptionExists placeId = {props.placeId}  planDisplay={props.planDisplay} to = {props.to} paymentMethod = {props.paymentMethod} upgrade={true}/>;
	if(props.plan === 'PERFORMANCE')	return <DisplaySubscriptionExists placeId = {props.placeId}  planDisplay={props.planDisplay} to = {props.to} paymentMethod = {props.paymentMethod} />;

	//HERE ARE THE DEMO
	return <DisplaySubscriptionExists planDisplay={props.planDisplay} to = {props.to} paymentMethod = {props.paymentMethod} />
};

const DisplaySubscriptionNone = props => {
	return (
		<section className="club-subscription">
			<h1 className="pageTitle">Votre abonnement</h1>
			<div>
				<strong>Votre fiche club n'est pas activée.</strong>
				{props.subscribe && <p>
					Choisissez une formule d'abonnement pour recevoir des prospects.
					<br/><br/>
					<Link to={`/club/${props.placeId}/subscription/subscribe`} className="button-primary">Voir les formules d'abonnement</Link>
				</p>
				}
			</div>
		</section>
	);
};



const DisplaySubscriptionExists= props => {
	return (
		<section className="club-subscription">
			<h1 className="pageTitle">Votre abonnement</h1>
			<div>
				<p>
					Votre souscription <em>{props.planDisplay}</em>
					{!!props.to && <span>est valable jusqu'au <em>{props.to}</em></span>}
				</p>
				{props.paymentMethod &&
				<p>
					<strong>Méthode de paiement</strong>
					{props.paymentMethod}
				</p>
				}
				{/**props.upgrade && <p>
					Choisissez une formule d'abonnement pour recevoir des prospects.
					<br/><br/>
					<Link to={`/club/${props.placeId}/subscription/upgrade`} className="button-primary">Upgrader mon compte</Link>
				</p>
				*/}
			</div>
		</section>
	);
};
