import React from 'react';
import API from '../utils/api';
import { Chart } from 'react-google-charts';

const options = {
	title: "Nombre de prospects par mois",
	vAxis: {
		viewWindow: {
			min: 0
		}
	},
	colors: [
		"#465362",
		"#FF005A"
	],
	seriesType:"bars",
	series: {
		0: {
			targetAxisIndex: 0
		},
		1: {
			type: "line",
			targetAxisIndex: 1
		}
	}
};
class MultiStats extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			step : "LOADING"
		};
		this.compute = this.compute.bind(this);
	}

	componentDidMount(){
		API.GET(`/statistic`)
		.then(res=>{
			if(!res){
				this.setState({step:"NOTHING"});
				return;
			}
			var stats = this.compute(res);
			this.setState({step:"OK", statistics: stats});
		})
		.catch(e=>{
			console.error("[MultiStats]", "Cannot get statistics", e);
			this.setState({step:"ERROR"});
		})
		;
	}

	render(){
		if(this.state.step === "LOADING"){
			return <p>Loading</p>;
		}
		if(this.state.step === "ERROR"){
			return <p>Error</p>;
		}
		if(this.state.step === "NOTHING"){
			return <p>Il semble que vous n'ayez accès à aucun club</p>;
		}
		return <Chart
			chartType="ComboChart"
			data={this.state.statistics}
			width="100%"
			options={options}
		/>
	}


	compute(stats){
		var res = {};
		stats.forEach(s=>{
			res[s.date] = res[s.date] || {
				views:0,
				prospects: 0
			};
			switch(s.type){
				case "nb_consultations":
					res[s.date].views += s.hits;
					break;
				case "nb_demandes_information":
				case "nb_demandes_essais_gratuits":
				case "nb_demandes_essais_payants":
				case "RESERVE_WITH_GOOGLE":
				case "nb_demandes_devis":
					res[s.date].prospects += s.hits;
					break;

				case "nb_visites_page_contact":
				case "nb_affichages_resultats":
					//Nothing
					break;
				default:
					console.warn("Unknow stat type:", s.type);
					break;

			}
		});
		var computed = [];
		computed.push(["Mois","Prospects","Vues"]);
		Object.keys(res).sort().forEach(p=>{
			var prettyDate = p.split('-');
			prettyDate = prettyDate[1]+"/"+prettyDate[0].slice(-2);
			var line = [prettyDate, res[p].prospects, res[p].views];
			computed.push(line);
		});
		return computed;
	}
}

export default MultiStats;
