import React from 'react';
import PropTypes from 'prop-types';
import AboConfig from '../containers/AboConfig';
import AboPlans from '../containers/AboPlans';
import Options from '../containers/Options';

export default class AboEnLigne extends React.Component {
	static contextTypes = {
		user: PropTypes.object.isRequired
	};

	render(){
		return <div>
			<AboHowTo/>
			<br/>
			<AboPlans/>
			<Options/>
			<AboConfig/>
		</div>
	}
}

const AboHowTo = props => {
	return (
		<section className="sale-how-to">
			<header>
				<h1>Abonnement en ligne : fonctionnement</h1>
			</header>
			<section>
				<ul>
					<li>Pour activer l'abonnement en ligne il faut paramétrer 1 à 3 abonnements en cliquant sur le bouton "+".</li>
					<li>La configuration est obligatoire, n'hésitez pas à nous appeler pour toute question au 09 62 68 45 59</li>
					<li>Les notifications de vente se font en temps réel par email et dans la section "Vos ventes"</li>
					<li>Masalledesport est rémunéré au succès uniquement, 12% de commission (avec un minimum de 40€ TTC) sur la base d'un abonnement annuel</li>
				</ul>
			</section>
		</section>
	);
};
