import React from 'react';
import PropTypes from "prop-types";
import Cropper from "react-cropper";

import API from "../utils/api";
import pictoDelete from "../static/icons/picto-delete--red.svg";
import whiteAddIcon from "../static/icons/picto-add--white.svg";

class SimpleImageCropper extends React.Component {
  static contextTypes = {
    user: PropTypes.object.isRequired,
    place: PropTypes.object.isRequired,
  };

  constructor(props, context) {
    super(props, context);
    this.state = {
      step: 'OK',
      currentAvatar: props.currentValue,
    };
    this.setAvatar = props.onChange;
    this.onDelete = props.onDelete;
  }

  addImage = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);
    this.setState({ step: "ADDING", fileType: files[0].type });
  };

  cropImage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
      return;
    }

    this.setState({step :"ADDING"});

    let img = this.cropper.getCroppedCanvas().toDataURL(this.state.fileType);

    this.setState({
      cropResult: img,
    });
    let clubId = this.context.place.id;

    var body = {
      image : img
    };

    API.POST(`/user/me/club/${clubId}/upload`, body)
    .then(res =>{
      this.setAvatar(res.path);
    })
    .catch((e)=>{
      this.setState({action :"ERROR"});
      console.error(e, e.stack);
    });
  };

  cancel = () => {
    this.setState({ step: "OK" });
  }

  render() {
    if (this.state.step === "UPLOADING")
      return (<div>
        <p>Votre image est en cours de téléchargement</p>
      </div>);
    else if (this.state.step === "ERROR")
      return (<div>
        <p>Une erreur est survenue</p>
      </div>);
    else if (this.state.step === 'ADDING')
      return (
        <div className="galerie_add">
          <section>
            <div className="galerie_add_cropper">
              <Cropper
                style={{"maxHeight": 300, "maxWidth": 400}}
                aspectRatio={4 / 3}
                guides={false}
                src={this.state.src}
                ref={cropper => { this.cropper = cropper; }} />
            </div>
            <div className="galerie_add_actions">
              <button className="button-false" style={{display:'none'}}>Hide</button> {/* Cropper use first button after cropping */}
              <button className="button-save" onClick={this.cropImage}>Valider</button>
              <button className="button-cancel" onClick={this.cancel}>Annuler</button>
            </div>
          </section>
        </div>
      );

    if (!!this.state.currentAvatar && this.state.step === "OK")
      return (
        <div className={'user-avatar'}>
          <img src={this.state.currentAvatar} alt={'coachAvatar'} style={{ maxWidth: '300px' }} />
          {this.onDelete && <div>
            <button className='button-delete' onClick={this.onDelete}>
              <img src={pictoDelete} alt="Supprimer" title="Supprimer cette photo"/>
            </button>
          </div>
          }
        </div>
      );

    return (<div className={'club-gallery-actions'}>
      <label className="button-add">
        <img src={whiteAddIcon} alt="Ajouter"/>
        <input className="fileUpload_input" type="file" accept="image/*" onChange={this.addImage}/>
      </label>
    </div>);
  }
}

export default SimpleImageCropper;
