import React from 'react';
import { Link } from 'react-router-dom';

export default function ClubsChooser(props) {
	let clubs = props.clubs;
	let selected = props.selected;
	return (
		<div className="club-switcher">
			<a className="club-switcher-selected">
				{(selected && selected.name) || 'Choisissez un club'}
			</a>
			<ul>
				<li key="_all">
					<Link to="/clubs">
						<strong>Voir tous</strong>
					</Link>
				</li>
				{clubs &&
					clubs.map((c) => (
						<li key={c.id} className="clubLink">
							<Link to={`/club/${c.id}`}>{c.name}</Link>
						</li>
					))}
				{props.canAddClub && (
					<li key="create" className="createNew">
						<Link to={`/club/createNew`}>Ajouter une nouvelle salle</Link>
					</li>
				)}
			</ul>
		</div>
	);
}
