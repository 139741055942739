import React, { useState, useContext, Fragment, useRef } from 'react';
import SuperComponent from '../containers/SuperComponent';
import { PlaceContext } from '../react_contexts';
import API from '../utils/api';
import queryString from 'query-string';
import moment from 'moment';

import cancelIcon from '../static/icons/picto-cancel.svg';

const CancelBookingModal = (props) => {

	const [state, setState] = useState({
		step: 'INIT'
	})

	const cancelBooking = () => {
		setState({ step: 'BOOKING_CANCELING' })

		API.PATCH(`/api/lead/place/${props.placeId}/booking/${props.booking.id}`, {
			status: 'CANCELED',
		})
		.then(() => {
			setState({ step: 'BOOKING_CANCELED' })
			props.reloadTable()
		})
		.catch(err => setState({ step: 'BOOKING_CANCELATION_ERROR', error: err.message }))
	}

	let title, body

	switch (state.step) {
		case 'BOOKING_CANCELATION_ERROR':
			title = 'Erreur lors de l\'annulation';
			body = <Fragment>
				<p>{state.error}</p>
				<div>
					<button className="button-primary" onClick={props.closeModal}>Fermer</button>
				</div>
			</Fragment>
			break
		case 'BOOKING_CANCELING':
			title = 'Annulation en cours'
			body = <p>Annulation en cours ...</p>
			break
		case 'BOOKING_CANCELED':
			title = 'Réservation annulée'
			body = <div><button className="button-primary" onClick={props.closeModal}>Fermer</button></div>
			break
		case 'INIT':
		default:
			title = 'Annuler la réservation'
			body = <Fragment>
				<span>Souhaitez vous annuler la reservation suivante ? </span>
				<p>
					Nom : {props.booking.user_information.family_name}<br/>
					Prénom : {props.booking.user_information.given_name}<br/>
					Pour le: {moment.unix(props.booking.slot.start_sec).format("DD/MM/YYYY kk:mm")}<br/>
					Service : {props.booking.name}<br/>
					Faite le: {moment(props.booking.date_creation).format("DD/MM/YYYY")}<br/>
				</p>
				{!!state.error && <p className="error">{state.error}</p>}
				<div>
					<button className="button-secondary" onClick={props.closeModal}>Non</button>
					<button className="button-primary" onClick={cancelBooking}>Oui</button>
				</div>
			</Fragment>
			break
	}

	return (
		<div className='modal-mask'>
			<div className='modal contacts-show'>
				<header>
					<h2>{title}</h2>
					<img src={cancelIcon} alt="X" onClick={props.closeModal} />
				</header>
				<div className="modal-content">
					{body}
				</div>
			</div>
		</div>
	)
}

export default function Bookings(props) {

	const [state, setState] = useState({ step: 'INIT', page: parseInt(queryString.parse(props.location.search).page) || 1 })

	const page = parseInt(queryString.parse(props.location.search).page) || 1

	const placeCtx = useContext(PlaceContext)
	const superComp = useRef(null)
	const changeScreenStep = (step, param) => {

		switch (step) {
			case 'LOAD_BOOKINGS':
				setState({ ...state, step })
				break
			case 'BOOKING_SELECTED':
				setState({ ...state, step, display: true, booking: param, cancelError: null })
				break
			case 'SHOW_BOOKINGS':
				setState({ ...state, step: 'SHOW_BOOKINGS', cancelError: null, bookings: param.values, pagination: param.pagination })
				break
			default:
				setState({ ...state, step: 'SHOW_BOOKINGS', display: false})
				break
		}
	}

	const placeId = placeCtx.place.id

	const doAction = (page) => {
		changeScreenStep('LOAD_BOOKINGS');
		return API.GET(`/api/lead/place/${placeId}/booking?page=${page}&resultPerPage=10`)
			.then(res => {
				const pagination = { ...res.pagination, pagesCount: res.pagination.maxPage, page: parseInt(res.pagination.page) }
				res = { ...res, pagination }
				changeScreenStep('SHOW_BOOKINGS', res)

				return res
			})
	}

	const displayCancelModal = (display, booking) => {
		if (display) changeScreenStep('BOOKING_SELECTED', booking)
		if (!display) changeScreenStep('SHOW')
	}

	const closeModal = () => displayCancelModal(false, null);
	const reloadTable = () => {
		superComp.current.reload(page)
	}

	return (
		<Fragment>
			<SuperComponent
				ref={superComp}
				clubId={placeId}
				title="Réservations"
				page={page}
				specificClassName="bookings-page"
				doAction={doAction}
				pagination={{ baseUrl: `${props.match.url}?` }}
			>
				<SimpleContainer displayCancelModal={displayCancelModal} />
			</SuperComponent>
			{state.display && <CancelBookingModal placeId={placeId} closeModal={closeModal} booking={state.booking} reloadTable={reloadTable} />}
		</Fragment>
	)
}

const translateStatus = (status) => {
	if (status === 'CANCELED') return 'Annulé'
	if (status === 'CONFIRMED') return 'Confirmé'
}

const SimpleContainer = (props) => {
	let bookings = props.values;
	if (props.values.length === 0) {
		return <p className="msdsAdvice">
			Vous n'avez pas encore reçu de reservation.
		</p>;
	}
	return (
		<table className="trial-requests">
			<thead>
				<tr>
					<th>Nom, Prénom</th>
					<th>Pour le </th>
					<th>Durée (minutes)</th>
					<th>Service</th>
					<th>Téléphone</th>
					<th>Email</th>
					<th>Statut</th>
					<th>Faite le </th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				{
					bookings.map((b) => {

						const onCancelBtClick = () => {
							props.displayCancelModal(true, b)
						}

						return <tr key={`line_booking_${b.id}`}>
							<td>{b.user_information.family_name} {b.user_information.given_name}</td>
							<td>{moment.unix(b.slot.start_sec).format("DD/MM/YYYY kk:mm")}</td>
							<td className="duration-col">{Math.floor(b.slot.duration_sec / 60)}</td>
							<td>{b.name}</td>
							<td className='trial-user-phone'>{b.user_information.telephone}</td>
							<td>{b.user_information.email}</td>
							<td>{translateStatus(b.status)}</td>
							<td>{moment(b.creation_date).format("DD/MM/YYYY")}</td>
							<td>{b.isCancelable && <button className="button-primary" onClick={() => onCancelBtClick()}>Annuler</button>}</td>
						</tr>
					})
				}
			</tbody>
		</table>
	);
};