import React from 'react';
import API from '../utils/api';
import OffreDetail from '../containers/OffreDetail';


class WidgetOfferConfiguration extends React.Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			default : true,
			editing : false
		};
	}

	offerDelete = (clubId) => {
		return API.DELETE(`/user/me/club/${clubId}/widget/offer`);
	};

	offerGet = (clubId) => {
		return API.GET(`/user/me/club/${clubId}/widget/offer`);
	};

	offerUpdate = (clubId, body) => {
		return API.PUT(`/user/me/club/${clubId}/widget/offer`, body);
	};

	render() {
		return (
			<div >
				<OffreDetail offerGet={this.offerGet} offerUpdate={this.offerUpdate} offerDelete={this.offerDelete}/>
			</div>

		);
	}
}

export default WidgetOfferConfiguration;
