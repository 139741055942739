import React from 'react';
import moment from 'moment';
import API from '../utils/api';
import { Chart } from 'react-google-charts';

const options = {
	title: "Volume de vente en ligne",
	vAxis: {
		viewWindow: {
			min: 0
		}
	},
	colors: [
		"#465362",
		"#FF005A"
	],
	seriesType:"bars",
	series: {
		0: {
			targetAxisIndex: 0
		},
		1: {
			type: "line",
			targetAxisIndex: 1
		}
	}
};
class MultiStatsVel extends React.Component {

	constructor(props){
		super(props);
		this.state = { step : "LOADING" };
	}

	componentDidMount(){
		this.setState({step:'LOADING'});

		let min = moment().add(-1, 'year').startOf('month').toDate().getTime();
		API.GET(`/sale?page=0&creation.min=${min}`)
		.then(res=>{
			let computed = {};
			res.values.forEach( v => {
				let month = moment(v.creation).format('YYYY-MM');
				computed[month] = computed[month] || 0;
				computed[month] += v.price;
			});

			computed = [
				['Mois', 'Volume']
				,...Object.keys(computed).sort().map( k => {
					return [k, computed[k]]
				})
			];
			this.setState({step:'OK', data:computed});
		})
		.catch(e=>{
			console.error("[MultiStatsVel]", "Cannot get statistics", e);
			this.setState({step:"ERROR"});
		})
		;
	}

	render(){
		if(this.state.step === "LOADING"){
			return <p>Loading</p>;
		}
		if(this.state.step === "ERROR"){
			return <p>Error</p>;
		}
		if(this.state.step === "NOTHING"){
			return <p>Il semble que vous n'ayez accès à aucun club</p>;
		}
		return <Chart
			chartType="ComboChart"
			data={this.state.data}
			width="100%"
			options={options}
		/>
	}

}

export default MultiStatsVel;
