import React, {useEffect, useState} from 'react';
import Cropper from 'react-cropper';
import API from '../utils/api';
import whiteAddIcon from '../static/icons/picto-add--white.svg';

export default class MyCropper extends React.Component {

	constructor(props, context){
		super(props, context);
		this.state = { action :"VIEW", "images" : null};
	}

	cancel = () => {
		this.setState({action :"VIEW"});
	};

	addImage = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({ src: reader.result });
		};
		reader.readAsDataURL(files[0]);
		this.setState({action:"ADDING", fileType:files[0].type});
	};

	cropImage = () => {
		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
			return;
		}

		this.setState({action :"UPLOADING"});

		const img = this.cropper.getCroppedCanvas().toDataURL(this.state.fileType);

		this.setState({
			cropResult: img,
		});

		const ext = this.state.fileType.split('/').slice(-1)[0];
		const body = {
			image : img,
			type: ext,
			fullPath: `${this.props.path}/${Date.now()}.${ext}`
		};

		API.POST(`/user/me/club/${this.props.placeId}/upload`, body)
		.then( ({fullPath}) => {
			this.props.onUpload({fullPath})
		})
		.catch( console.error )
	};

	render() {
		return (
			<div className="club-gallery">
				{this.state.action === "UPLOADING" && <p>Votre image est en cours de téléchargement</p> }

				{this.state.action === "VIEW" &&
					<section className="club-gallery-page">
						<header>
							<div className="club-gallery-actions">
								<label className="button-add" style={{padding:'0.5rem'}}>
									Uploader
									<input className="fileUpload_input" type="file" accept="image/*" onChange={this.addImage}/>
								</label>
							</div>
						</header>
					</section>
				}

				{this.state.action === "ADDING" &&
					<div className="galerie_add">
						{/* <h4 className="pageTitle">Mes offres > Ajout</h4> */}
						<section>
							<div className="galerie_add_cropper">
								<Cropper
									style={{"maxHeight": this.props.width, "maxWidth": this.props.height}}
									aspectRatio={ this.props.width / this.props.height}
									guides={false}
									src={this.state.src}
									ref={cropper => { this.cropper = cropper; }} />
							</div>
							<div>
								<p>Utilisez des images de {this.props.width}px sur {this.props.height}px</p>
								<div className="galerie_add_actions">
									<button style={{padding:'.5rem', color:'white', background:'red'}}className="button-save" onClick={this.cropImage}>Valider</button>
									<button className="button-cancel" onClick={this.cancel}>Annuler</button>
								</div>
							</div>
						</section>
					</div>
				}
			</div>
		);
	}
}
