import React from 'react';
import {TwitterPicker} from 'react-color';

class ColorPicker extends React.Component {

	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.showPicker = this.showPicker.bind(this);
		this.hidePicker = this.hidePicker.bind(this);
		this.state = {
			displayPicker: false,
			color: props.color || '#ffffff',
			field: props.name,
			disabled: props.disabled
		};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({
			disabled: nextProps.disabled
		});
	}

	showPicker() {
		if (!this.state.disabled) {
			this.setState({displayPicker: true});
		}
	}

	hidePicker() {
		this.setState({displayPicker: false});
	}

	onChange(selectedColor){
		this.setState({color: selectedColor.hex});
		this.props.onChangeColor(selectedColor.hex, this.state.field);
	}

	render() {
		let colors = this.props.colors || ['#AA47BC', '#7A1FA2', '#78909C', '#465A65', '#EC407A', '#C2175B', '#5C6BC0', '#0288D1', '#00579C', '#0098A6', '#00887A', '#004C3F', '#689F39', '#33691E', '#8C6E63', '#5D4038', '#7E57C2', '#512DA7', '#EF6C00', '#F5511E', '#BF360C', '#FACC15', '#CA8A04'];
		return (
			<div className="color-picker">
				<div className={"selected-color" + (this.state.disabled ? " disabled":"")} onClick={this.showPicker} style={{backgroundColor: this.state.color}}/>
				{this.state.displayPicker &&
					<div className="popover">
						<div className="cover" onClick={this.hidePicker}/>
						<TwitterPicker colors={colors} color={this.state.color} onChange={this.onChange}/>
					</div>
				}
			</div>
		);
	}
}

export default ColorPicker;

