import React from 'react';
import API from '../utils/api';
import Pagination from '../components/pagination';
import Source from '../components/source';
import moment from 'moment';

class MultiLeads extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			step : "LOADING",
			page : 1
		};
		this.changePage = this.changePage.bind(this);
		this.export = this.export.bind(this);
	}

	export(event) {
		event.preventDefault();
		return API.GET(`/contact`)
		.then(res=>{
			window.open(res.url);
		})
		.catch(e=>{
			console.error(e, e.stack);
		})
		;
	}

	changePage(event){
		event.preventDefault();
		this.setState({page: parseInt(event.target.dataset.page)});
	}

	componentDidMount(){
		API.GET(`/tryRequest`)
		.then(res=>{
			if(!res){
				this.setState({step:"NOTHING"});
				return;
			}
			this.setState({step:"OK", leads: res});
		})
		.catch(e=>{
			console.error(e);
			this.setState({step:"ERROR"});
		})
		;
	}

	render(){
		if(this.state.step === "LOADING"){
			return <p>Loading</p>;
		}
		if(this.state.step === "ERROR"){
			return <p>Error</p>;
		}
		if(this.state.step === "NOTHING"){
			return <p>Il semble que vous n'ayez accès à aucun club</p>;
		}

		const nbPerPage = 10;
		var page = this.state.page;
		var displayed = this.state.leads.slice( (page-1) * nbPerPage, page*nbPerPage );
		var pageMax = Math.ceil(this.state.leads.length / nbPerPage);
		const hasBirthdate = this.state.leads.find(l => !!l.birthdate);

		return <section style={{flexDirection:"column"}}>
			<h2>Vos derniers prospects</h2>
			<div style={{margin:'auto', marginBottom:'2rem'}}>
				<button onClick={this.export} className='button-primary'>Exporter</button>
			</div>
			<Pagination currentPage={this.state.page} maxPage = {pageMax} onClick={this.changePage} baseUrl='/clubs/lead?'/>
			<table>
				<thead>
					<tr>
						<th>Source</th>
						<th>Date</th>
						<th>Lead</th>
						{!!hasBirthdate && <th>Date de naissance</th>}
						<th>Club</th>
					</tr>
				</thead>
				<tbody>
				{displayed.map(l=>{
					return (
						<tr key={l.id}>
							<td><Source source={l.source}/></td>
							<td>{moment(l.date).format("DD/MM/YYYY")}</td>
							<td>{l.firstname} {l.lastname}</td>
							{!!hasBirthdate && <td>{l.birthdate}</td>}
							<td>{l.place.name}</td>
						</tr>
					);
				})}
				</tbody>
			</table>
		</section>;
	}

}

export default MultiLeads;
