import React from 'react';
import PropTypes from 'prop-types';
import MagicForm from '../components/magicForm';

class ClubAccess extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		placeUpdate : PropTypes.func.isRequired,
	};

	constructor(props, context){
		super(props, context);
		let access = this.context.place.access;
		this.state = this.accessToState(access);
	}

	render() {
		return (
			<div className="club-access-details">
			{Object.keys(this.state.form).map((key)=>{
				return <MagicForm
					id={key}
					key={key}
					title={"Accès "+this.state.form[key].title}
					formClass='club-access-item'
					editing = {this.state.form[key].editing}
					onCancelEdit = {this.onCancelEdit.bind(this, key)}
					onSubmit = {this.onSubmit.bind(this,key)}
					onEdit={this.onEdit.bind(this, key)}
					>
						<textarea name={key}
							placeholder="Détaillez cette section"
							onChange={this.onChange} value={this.state.form[key].value}
						/>
				</MagicForm>
			})}
			</div>
		);
	}

	accessToState = (access) => {
		return {
			form : {
				subway : { title:"Métro", editing: false, value : access.subway}
				,rer : { title:"RER", editing: false, value : access.rer}
				,tram : { title:"Tram", editing: false, value : access.tram}
				,bus : { title:"Bus", editing: false, value : access.bus}
				,cycle : { title:"Vélo", editing: false, value : access.cycle}
				,parking : { title:"Parking", editing: false, value : access.parking}
				,road : { title:"Route", editing: false, value : access.road}
				,other : { title:"Autres", editing: false, value : access.other}
			}
		};
	};

	onSubmit = (key, event) => {
		event.preventDefault();
		event.stopPropagation();

		let newState = Object.assign({}, this.state);

		var body = {
			access: {
				[key] : this.state.form[key].value
			}
		};

		this.context.placeUpdate(body)
		.then((reponse) =>{
			newState.form[key].editing=false;
			this.setState(newState);
		})
		.catch((e) => console.error(e));
	};

	onCancelEdit = (key, event) => {
		event.preventDefault();
		let newState = Object.assign({}, this.state);
		newState.form[key].value = this.context.place.access[key];
		newState.form[key].editing = false;
		this.setState(newState);
	};

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name].value = event.target.value;
		this.setState(newState);
	};

	onEdit = (key, event) => {
		event.preventDefault();
		let newState = Object.assign({}, this.state);
		newState.form[key].editing = true;
		this.setState(newState);
	};
}

export default ClubAccess;
