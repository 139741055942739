import React from 'react';
import API from '../utils/api';
import moment from 'moment';

class NewsList extends React.Component{

	constructor(props){
		super(props);
		this.state={
			step : "LOADING"
		};
	}

	componentDidMount(){
		API.GET(`/news`)
		.then(res=>{
			this.setState({ step : "OK", news:res})
		})
		.catch(e=>{
			console.error(e, e.stack);
		})
		;
	}

	render(){
		if(this.state.step === "LOADING"){
			return <p>Loading</p>;
		}

		if(!this.state.news.length){
			return null;
		}

		return (
			<section className="dashboard-news">
				<header>
					<h2>Actualités</h2>
				</header>
				<ul>
					{this.state.news.map((n,i)=>{
						return <li key={i}><News news={n} opened={i===0}/></li>
					})}
				</ul>
			</section>
		);
	}
}

export default NewsList;

class News extends React.Component{

	constructor(props){
		super(props);
		this.state = {
			expand : props.opened
		};
	}

	render(){
		const news = this.props.news;
		return (
			<section className="news-item">
				<header>
					<h2>
						{news.title}
						<small> - {moment(news.creation).format("DD/MM/YYYY")}</small>
					</h2>
					{this.state.expand && (
						<button className="button-secondary" onClick={(event)=>{event.preventDefault();this.setState({expand:false})}}>Fermer</button>
					)}
					{!this.state.expand && (
						<button className="button-secondary" onClick={(event)=>{event.preventDefault();this.setState({expand:true})}}>Lire</button>
					)}
				</header>

				{this.state.expand && (<article>
					<div className="news_content" dangerouslySetInnerHTML={{__html : news.content}}/>
				</article>)}
			</section>
		);
	}
}
