import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import StarsRating from '../components/StarsRating';
import ReviewsChart from '../containers/ReviewsChart';
import ReviewsList from '../containers/ReviewsList';
import Source  from '../components/source';

import starIcon from '../static/icons/picto-star.svg';

class ReviewsContainer extends React.Component {

	static contextTypes = {
		place: PropTypes.object
	};

	constructor(props, context) {
		super(props, context);
		this.load = this.load.bind(this);
		this.setSelectedRate = this.setSelectedRate.bind(this);

		this.state = {
			step: "LOADING",
			selectedRate: null,
			sources : ['MSDS', 'WIDGET', 'FACEBOOK_PAGE', 'GOOGLE_MYBUSINESS']
		};

		this.sourceSelected = this.sourceSelected.bind(this);
	}

	sourceSelected(event){
		var source = event.target.dataset.source;
		var newState = Object.assign({}, this.state);
		var index = newState.sources.indexOf(source);
		if(index === -1)
			newState.sources.push(source);
		else if(newState.sources.length > 1)
			newState.sources.splice(index, 1);
		this.setState(newState);
		this.load(newState.sources);
	}

	componentDidMount() {
		this.load(this.state.sources);
	}

	setSelectedRate(rate) {
		this.setState({
			selectedRate: rate
		});
	}

	load(sources) {
		this.setState({step:'LOADING'});
		var places = this.props.places || [this.context.place];
		var url = `/review?sources=${sources.join(',')}&places=${places.map(p=>p.id).join(',')}`;

		return API.GET(url)
		.then(res => {
			var displayed = res.filter(r=>r.status === "OK").filter(r=>!r.hidden);
			var totalRank = displayed.reduce((res, review) => res+review.marks.average, 0);

			let displayAvgRank = displayed.length &&
				Math.round(totalRank / displayed.filter(d => {
					return (d.source.type !== 'FACEBOOK_PAGE' || d.marks.average !== null)
				}).length * 10) / 10;

			this.setState({
				step: "OK",
				reviews: res,
				displayed : displayed,
				displayAvgRank : displayAvgRank
			});
		}).catch(e => {
			if (e.restCode && e.restCode === "mustUpgradePlan") {
				this.setState({
					step: "MUST_UPGRADE",
					requiredPlan : e.requiredPlan
				});
			} else {
				console.error(e);
				this.setState({
					step:"ERROR",
					error: e
				});
			}
		});
	}

	render() {
		if (this.state.step === "LOADING") {
			return <p>Loading</p>
		}

		if (this.state.step === "ERROR") {
			return <div className="msdsAdvice">Une erreur est survenue : {this.state.error}</div>
		}

		var hidden = this.state.reviews.length - this.state.displayed.length;

		return (
			<section className="club-reviews-page">
				<ReviewGeneral avg={this.state.displayAvgRank} count={this.state.displayed.length} hiddenCount={hidden}
					sourcesSelected={this.state.sources} onSourceSelect={this.sourceSelected}
				/>
				<div className="club-reviews-warning">
					<p>
					Les avis Google/Facebook ne sont pas visibles sur le site MaSalleDeSport.
					</p>
					<p>
						Les avis Facebook sont désormais devenus des Recommandations. Il vous est toujours possible d'y
						répondre depuis notre interface, mais ils ne sont pour le moment plus comptabilisés dans les
						statistiques globales affichées ci-dessous.
					</p>
					<p>Notre équipe technique travaille pour remédier à ce problème. Merci de votre compréhension.</p>
				</div>
				<div className="reviews-charts">
					<div>
						<h5>Répartition des notes</h5>
						<ReviewsDistribution reviews={this.state.displayed.filter(d => {
							return (d.source.type !== 'FACEBOOK_PAGE' || d.marks.average !== null)
						})} selectedRate={this.state.selectedRate} onSelectRate={this.setSelectedRate}/>
					</div>
					<div>
						<ReviewsChart reviews={this.state.displayed.filter(d => {
							return (d.source.type !== 'FACEBOOK_PAGE' || d.marks.average !== null)
						})}/>
					</div>
				</div>
				<ReviewsList reviews={this.state.reviews} selectedRate={this.state.selectedRate}/>
			</section>
		);
	}
}

const ReviewGeneral = ({avg, count, hiddenCount, sourcesSelected, onSourceSelect})=> (
	<div className="reviews-general-infos">
		<div className="reviews-general-rank">
			<h3>Note globale</h3>
			<strong>{avg}<small>/5</small></strong>
			<StarsRating rating={avg}/>
			<p>{count} avis</p>
			{hiddenCount > 0 && <p>{hiddenCount} avis caché{hiddenCount > 1 && 's'}</p>}
		</div>
		<ul className="reviews-general-social">
			<li>
				<label>
					<input type='checkbox'  onChange={onSourceSelect} name='source' checked={sourcesSelected.indexOf('FACEBOOK_PAGE') !== -1} data-source='FACEBOOK_PAGE'/>
					<span><Source source='FACEBOOK_PAGE'/> Facebook</span>
				</label>
			</li>
			<li>
				<label>
					<input type='checkbox'  onChange={onSourceSelect} name='source' checked={sourcesSelected.indexOf('GOOGLE_MYBUSINESS') !== -1} data-source='GOOGLE_MYBUSINESS'/>
					<span><Source source='GOOGLE_MYBUSINESS'/> Google</span>
				</label>
			</li>
			<li>
				<label>
					<input type='checkbox' onChange={onSourceSelect} name='source' checked={sourcesSelected.indexOf('MSDS') !== -1} data-source='MSDS'/>
					<span><Source source='MSDS'/> MSDS</span>
				</label>
			</li>
			<li>
				<label>
					<input type='checkbox' onChange={onSourceSelect} name='source' checked={sourcesSelected.indexOf('WIDGET') !== -1} data-source='WIDGET'/>
					<span><Source source='WIDGET'/> WIDGET</span>
				</label>
			</li>
		</ul>
	</div>
);

const ReviewsDistribution = ({reviews, selectedRate, onSelectRate}) => {
	var count = 0;
	var reviewsByRate = {5: [], 4: [], 3: [], 2: [], 1: []};
	reviews
	.forEach(r => {
		reviewsByRate[r.marks.average] && reviewsByRate[r.marks.average].push(r);
	});
	Object.keys(reviewsByRate).forEach((rate) => {
		count += reviewsByRate[rate].length;
	});

	return (
		<ol className="rate-distribution">
			{Object.keys(reviewsByRate).map((rate) => {
				let feedbacks = reviewsByRate[rate];
				let percent = reviewsByRate[rate].length / count * 100;
				return (
					<li key={rate} className={feedbacks.length === 0 ? "empty" : ""}
							onClick={() => onSelectRate(selectedRate === rate ? null : rate)}>
						<button className={selectedRate === rate ? "active" : ""}>
							<span>{rate}</span>
							<img src={starIcon} alt="Étoile(s)"/>
						</button>

						<button className={"rate-percentage" + (selectedRate === rate ? " active" : "")}>
							{percent > 0 && <span style={{width: percent + "%"}}>{feedbacks.length}</span>}
						</button>
					</li>
				)
			})}
		</ol>
	);
};

export default ReviewsContainer;
