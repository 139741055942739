import React from 'react';
import StarsRating from '../components/StarsRating';
import ModerationModal from '../components/moderationModal';
import API from '../utils/api';
import MustUpgrade from '../components/mustUpgrade';
import Source  from '../components/source';
import moment from 'moment';

import visibleIcon from '../static/icons/picto-visible.svg';
import hiddenIcon from '../static/icons/picto-hidden.svg';
import abusIcon from '../static/icons/picto-abus.svg';

class Review extends React.Component {

	constructor( props ) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCancel = this.onCancel.bind(this);

		this.showModerateModal = this.showModerateModal.bind(this);
		this.hideModerateModal = this.hideModerateModal.bind(this);

		this.state = {
			displayModerateModal: false,
			targetReviewId: null,
			review : props.review,
			step: "DISPLAY",
			text: ""
		};
	}

	onCancel(event){
		event.preventDefault();
		this.setState({step: "DISPLAY"});
	}

	onChange(event) {
		this.setState({ text: event.target.value});
	}

	onSubmit(event) {
		event.preventDefault();

		var text = this.state.text.trim();
		if(!text){
			return this.setState({text:""});
		}

		this.setState({
			step: "PENDING",
			error: false
		});
		let reviewId = this.state.review.id;
		API.POST(`/review/${reviewId}/answer`, {text : text} )
		.then(res => {
			if(res !== null){
				return this.setState({
					step: "ANSWER",
					error: true
				});
			}
			let newState = Object.assign({}, this.state);
			newState.step = "DISPLAY";
			newState.review.answer = {
				date : new Date().getTime(),
				text : text
			};
			this.setState(newState);
			return;
		})
		.catch(e => {
			console.error(e);
			this.setState({
				step: "ANSWER",
				error: true
			});
		});
	}

	showModerateModal(event) {
		event.preventDefault();
		this.setState({
			displayModerateModal: true,
			targetReviewId: event.currentTarget.dataset.reviewId
		});
	}

	hideModerateModal() {
		this.setState({
			displayModerateModal: false,
			targetReviewId: null
		});
	}

	render() {
		const review = this.state.review;
		var externalPageStyle = review.externalPage && review.externalPage.status!=="OK" && {color:'gray'} || {};

		return (
			<li className={`review review_${review.status}`}>
				<header>
					<div>
						<div className="review-meta">
							<Source source={review.source.type} />
							{!review.hidden && review.marks.average &&
							<StarsRating rating={review.marks.average}/>
							}
							{!review.hidden &&
							<p>&nbsp;{review.author.firstName}&nbsp;{review.author.lastName},</p>
							}
							<em>&nbsp;le&nbsp;{moment(review.date).format("DD/MM/YYYY")}</em>
							{review.status === "OK" && ['MSDS','WIDGET'].indexOf(review.source.type) !== -1 &&
								<span className="review-visibility">
									<img alt='visibility' src={visibleIcon}/>
									visible
								</span>
							}
							{review.status !== "OK" && ['MSDS','WIDGET'].indexOf(review.source.type) !== -1 &&
								<span className="review-visibility">
									<img alt='visibility' src={hiddenIcon}/>
									caché
								</span>
							}
						</div>
						{review.status === "MODERATED" && <strong>Modéré</strong>}
						{review.status === "OK" && ['MSDS','WIDGET'].indexOf(review.source.type) !== -1 &&
							<button className="button-secondary" title="Demande de modération" data-review-id={review.id} onClick={this.showModerateModal}>
								<img src={abusIcon} alt="Abus"/>
								Demande de modération
							</button>
						}
						{review.status === "WAITING_CONFIRMATION" && <strong>En attente de validation</strong>}
						{review.status === "MODERATION_ASKED" && <strong>Modération demandée</strong>}
					</div>
					<em>pour {review.place.name}</em>
					{review.externalPage && (
						<p style={{marginTop:'1rem'}}>
							via la page &nbsp;
							<a target='_blank' rel="noopener noreferrer" style={externalPageStyle} href={review.externalPage.url}>
								{review.externalPage.name}{review.externalPage.status!=="OK" && <span>&nbsp;!</span>}
							</a>
						</p>
					)}
					{!review.hidden && <strong>{review.comments.title}</strong>}
				</header>

				<div className="review-content">
					{!review.hidden && review.comments.general && <p>{review.comments.general}</p>}
					{review.hidden && <MustUpgrade plan={{display:review.hidden.neededPlan}} clubId={review.place.id} goal="voir cet avis"/> }
				</div>

				{review.answer &&
					<div className="review-answer">
						<span><strong>Réponse</strong>&nbsp;-&nbsp;<i>{moment(review.answer.date).format('DD/MM/YYYY')}</i></span>
						<p>{review.answer.text}</p>
					</div>
				}

				{this.state.step === "DISPLAY" && review.answerable &&
					<button className="button-primary" onClick={()=>this.setState({step:"ANSWER"})}>Répondre</button>
				}

				{!review.answer && this.state.step === "PENDING" &&
					<div className="review-answer-pending">Envoi en cours...</div>
				}

				{!review.answer && this.state.step === "ANSWER" &&
					<form className="review-answer-form" onSubmit={this.onSubmit}>
						{this.state.error &&
							<p style={{color:'red'}}>Une erreur est survenue</p>
						}

						<textarea name="text" placeholder="Votre réponse" value={this.state.text} onChange={this.onChange}/>
						<div>
							<button className="button-secondary" onClick={this.onCancel}>Annuler</button>
							<button className="button-primary" onClick={this.onSubmit}>Envoyer</button>
						</div>
					</form>
				}
				{this.state.displayModerateModal &&
					<ModerationModal closeModal={this.hideModerateModal} review={this.state.review} reviewId={this.state.targetReviewId}/>
				}
			</li>
		);
	}
}

export default Review;
