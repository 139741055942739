import React, {useEffect, useState} from 'react';
import Cropper from 'react-cropper';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Loader from '../components/loader';
import whiteAddIcon from '../static/icons/picto-add--white.svg';
import pictoDelete from '../static/icons/picto-delete--red.svg';
import pictoDownload from '../static/icons/picto-download.svg';
import Tabs from '../components/Tabs';
import MyCropper from './Cropper';
import OnePlace from './OnePlace';

export default function Places({vel}){
	const [places, placesSet] = useState(false);
	const [selected, selectedSet] = useState(false)
	const doReload = () => {
		placesSet(false);
		API.GET(`/r2vel/${vel.id}/place`)
		.then( placesSet )
	};
	useEffect( doReload, [vel.id]);

	const backToList = ({reload}) => {
		selectedSet(false)
		if(reload)
			doReload();
	};

	if( places === false)
		return null;

	if( selected ) {
		return <OnePlace vel={vel} place ={selected} backToList={backToList}/>
	}

	return (
		<>
			<h2>Mes clubs ({places.length})</h2>
			<table>
				<tbody>
				{places.map( p => {
					return (
						<tr key={p.msdsId} style={{cursor:'pointer', margin:'1rem'}} onClick={() => selectedSet(p)}>
							<td>
								<strong style={{fontSize:'1.2rem'}} onClick={() => selectedSet(p)}>{p.name}</strong>
								<p>{p.addresse}</p>
								<p>{p.addresse2}</p>
								<p>{p.zip} {p.city}</p>
							</td>
							<td>
								{p.offer_url && <img width='120' height='auto' src={p.offer_url}/>}
							</td>
						</tr>
					);
				})}
				</tbody>
			</table>
		</>
	)
}
