import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import logo from '../static/logos/logo.svg';

const TIMER = 20;

export default function UnexpectedError() {
	const history = useHistory();
	useEffect(() => {
		const timer = setTimeout(() => {
			history.replace('/');

			return () => clearTimeout(timer);
		}, TIMER * 1000);
	});

	return (
		<div className="root">
			<div className="rootContainer">
				<header className="mainHeader">
					<h1 className="logo">
						<img src={logo} alt="MSDS" />
						<span>Ma Salle de Sport</span>
					</h1>
				</header>
				<section className="msg-page page">
					<div className="error">
						<h1>Une erreur s'est produite</h1>
						<p>
							Veuillez nous excuser pour la gêne occasionnée.
							<br />
							Cette page va tenter de se rafraichir toutes les {TIMER} secondes.
							<br />
							Si le problème persiste, n'hésitez pas à nous contacter
						</p>
					</div>
				</section>
			</div>
		</div>
	);
}
