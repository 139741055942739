import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PlaceContext } from '../react_contexts';
import UserClubAdd from '../containers/UserClubAdd';

export default function MultiClubs() {
	const { places } = useContext(PlaceContext);

	return (
		<section className="multi-dashboard-clubs">
			{places.length !== 0 && (<h2>Vos clubs</h2>)}
			{places.length !== 0 && (
				<ul className="clubs-list">
					{places.map(c => {
						return (
							<li key={c.id} className="clubs-list-item">
								<Link to={`/club/${c.id}`} className="club-link">
									{c.name}
								</Link>
							</li>
						);
					})}
				</ul>
			)}
			{!places.length && <UserClubAdd/> }
		</section>
	);
}
