import React from 'react';
import Tooltip from '../components/Tooltip';

import infoIcon from '../static/icons/picto-info.svg';

class SubscriptionPlan extends React.Component {

	onClick = (event) => {
		if(!this.props.selectable) return;
		this.props.planSelected(this.props.plan.title, event.currentTarget.dataset.monthly);
	};

	render() {
		let plan = this.props.plan;
		var planStatus = (this.props.selectable ? "" : ' disabled');

		var className = "plan-card" + (this.props.selected ? " selected": "") + planStatus;

		return (
			<div className={className}>
				{this.props.ribbon &&
				<div className="ribbon">
					<span>{this.props.ribbon}</span>
				</div>
				}
				<header>
					<h4>{plan.display}</h4>
				</header>
					<div>
						{plan.leads}
					</div>
				<ul>
					{plan.features.map((f, i) =>
						<li key={i}>
							{f.title}
							<Tooltip text={f.desc} position="top"><img src={infoIcon} alt="i"/></Tooltip>
						</li>
					)}
				</ul>

				<section>

					<div onClick={this.onClick} data-monthly={true} className={(this.props.monthly && this.props.selected) ? `selected ${planStatus}` : `${planStatus}` }>
						<span>
							{this.props.prices.monthly}
							<small>€ HT / mois</small>
						</span>
					</div>

					<div onClick={this.onClick} className={(!this.props.monthly && this.props.selected) ? `selected ${planStatus}` : `${planStatus}`}>
						<span>
							{this.props.prices.annual}
							<small>€ HT / an</small>
						</span>
						<em>au lieu de {this.props.prices.monthlyAnnual}€ HT</em>
					</div>

				</section>
			</div>
		);
	}
}

export default SubscriptionPlan;