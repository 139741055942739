import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../components/Tabs';

class WidgetAndFacebook extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render() {
		const placeId = this.context.place.id;

		var menu = [
			{ path : 'widget'   , display: 'Widget'},
			{ path : 'facebook' , display: 'Facebook'},
			{ path : 'meta_google_ads' , display: 'Meta/Google Ads'}
		];

		return (
			<Tabs baseUrl={`/club/${placeId}/widgetAndFacebook/`} className="club-prospects" items={menu}>
				{this.props.children}
			</Tabs>
		);
	}

}
export default WidgetAndFacebook;
