import React from 'react';
import PropTypes from 'prop-types';
import MagicForm from '../components/magicForm';
import Loader from '../components/loader';
import MustUpgrade from '../components/mustUpgrade';
import Duplicate from '../components/Duplicate';

class OffreDetail extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		places : PropTypes.array.isRequired
	};

	constructor(props, context) {
		super(props, context);

		this.state = {editing : false, step:'LOADING'};
		this.state.offer = {
			"FREE_1" : {},
			'FREE' : {
				count :"",
				type: 'ENTRY'
			},
			'REMB' : {
				price : ''
			},
			'PROMO' :{
				price : '',
				publicPrice : '',
			},
			'REDUC' : {
				value : '',
				type  : '%',
				on    : 'ABO'
			},
			TEXT : {
				start : "Recevez",
				detail : ''
			}
		};
		this.reload = this.reload.bind(this);
		this.offerTypeChosed = this.offerTypeChosed.bind(this);
		this.toggleAppointment = this.toggleAppointment.bind(this);
		this.offerParamsChange = this.offerParamsChange.bind(this);
		this.mailParamsChange = this.mailParamsChange.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onCancelEdit = this.onCancelEdit.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onDelete = this.onDelete.bind(this);
	}

	componentDidMount(){
		this.reload();
	}

	onDelete(event){
		event.preventDefault();
		let clubId = this.context.place.id;
		this.props.offerDelete(clubId)
		.then(res => {
			this.reload();
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error(e);
			this.setState({step:"ERROR", error :e});
		})
		;
	}

	reload(){
		this.setState({step : "LOADING"});
		let clubId = this.context.place.id;

		return this.props.offerGet(clubId)
		.then(config => {
			var newState = Object.assign({}, this.state);
			var selected = config.name;
			newState.selectedOffer = selected;
			newState.offer[selected] = config[selected];
			newState.appointment = config[selected].appointment || false;
			newState.specific = config.specific;
			newState.mail = config.mail;
			newState.mail.bcc = newState.mail.bcc || '';
			newState.step = 'OK';
			newState.editing = false;
			this.setState(newState);
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error(e);
			this.setState({step:"ERROR", error :e});
		})
		;
	}

	onEdit(event){
		event.preventDefault();
		this.setState({editing:true});
	}

	onCancelEdit(event){
		event.preventDefault();
		this.reload();
	}

	offerTypeChosed(event){
		this.setState({selectedOffer:event.target.value});
	}

	toggleAppointment() {
		this.setState({appointment: !this.state.appointment});
	}

	offerParamsChange(event){
		var offerName = event.target.name.split('_')[0];
		var paramName = event.target.name.split('_')[1];
		var offer = Object.assign({}, this.state.offer);
		var value = event.target.value;
		if(event.target.type === 'text'){
			if(event.target.dataset.type !== 'string'){
				value = parseInt(value.replace(/[^0-9]/g, '')) || '';
			}
		}
		offer[offerName][paramName] = value;
		this.setState({selectedOffer:offerName,offer:offer});
	}

	mailParamsChange(event){
		var mail = Object.assign({}, this.state.mail);
		mail[event.target.name] = event.target.value;
		this.setState({mail:mail});
	}

	onSubmit(event){
		event.preventDefault();
		let clubId = this.context.place.id;
		var body = {
			name: this.state.selectedOffer,
			[this.state.selectedOffer]: this.state.offer[this.state.selectedOffer],
			mail: this.state.mail
		};
		body[this.state.selectedOffer].appointment = this.state.appointment;

		return this.props.offerUpdate(clubId, body)
		.then(res => {
			this.reload();
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error(e);
			this.setState({step:"ERROR", error :e});
		})
		;
	}

	updateForPlace = (placeId) => {
		var body = {
			name : this.state.selectedOffer,
			[this.state.selectedOffer] : this.state.offer[this.state.selectedOffer],
			mail : this.state.mail
		};
		body[this.state.selectedOffer].appointment = this.state.appointment;


		if(!this.state.duplicate_mail){
			delete body.mail.bcc;
			delete body.mail.to;
		}

		if(!this.state.duplicate_mail_content) {
			delete body.mail.message;
		}

		return this.props.offerUpdate(placeId, body)
		.then(res => {
			this.reload();
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error(e);
			this.setState({step:"ERROR", error :e});
		})
		;
	};

	duplicate = (event) => {
		event.preventDefault();
		this.setState({step:'DUPLICATE', duplicate_mail:false, duplicate_mail_content:false});
	};


	onChange = (event) => {
		this.setState({
			[event.target.name] : !this.state[event.target.name]
		});
	};

	render() {
		if(this.state.step === "LOADING"){
			return <Loader title="Votre offre d'essai"/>;
		}
		if (this.state.step === "MUST_UPGRADE") {
			return <MustUpgrade title={this.props.title} plan={this.state.requiredPlan} clubId={this.context.place.id}/>
		}
		if (this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>
		}

		if(this.state.step === 'DUPLICATE'){
			return (
				<Duplicate
					title="Dupliquer l'offre découverte"
					places={this.context.places.filter(p=>p.id !== this.context.place.id)}
					updateOne = {this.updateForPlace}
					cancel = {()=> this.setState({step:'OK'})}
					done = {this.reload}
				>
					<div>
						<p>
							Sélectionnez les clubs pour lesquels vous souhaitez dupliquer l'offre découverte
						</p>
						<p>
							<label>
								<input type='checkbox' name='duplicate_mail_content' checked={this.state.duplicate_mail_content} onChange={this.onChange}/>
								<span>Copier aussi le contenu de l'email</span>
							</label>
						</p>
						<p>
							<label>
								<input type='checkbox' name='duplicate_mail' checked={this.state.duplicate_mail} onChange={this.onChange}/>
								<span>Copier aussi les emails de réception</span>
							</label>
						</p>
					</div>
				</Duplicate>
			);
		}

		var title = "Offre d'essai";
		if(this.state.specific === true){
			title += " (spécifique au Widget)";
		}
		if(this.state.specific === false && !this.state.editing){
			title += " (offre globale MaSalleDeSport)";
		}

		var deleteAction = this.props.offerDelete && this.state.specific && this.onDelete;

		return (
			<div >
				<MagicForm
					title = {title}
					formClass = 'club-offer-form'
					editing = {this.state.editing}
					onCancelEdit = {this.onCancelEdit}
					onSubmit = {this.onSubmit}
					onEdit = {this.onEdit}
					onDelete = {deleteAction}
					>
					<section className="club-offer-details">
						<div className='fields'>
							<div className={(this.state.selectedOffer === 'FREE_1' || this.state.editing) ? 'active' : ''}>
								<input type='radio' onChange={this.offerTypeChosed} checked = {this.state.selectedOffer === 'FREE_1'} value="FREE_1" style={{visibility: (this.state.editing ? 'visible' : 'hidden') }}/>
								<span>1 séance d'essai GRATUITE <em>(Offre recommandée)</em></span>
							</div>

							<div className={(this.state.selectedOffer === 'FREE' || this.state.editing) ? 'active' : ''}>
								<input type='radio' onChange={this.offerTypeChosed} checked = {this.state.selectedOffer === 'FREE'} value="FREE" style={{visibility: (this.state.editing ? 'visible' : 'hidden') }}/>
								<input type='text' name='FREE_count' onChange={this.offerParamsChange} value={this.state.offer.FREE.count} placeholder="Nombre"/>
								<select name='FREE_type' onChange={this.offerParamsChange} value={this.state.offer.FREE.type}>
									<option value='ENTRY'>séance(s)</option>
									<option value='DAY'>jour(s)</option>
									<option value='WEEK'>semaine(s)</option>
									<option value='MONTH'>mois</option>
								</select>
								<span>d'essai GRATUIT</span>
							</div>

							<div className={(this.state.selectedOffer === 'REMB' || this.state.editing) ? 'active' : ''}>
								<input type='radio' onChange={this.offerTypeChosed} checked = {this.state.selectedOffer === 'REMB'} value="REMB" style={{visibility: (this.state.editing ? 'visible' : 'hidden') }}/>
								<span>1 séance d’essai à </span>
								<input type='text' placeholder='Prix' name='REMB_price' value={this.state.offer.REMB.price} onChange={this.offerParamsChange}/>
								<span>€ (remboursé si ABO)</span>
							</div>

							<div className={(this.state.selectedOffer === 'PROMO' || this.state.editing) ? 'active' : ''}>
								<input type='radio' onChange={this.offerTypeChosed} checked = {this.state.selectedOffer === 'PROMO'} value="PROMO" style={{visibility: (this.state.editing ? 'visible' : 'hidden') }}/>
								<span>1 séance d’essai à </span>
								<input type='text' placeholder='Prix'  name='PROMO_price' value={this.state.offer.PROMO.price} onChange={this.offerParamsChange}/>
								<span>€ au lieu de </span>
								<input type='text' placeholder='Prix public'  name='PROMO_publicPrice' value={this.state.offer.PROMO.publicPrice} onChange={this.offerParamsChange}/>
							</div>

							<div className={(this.state.selectedOffer === 'REDUC' || this.state.editing) ? 'active' : ''}>
								<input type='radio' onChange={this.offerTypeChosed} checked={this.state.selectedOffer === 'REDUC'} value="REDUC" style={{visibility: (this.state.editing ? 'visible' : 'hidden') }}/>
								<input type='text' width="3" placeholder='Montant'  name='REDUC_value' value={this.state.offer.REDUC.value} onChange={this.offerParamsChange}/>
								<select name='REDUC_type' value={this.state.offer.REDUC.type} onChange={this.offerParamsChange}>
									<option value="%">%</option>
									<option value="€">€</option>
								</select>
								<span>de réduction sur</span>
								<select name='REDUC_on' value={this.state.offer.REDUC.on} onChange={this.offerParamsChange}>
									<option value="ABO" >votre abonnement</option>
									<option value="FEES">les frais d'inscription</option>
									<option value="PASS">votre carte</option>
								</select>
							</div>

							<div className={(this.state.selectedOffer === 'TEXT' || this.state.editing) ? 'active' : ''}>
								<input type='radio' onChange={this.offerTypeChosed} checked={this.state.selectedOffer === 'TEXT'} value="TEXT" style={{visibility: (this.state.editing ? 'visible' : 'hidden') }}/>
								<select name='TEXT_start' value={this.state.offer.TEXT.start} onChange={this.offerParamsChange}>
									<option value="Recevez">Recevez</option>
									<option value="Bénéficiez">Bénéficiez</option>
									<option value="Organisez">Organisez</option>
								</select>
								<input type='text' data-type="string" placeholder='détails'  name='TEXT_detail' value={this.state.offer.TEXT.detail} onChange={this.offerParamsChange} className="details-input"/>
							</div>
						</div>
						<div className='appointment'>
							<label>
								<input type="checkbox" checked={this.state.appointment} onChange={this.toggleAppointment}/>
								<span>Prise de RDV exigée</span>
							</label>
						</div>
					</section>

					<br/>

					<div className="offre-emails">
						<h3>Email de réception des demandes</h3>
						<div className="inputs-group">
							<label>
								<span>Envoyée à</span>
								<input
									type="text" name="to"
									placeholder="email de réception"
									value = {this.state.mail.to}
									onChange={this.mailParamsChange}
								/>
							</label>
							<label>
								<span>En copie cachée à</span>
								<input
									type="text" name="bcc"
									placeholder="email de copie"
									value = {this.state.mail.bcc}
									onChange={this.mailParamsChange}
								/>
							</label>
						</div>
					</div>


					<div className="offre-mail-template">
						<h3>Email envoyé aux prospects</h3>
						<p>Vous pouvez inclure des modalités pratiques dans l'email qui sera reçu par vos prospects.</p>
						<p>Notez que vos infos de base (adresse et téléphone) sont déjà présentes</p>
						<textarea rows="10" placeholder="Votre message personnalisé" name="message" value = {this.state.mail.message} onChange={this.mailParamsChange}/>
					</div>

				</MagicForm>

				{!this.state.editing && !!this.context.places.length &&
				<div className='duplicate-action'>
					<p>Vous pouvez appliquer les paramètres de cette offre sur tous les clubs que vous gérez en un clic sur le bouton suivant</p>
					<button onClick={this.duplicate} className='button-duplicate'>Dupliquer</button>
				</div>
				}

			</div>

		);
	}
}

export default OffreDetail;
