import React from 'react';
import {NavLink} from 'react-router-dom';

class Tabs extends React.Component {
	render() {
		return (
			<section className={this.props.className || ''}>
				<ul className="tabs">
					{
						this.props.items.map(i=> {
							const content = i.new ?  <>{i.display} <span className="label">New</span></> : i.display;

							return <li key={i.path} className={i.new ? "is-new" : null}><NavLink to={`${this.props.baseUrl}${i.path}`}>{content}</NavLink></li>
						})
					}
				</ul>
				{this.props.children}
			</section>
		);
	}

}
export default Tabs;
