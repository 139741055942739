import React from 'react';
import API from '../utils/api';
import ActorDisplay from '../components/actor';
import Cropper from 'react-cropper';

class Actor extends React.Component {

	constructor(props){
		super(props);
		this.state = this.toState(this.props.actor);
	}

	deleteImage = (e) => {
		e.preventDefault();
		let newState = Object.assign({}, this.state);
		newState.form.image = null;
		this.setState(newState);
	};

	addImage = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({ src: reader.result });
		};
		reader.readAsDataURL(files[0]);
		this.setState({step:"IMAGE_ADDING", fileType:files[0].type});
	};

	cancelImage = (e) => {
		e.preventDefault();
		this.setState({step:"EDIT"});
	};

	toState = (actor) => {
		return {
			initialImage : actor.photo || null,
			step:  (!this.props.isNew && "VIEW") || "EDIT",
			id : actor.id,
			form : {
				fullname : actor.fullname,
				job : actor.job,
				description : actor.description,
				image : actor.photo,
			}
		};
	};

	onDelete = (event) => {
		event.preventDefault();
		let clubId = this.props.placeId;
		//TODO better confirm please
		let result = window.confirm("Etes-vous sûr de vouloir supprimer ce coach ?");
		if(result){
			return API.DELETE(`/user/me/club/${clubId}/actor/${this.state.id}`)
			.then(()=>{ this.props.reload();})
			.catch(e=>console.error(e, e.stack))
			;
		}
	};

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = event.target.value;
		this.setState(newState);
	};

	onUpdate = (event) => {
		event.preventDefault();
		let clubId = this.props.placeId;
		let actorId = this.state.id;

		var body = {
			fullname : this.state.form.fullname,
			job : this.state.form.job,
			description : this.state.form.description,
			image : (this.state.initialImage !== this.state.form.image) ? this.state.form.image : undefined
		};
		API.PUT(`/user/me/club/${clubId}/actor/${actorId}`, body)
		.then(()=>{
			this.setState({step :"VIEW"});
		})
		.catch(e=>console.error(e, e.stack))
		;
	};


	onCreate = (event) => {
		event.preventDefault();
		let clubId = this.props.placeId;

		var body = {
			fullname : this.state.form.fullname,
			job : this.state.form.job,
			description : this.state.form.description,
			image : (this.state.initialImage !== this.state.form.image) ? this.state.form.image : undefined
		};
		API.POST(`/user/me/club/${clubId}/actor`, body)
		.then(()=>{
			this.props.reload();
		})
		.catch(e=>console.error(e, e.stack))
		;
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let { actor } = this.props;
		this.setState(this.toState(actor));
	};

	onEdit = (event) => {
		event.preventDefault();
		this.setState({step: "EDIT"});
	};

	cropImage = () => {
		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
			return;
		}

		this.setState({step :"IMAGE_UPLOADING"});

		let img = this.cropper.getCroppedCanvas().toDataURL(this.state.fileType);
		let newState = Object.assign({}, this.state);
		newState.step = "EDIT";
		newState.form.image = img;
		this.setState(newState);
	};

	render() {
		if(this.state.step === "IMAGE_ADDING"){
			let title = (this.state.form.fullname ? this.state.form.fullname : "Nouveau coach")
				+ (this.state.form.job ? (", "+this.state.form.job) : "");
			let maxLength = 30;
			title.length > maxLength && (title = title.substring(0, maxLength) + "[...]");

			return (
				<div className="actorImageForm">
					<h1 className="pageTitle">{title}</h1>
					<section>
						<div className="galerie_add_cropper">
							<Cropper
								style={{"maxHeight": 300, "maxWidth": 300}}
								aspectRatio={1}
								guides={false}
								src={this.state.src}
								ref={cropper => { this.cropper = cropper; }} />
						</div>
						<div className="galerie_add_actions">
							<button className="button-valid" onClick={this.cropImage}>Valider</button>
							<button className="button-cancel" onClick={this.cancelImage}>Annuler</button>
						</div>
					</section>
				</div>
			);
		}
		return (
			<ActorDisplay
				editing={this.state.step ==="EDIT"}
				onChange={this.onChange}
				onSubmit={this.props.isNew? this.onCreate : this.onUpdate}
				onCancelEdit ={ this.props.isNew? this.props.onCancelCreate: this.onCancelEdit}
				onEdit ={ this.onEdit}
				onDelete = {this.onDelete}
				addImage = {this.addImage}
				deleteImage = {this.deleteImage}
				fullname ={ this.state.form.fullname}
				job ={this.state.form.job}
				description={this.state.form.description}
				image = {this.state.form.image}
			/>
		);
	}
}


export default Actor;
