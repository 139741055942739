import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import ClubImage from '../components/clubImages';
import Cropper from 'react-cropper';
import Loader from '../components/loader';

import whiteEditIcon from '../static/icons/picto-edit--white.svg';

class SearchPicture extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);
		this.state = {action:"LOADING"};
		this.reload = this.reload.bind(this);
		this.addImage = this.addImage.bind(this);
		this.cancel = this.cancel.bind(this);
		this.cropImage = this.cropImage.bind(this);
	}

	componentDidMount() {
		this.reload()
	}

	reload(){
		this.setState({action:"LOADING"});
		API.GET(`/user/me/club/${this.context.place.id}/searchPicture`)
		.then(res=>{
			this.setState({action:"VIEW", image: res})
		})
		.catch(e=>{
			console.error(e, e.stack);
		})
		;
	};

	addImage(e) {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({ src: reader.result });
		};
		reader.readAsDataURL(files[0]);
		this.setState({action:"ADDING", fileType:files[0].type});
	}

	cancel() {
		this.setState({action :"VIEW"});
	}


	cropImage() {
		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
			return;
		}

		this.setState({action :"UPLOADING"});

		let img = this.cropper.getCroppedCanvas().toDataURL(this.state.fileType);

		this.setState({
			cropResult: img,
		});
		var body = {
			image : img
		};
		API.PUT(`/user/me/club/${this.context.place.id}/searchPicture`, body)
		.then( res =>{
			this.setState({action :"VIEW"});
			this.reload();
		})
		.catch(e=>{
			this.setState({action :"VIEW"});
			console.error(e, e.stack);
		})
		;
	}

	render() {
		if(this.state.action === "LOADING" ){
			return (
				<section className="club-gallery-page">
					<Loader title="Image de recherche" />
				</section>
			);
		}

		if(this.state.action === "VIEW"){
			return (
				<section className="club-gallery-page">
					<header>
						<h1 className="pageTitle">Image de recherche</h1>
						<div className="club-gallery-actions">
							{
								<label className="button-primary">
									<img src={whiteEditIcon} alt="Modifier"/>
									<input className="fileUpload_input" type="file" accept="image/*" onChange={this.addImage}/>
								</label>
							}
						</div>
					</header>
					<div className="club-gallery-list">
						{this.state.image && this.state.image.url && <ClubImage url={this.state.image.url}/>}
						{(!this.state.image || !this.state.image.url) && <div>L'image qui sera affichée sur les pages de résultats de recherche</div>}
					</div>
				</section>
			);
		}

		if(this.state.action === "ADDING"){
			return (
				<section className="club-gallery-page">
					<header>
						<h1 className="pageTitle">Image de recherche > Modifier</h1>
					</header>
					<section>
						<div className="galerie_add_cropper">
							<Cropper
								style={{"maxHeight": 300, "maxWidth": 400}}
								aspectRatio={4 / 3}
								guides={false}
								src={this.state.src}
								ref={cropper => { this.cropper = cropper; }} />
						</div>
						<div>
							<button className="button-save" onClick={this.cropImage}>Valider</button>
							<button className="button-cancel" onClick={this.cancel}>Annuler</button>
						</div>
					</section>
				</section>
			);
		}
		if(this.state.action === "UPLOADING"){
			return (
				<section className="club-gallery-page">
					<header>
						<h1 className="pageTitle">Image de recherche > Upload</h1>
					</header>
				</section>
			);
		}
	}
}

export default SearchPicture;
