import React from 'react';

import fullStar from '../static/icons/picto-star.svg';
import emptyStar from '../static/icons/picto-star-empty.svg';
import halfStar from '../static/icons/picto-star-half.svg';

const starMap = {
	star: fullStar,
	"star-half": halfStar,
	"star-empty": emptyStar
}

export default props => {
		let rating = Math.round(props.rating * 2 ) / 2;

		let res = [];
		for (let i = 0; i < Math.floor(rating); i++) {
			res.push("star");
		}
		if ((rating + "").endsWith(".5")) {
			res.push("star-half");
		}
		while (res.length !== 5) {
			res.push("star-empty");
		}

		return (
			<ul className="review-stars-rating">
				{
					res.map(function(star,index){
						return (
							<li key={`start_${index}`}>
								<img src={starMap[star]} alt={star}/>
							</li>
						);
					})
				}
			</ul>
		);
};