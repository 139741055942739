import React from 'react';
import Galerie from '../containers/Galerie';
import SearchPicture from '../containers/SearchPicture';

class GalerieAndSearchPicture extends React.Component {
	render() {
		return (
			<div>
				<SearchPicture/>
				<Galerie/>
			</div>
		);
	}
}

export default GalerieAndSearchPicture;