import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../components/Tabs';

class Shop extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render() {
		const placeId = this.context.place.id;

		var menu = [{
			path: 'abos',
			display: 'Abonnement en ligne'
		}, {
			path: 'products',
			display: 'Vente en ligne'
		}];

		return (
			<Tabs baseUrl={`/club/${placeId}/shop/`} className="club-shop" items={menu}>
				{this.props.children}
			</Tabs>
		);
	}

}
export default Shop;
