import React from 'react';
import Adress from '../containers/Adress';
import Access from '../containers/Access';
import Coord from '../containers/Coord';

class AdressAndAccess extends React.Component {

	render() {
		return (
			<section className="club-access-page">
				<Adress />
				<Coord/>
				<Access />
			</section>
		);
	}
}

export default AdressAndAccess;
