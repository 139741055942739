import React from 'react';
import Review from '../components/Review';
import Pagination from '../components/pagination';

class ReviewsList extends React.Component {

	constructor(props) {
		super(props);
		this.changePage = this.changePage.bind(this);

		this.state = {
			page: 1,
			reviews: this.props.reviews
		};
	}

	componentWillReceiveProps(newProps, oldProps) {
		if (newProps.selectedRate && newProps.selectedRate !== oldProps.selectedRate) {
			this.setState({page: 1});
		}
	}

	changePage(event){
		event.preventDefault();
		this.setState({page: parseInt(event.target.dataset.page)});
	}

	render() {
		const nbPerPage = 10;
		const reviews = this.state.reviews;
		const match = reviews.filter(r => !this.props.selectedRate || (r.marks && r.marks.average == this.props.selectedRate));
		let page = this.state.page;
		let pageMax = Math.ceil(match.length / nbPerPage);
		let displayed = match.slice((page - 1) * nbPerPage, page * nbPerPage);
		return (
			<div className={"reviews-list"}>
				<Pagination currentPage={this.state.page} maxPage = {pageMax} onClick={this.changePage}/>
				<ul className="club-reviews-list">
					{displayed.map((review) => {
						return (
							<Review key={`review${review.id}`} review={review}/>
						);
					})}
				</ul>
			</div>
		);
	}
}

export default ReviewsList;
