import React, {useEffect, useState} from 'react';
import Cropper from 'react-cropper';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Loader from '../components/loader';
import whiteAddIcon from '../static/icons/picto-add--white.svg';
import pictoDelete from '../static/icons/picto-delete--red.svg';
import pictoDownload from '../static/icons/picto-download.svg';
import Tabs from '../components/Tabs';
import MyCropper from './Cropper';

export default function OnePlace({vel, place, backToList}) {
	const [state, stateSet] = useState('view');
	const [url, urlSet] = useState('');

	const onUpload = ({path, fullPath, image, type}) => {
		stateSet('uploading');
		API.PATCH(`/api/pro/r2vel/${vel.id}/place/${place.msdsId}`, {offer_url: fullPath })
		.then( () => {
			backToList({reload:true});
		})
		.catch( console.error )
	};

	const submit = (e) => {
		e.preventDefault();
		stateSet('uploading');
		API.PATCH(`/api/pro/r2vel/${vel.id}/place/${place.msdsId}`, {offer_url: url.trim() })
		.then( () => {
			backToList({reload:true});
		})
		.catch( console.error )
	};

	const deleteImg = e => {
		stateSet('uploading');
		API.PATCH(`/api/pro/r2vel/${vel.id}/place/${place.msdsId}`, {offer_url: null })
		.then( () => {
			backToList({reload:true});
		})
		.catch( console.error )
	};

	return (
		<>
			<div style={{display:'flex', flexDirection:'rows', alignItems:"center"}}>
				<button onClick={backToList}>Back</button>
				<h2>{place.name}</h2>
			</div>
			{state === 'view' && (
				<>
				<div>
					{place.offer_url && <p style={{marginTop:'0.5rem'}}>{place.offer_url}</p>}
					{place.offer_url && <img width='240' height='auto' style={{padding:'.5rem'}} src={place.offer_url}/>}
				</div>

				<div style={{display:'flex', flexDirection:"rows", alignItems:"center", justifyContent:'center', gap:'1rem'}}>
					{!place.offer_url && (
						<>
						<form onSubmit={submit}>
							<input name='url' type='text' placeholder="URL de l'image" value={url} onChange={e => urlSet(e.target.value)}/>
							<input type='submit' style={{padding:'.5rem', color:'white', background:'red'}} value="Utliser l'url"/>
						</form>
						<strong>OR</strong>
						<MyCropper placeId={place.msdsId} width={350} height={560} onUpload={onUpload} path={`r2vel/${vel.id}`} />
						</>
					)}
					{place.offer_url && <button onClick={deleteImg} style={{padding:'.5rem', color:'white', background:'red'}}>Supprimer</button>}
				</div>
				</>
			)}
			{state === 'uploading' && <p>Uploading</p>}
		</>
	)
}
