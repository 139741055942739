import React from 'react';
import PropTypes from 'prop-types';
import MagicForm from '../components/magicForm';
import Cropper from 'react-cropper';
import DatePicker from "react-datepicker";
import moment from 'moment';

import defaultEventImage from '../static/default-event.png';
import deleteIcon from '../static/icons/picto-delete.svg';

class Event extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);
		this.state = this.toState(props.event);
	}

	toState = (event) => {
		return {
			id : event.id
			,form :{
				name : { value : event.name  || "" }
				,type : { value : event.type  || "" }
				,description : { value : event.description || "" }
				,from : { value : event.from && moment(event.from).toDate() }
				,publish : { value : event.publish && moment(event.publish).toDate() }
				,to : { value : event.to && moment(event.to).toDate() }
				,imageUrl : event.imageUrl
			}
			,editing : this.props.isNew || false
		};
	};

	onUpdate = (clickEvent) => {
		clickEvent.preventDefault();

		let newState = Object.assign({}, this.state);
		let error = false;

		Object.keys(newState.form).forEach((k)=>{
			if(k==="imageUrl") return;
			if(!newState.form[k].value){
				newState.form[k].invalid = true;
				error = true;
			}
		});
		if(error){
			this.setState(newState);
			return;
		}

		var body = {
			name : newState.form.name.value,
			type : newState.form.type.value,
			description : newState.form.description.value,
			from : moment(newState.form.from.value).format("YYYY-MM-DD"),
			to : moment(newState.form.to.value).format("YYYY-MM-DD"),
			publish : moment(newState.form.publish.value).format("YYYY-MM-DD"),
			imageUrl : newState.form.imageUrl
		};
		if(newState.form.imageUrl === this.props.event.imageUrl){
			delete body.imageUrl;
		}
		this.props.onUpdate(this.state.id, body);
	};


	onDelete = (clickEvent) => {
		clickEvent.preventDefault();
		let result = window.confirm("Etes-vous sûr de vouloir supprimer cet évènement ?");
		if(result){
			this.props.onDelete(this.props.event.id);
		}
	};

	onChange = (changeEvent) => {
		let newState = Object.assign({}, this.state);
		newState.form[changeEvent.target.name] = { value : changeEvent.target.value} ;
		this.setState(newState);
	};

	onCreate = (clickEvent) => {
		clickEvent.preventDefault();

		let newState = Object.assign({}, this.state);
		let error = false;

		Object.keys(newState.form).forEach((k)=>{
			if (k === "imageUrl") return;
			if(!newState.form[k].value){
				newState.form[k].invalid = true;
				error = true;
			}
		});
		if(error){
			this.setState(newState);
			return;
		}

		var body = {
			name : newState.form.name.value,
			type : newState.form.type.value,
			description : newState.form.description.value,
			from : moment(newState.form.from.value).format("YYYY-MM-DD"),
			to : moment(newState.form.to.value).format("YYYY-MM-DD"),
			publish : moment(newState.form.publish.value).format("YYYY-MM-DD")
		};

		this.props.onCreate(body)
	};

	onCancelEdit = (clickEvent) => {
		clickEvent.preventDefault();
		clickEvent.stopPropagation();

		let { event } = this.props;
		this.setState(this.toState(event));
	};

	onEdit = (event) => {
		event.preventDefault();
		this.setState({editing : true});
	};

	addImage = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({ src: reader.result });
		};
		reader.readAsDataURL(files[0]);
		this.setState({step:"IMAGE_ADDING", fileType:files[0].type});
	};


	cropImage = () => {
		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
			return;
		}

		this.setState({step :"IMAGE_UPLOADING"});

		let img = this.cropper.getCroppedCanvas().toDataURL(this.state.fileType);
		let newState = Object.assign({}, this.state);
		newState.step = "EDIT";
		newState.form.imageUrl = img;
		this.setState(newState);
	};

	cancelImage = (e) => {
		e.preventDefault();
		this.setState({step:"EDIT"});
	};

	deleteImage = (e) => {
		e.preventDefault();
		let newState = Object.assign({}, this.state);
		newState.form.imageUrl = null;
		this.setState(newState);
	};


	render() {
		if(this.state.step === "IMAGE_ADDING"){
			let title = `Ajout de l'image pour ${this.state.form.name.value}`;
			return (
				<div className="actorImageForm" style={{display:'flex', flexDirection:'column'}}>
					<h1 className="pageTitle">{title}</h1>
					<section style={{display:'flex', flexDirection:'column'}}>
						<div className="galerie_add_cropper">
							<Cropper
								style={{"maxHeight": 300, "maxWidth": 300}}
								aspectRatio={1}
								guides={false}
								src={this.state.src}
								ref={cropper => { this.cropper = cropper; }} />
						</div>
						<div className="galerie_add_actions">
							<button className="button-valid" onClick={this.cropImage}>Valider</button>
							<button className="button-cancel" onClick={this.cancelImage}>Annuler</button>
						</div>
					</section>
				</div>
			);
		}

		//TODO from applicationDatas please
		let typeValues  = [
			{ code: "news", name : "News"}
			, { code: "competition", name : "Compétition"}
			, { code: "festivite", name : "Festivité"}
			, { code: "pass_decouverte", name : "Pass découverte"}
			, { code: "promo", name : "Promotion"}
			, { code: "evenement", name : "Evénement"}
			, { code: "manifestation", name : "Manifestation"}
			, { code: "stage", name : "Stage"}
			, { code: "new_activite", name : "Portes ouvertes"}
			, { code: "NEW_ACTIVITY", name : "Nouvelle activité"}
			, { code: "divers", name : "Divers"}
		 ];

		return ( <ClubEventComponent
					editing={this.state.editing}
					onChange={this.onChange}
					onSubmit={this.props.isNew? this.onCreate : this.onUpdate}
					onCancelEdit ={ this.props.isNew? this.props.onCancelCreate: this.onCancelEdit}
					onEdit ={ this.onEdit}
					onDelete = {this.onDelete}
					id ={ this.state.id}
					name ={ this.state.form.name}
					type ={this.state.form.type}
					typeValues={typeValues}
					description={this.state.form.description}
					from = {this.state.form.from}
					to = {this.state.form.to}
					imageUrl = {this.state.form.imageUrl}
					publish = {this.state.form.publish}
					addImage = {this.addImage}
					deleteImage = {this.deleteImage}
				/>
		);
	}
}


export default Event;



const ClubEventComponent = props => {

	function dateChange(name, date) {
		props.onChange({
			target: {
				name: name,
				value: date
			}
		});
	}

	let title = props.name.value;
	if (title.length > 30) {
		title = title.substring(0, 30 - 3) + "[...]";
	}
	return <MagicForm
		formClass='club-event-form'
		title={title}
		editing={props.editing}
		onCancelEdit={props.onCancelEdit}
		onSubmit={props.onSubmit}
		onEdit={props.onEdit}
		onDelete={props.onDelete}>
		<div className="event-form-content">
			<section>
				<div className="club-event-pic" style={{display:'flex',flexDirection:'column'}}>
					{props.imageUrl &&
					<img alt='event' src={props.imageUrl} width="450" height="450"/>
					}
					{!props.imageUrl &&
					<div className="default-event-pic">
						<img alt='default' src={defaultEventImage} width="450" height="450"/>
						<strong>{props.name.value}</strong>
					</div>
					}
					{props.editing && !props.imageUrl &&
					<label>
						<span>Ajouter une photo</span>
						<input className="fileUpload_input" type="file" accept="image/*" onChange={props.addImage}/>
					</label>
					}
					{props.editing && props.imageUrl &&
					<button style={{marginTop:'2rem'}} className="button-delete" title="Supprimer" onClick={props.deleteImage}>Supprimer l'image<img src={deleteIcon} alt="Supprimer" /></button>
					}
				</div>
			</section>
			<section style={{marginTop:'2rem'}}>
				<label style={ !props.editing ? {"display": "none"} : {} }>
					<span>Nom de l'évènement</span>
					<input type="text"
					       name="name"
					       value={props.name.value}
					       placeholder="Nom"
					       onChange={props.onChange}
					       className={ (props.name.invalid ? " inputInvalid" : "")}
					/>
				</label>
				<div className="inputs-group">
					<label>
						<span>Début</span>
						<DatePicker
							name="from"
							selected={props.from.value ? props.from.value : new Date()}
							onChange={dateChange.bind(null, "from")}
							todayButton={"Aujourd'hui"}
							locale="fr"
							disabled={!props.editing}
							dateFormat="dd/MM/YYYY"
							isClearable={false}
							placeholderText="JJ/MM/AAAA"
							className={ (props.from.invalid ? " inputInvalid" : "")}
						/>
					</label>
					<label>
						<span>Fin</span>
						<DatePicker
							name="to"
							selected={props.to.value ? props.to.value : new Date()}
							onChange={dateChange.bind(null, "to")}
							todayButton={"Aujourd'hui"}
							locale="fr"
							disabled={!props.editing}
							dateFormat="dd/MM/YYYY"
							isClearable={false}
							placeholderText="JJ/MM/AAAA"
							className={ (props.to.invalid ? " inputInvalid" : "")}
						/>
					</label>
					<label>
						<span>Publication à partir du</span>
						<DatePicker
							name="publish"
							selected={props.publish.value ? props.publish.value : new Date()}
							onChange={dateChange.bind(null, "publish")}
							todayButton={"Aujourd'hui"}
							locale="fr"
							disabled={!props.editing}
							dateFormat="dd/MM/YYYY"
							isClearable={false}
							placeholderText="JJ/MM/AAAA"
							className={ (props.publish.invalid ? " inputInvalid" : "")}
						/>
					</label>
				</div>
				<label>
					<span>Type d'évènement</span>
					<select
						name="type" value={props.type.value}
						onChange={props.onChange}
						className={ (props.type.invalid ? " inputInvalid" : "")}
					>
						<option key="disabled" value="" disabled>--</option>
						{props.typeValues.map((v) => {
							return <option key={v.code} value={v.code}>{v.name}</option>;
						})}
					</select>
				</label>
				<label>
					<span>Description</span>
					<textarea
						name="description" value={props.description.value}
						placeholder="Description de l'évènement"
						onChange={props.onChange}
						className={ (props.description.invalid ? " inputInvalid" : "")}
					/>
				</label>
			</section>
		</div>
	</MagicForm>
		;
}
