import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../components/Tabs';

class SalesAndRefund extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render() {
		const placeId = this.context.place.id;

		var menu = [{
			path: 'abos',
			display: 'Vos abonnements'
		},{
			path: 'list',
			display: 'Vos ventes'
		},{
			path: 'refund',
			display: 'Vos remboursements'
		}];

		return (
			<Tabs baseUrl={`/club/${placeId}/sales/`} className="club-sales-refunds" items={menu}>
				{this.props.children}
			</Tabs>
		);
	}

}
export default SalesAndRefund;
