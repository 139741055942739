import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import Overlay from './overlay';

const Dropdown = ({ isOpen, button, children, onClose }) => {
  return (
    <>
      {isOpen && <Overlay alphaBackground onClose={onClose} />}
      <div className="dropdown">
        {button}
        <div
            className={cn('dropdown-list', {
                'open': isOpen,
            })}
        >
            {children}
        </div>
      </div>
    </>
  );
};

Dropdown.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  button: PropTypes.node,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  onClose: PropTypes.func.isRequired,
};

export default Dropdown;
