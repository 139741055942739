import React from 'react';
import PropTypes from 'prop-types';
import ReviewsContainer from '../containers/ReviewsContainer';

export default class MultiReview extends React.Component {
	static contextTypes = {
		places: PropTypes.array.isRequired
	};


	render() {
		if(!this.context.places.length){
			return <p>Il semble que vous n'ayez accès à aucun club</p>;
		}

		return <ReviewsContainer places={this.context.places} />
	}

}
