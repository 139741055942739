import React, {useEffect, useState} from 'react';
import Cropper from 'react-cropper';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Loader from '../components/loader';
import whiteAddIcon from '../static/icons/picto-add--white.svg';
import pictoDelete from '../static/icons/picto-delete--red.svg';
import pictoDownload from '../static/icons/picto-download.svg';
import Tabs from '../components/Tabs';
import MyCropper from './Cropper';
import Places from './Places';

export default function Resamania2Vel(){
	const [data, dataSet] = useState(false);
	const [actif, actifSet] = useState(false);

	const reload = () => {
		dataSet(false);
		API.GET('/r2vel')
		.then( d => { dataSet(d); !actif && actifSet(d[0])} )
		.catch( e => dataSet('error') );
	};

	useEffect( reload, [])

	if( data === false ) {
		return <Loader/>
	}

	if( data === 'error' ) {
		return <p>Une erreur est survenue</p>
	}

	return (
		<>
			<h1>Vente en ligne</h1>
			<div style={{display:'flex', flexDirection:'row', gap:'1rem', marginTop:'1rem'}}>
				{data.map( d=> {
					let style = {background:'white', border:'1px solid gray', padding:'1rem', cursor:'pointer'};
					if( d.id === actif?.id ) {
						style.background = 'gray';
						style.color = 'white';
					}
					return <div onClick={ e => actifSet(d)} style={style} key={d.id}>{d.name}</div>
				})}
			</div>
			{actif && <Vel key={actif.id} vel={actif} reload={reload}/> }
		</>
	);
}

function Vel({vel, reload}) {

	const onUse = (offerId) => {
		let msg = "Voulez-vous supprimer l'offre en cours?";
		if( offerId ) {
			msg = "Voulez-vous utiliser cette offre?";
		}
		if( !window.confirm(msg))
			return;
		API.PATCH(`/r2vel/${vel.id}`, {offer: offerId})
		.then( reload )
	}

	return (
		<>
		<div style={{border:'1px solid lightgray', margin:'1rem', padding:'1rem', background:'white'}}>
			<br/>
			{vel.offer_url && <>
				<h3>Offre en cours</h3>
				<img width='670' height='auto' src={vel.offer_url} />
				<button className='button-delete' onClick={() => onUse(null)}>
					<img src={pictoDelete} alt="Supprimer" title="Supprimer cette photo"/>
				</button>
			</>}
			{!vel.offer_url && <h3>Vous n'avez aucune offre en cours</h3>

			}
		</div>
		<div style={{border:'1px solid lightgray', margin:'1rem', padding:'1rem', background:'white'}}>
			<Galerie id={vel.id} onUse={onUse}/>
		</div>
		<div style={{border:'1px solid lightgray', margin:'1rem', padding:'1rem', background:'white'}}>
			<Places vel={vel}/>
		</div>
		</>
	)
}

class Galerie extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);

		this.state = { action :"LOADING", "images" : null};
		this.maxImages = 10;
	}

	componentDidMount() {
		this.reload()
	}

	reload = () => {
		this.setState({action:"LOADING"});
		let clubId = this.context.place.id;
		//TODO get from vel_img galerie
		API.GET(`/r2vel/${this.props.id}/offer`)
		.then(res=>{
			this.setState({action:"VIEW", images: res})
		})
		.catch(e=>{
			console.error(e, e.stack);
		})
		;
	};

	deleteImage = (id, event) => {
		event.preventDefault();
		let clubId = this.context.place.id;
		//TODO better confirm please
		let result = window.confirm("Etes-vous sûr de vouloir supprimer cette photo ?");
		if(!result){
			return;
		}


		//TODO delete from vel_img galerie
		API.DELETE(`/r2vel/${this.props.id}/offer/${id}`)
		.then(res=>{
			this.reload();
		})
		.catch(e=>console.error(e, e.stack))
		;
	};

	cancel = () => {
		this.setState({action :"VIEW"});
	};

	addImage = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({ src: reader.result });
		};
		reader.readAsDataURL(files[0]);
		this.setState({action:"ADDING", fileType:files[0].type});
	};

	cropImage = () => {
		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
			return;
		}

		this.setState({action :"UPLOADING"});

		let img = this.cropper.getCroppedCanvas().toDataURL(this.state.fileType);

		this.setState({
			cropResult: img,
		});
		let clubId = this.context.place.id;

		var body = {
			image : img,
			type: this.state.fileType.split('/').slice(-1)
		};

		API.POST(`/r2vel/${this.props.id}/offer`, body)
		.then(res =>{
			this.setState({action :"VIEW"});
			this.reload();
		})
		.catch((e)=>{
			this.setState({action :"VIEW"});
			console.error(e, e.stack);
		})
		;
	};


	dragStart = (e) => {
		this.dragged = e.currentTarget;
		e.dataTransfer.effectAllowed = 'move';
		// Firefox requires calling dataTransfer.setData
		// for the drag to properly work
		e.dataTransfer.setData("text/html", e.currentTarget);
	};

	dragEnd = (e) => {
		if(!this.over){
			return;
		}

		let ordering = this.state.ordering;
		let from = Number(this.dragged.dataset.index);
		let to = Number(this.over.dataset.index);
		if(from < to) to--;
		ordering.splice(to, 0, ordering.splice(from, 1)[0]);
		this.setState({ordering: ordering});
	};

	dragOver = (e) => {
		e.preventDefault();
		if(!e.target.dataset.index){
			return;
		}
		this.over = e.target;
	};

	render() {
		if(this.state.action === "LOADING" ){
			return <Loader title="Mes offres" />
		}

		// let uid = new Date().getTime()+"_"+Math.random();

		return (
			<div className="club-gallery">
				{this.state.action === "UPLOADING" &&
					<div>
						<h4>Mes offres</h4>
						<p>Votre image est en cours de téléchargement</p>
					</div>
				}

				{this.state.action === "VIEW" &&
					<section className="club-gallery-page">
						<header>
							<div>
								<h4>Mes offres ({this.state.images.length}/{this.maxImages})</h4>
								<p>Utilisez des images de 670px sur 120px</p>
							</div>
							<div className="club-gallery-actions">
								{
									this.state.images.length < this.maxImages &&
									<label className="button-add">
										<img src={whiteAddIcon} alt="Ajouter"/>
										<input className="fileUpload_input" type="file" accept="image/*" onChange={this.addImage}/>
									</label>
								}
							</div>
						</header>
						<div className="club-gallery-list">
							{this.state.images.map((photo) => {
								return <Image key={photo.id} url={photo.url} onUse={() => this.props.onUse(photo.id)} onDelete={this.deleteImage.bind(this,photo.id)}	/>;
							})}
							{this.state.images.length === 0
								&& <p className="msdsAdvice">Ajouter des images dans votre galerie pour les utiliser comme offre spéciale sur la vente en ligne</p>
							}
						</div>
					</section>
				}

				{this.state.action === "ADDING" &&
					<div className="galerie_add">
						<h4 className="pageTitle">Mes offres > Ajout</h4>
						<section>
							<div className="galerie_add_cropper">
								<Cropper
									style={{"maxHeight": 600, "maxWidth": 800}}
									aspectRatio={ 670 / 120}
									guides={false}
									src={this.state.src}
									ref={cropper => { this.cropper = cropper; }} />
							</div>
							<div className="galerie_add_actions">
								<button className="button-save" onClick={this.cropImage}>Valider</button>
								<button className="button-cancel" onClick={this.cancel}>Annuler</button>
							</div>
						</section>
					</div>
				}
			</div>
		);
	}
}

function Image({url, originalUrl, onDelete, onUse}){
	return (
		<div className="club-gallery-item">
			<img src={url} alt="club" style={{width:'670px', height:'auto'}}/>
			<div className="club-image-action">
				{onDelete && (
					<button className='button-delete' onClick={onDelete}>
						<img src={pictoDelete} alt="Supprimer" title="Supprimer cette photo"/>
					</button>
				)}
				{onUse && (
					<button className='button-download' onClick={onUse}>
						<img src={pictoDownload} alt="Utiliser" title="Utiliser"/>
					</button>
				)}
			</div>
		</div>
	);
}
