import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Product from '../containers/Product';
import Loader from '../components/loader';
import MustUpgrade from '../components/mustUpgrade';

import whiteAddIcon from '../static/icons/picto-add--white.svg';

class Products extends React.Component {

	static contextTypes = {
		user: PropTypes.object.isRequired,
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.reload = this.reload.bind(this);
		this.add = this.add.bind(this);
		this.onCancelCreate = this.onCancelCreate.bind(this);

		this.state ={
			action : "LOADING",
			products : null
		};

		this.maxProducts = 3;
		this.typesWithSubValue = ['CLASS', 'COACHING'];
	}

	componentDidMount() {
		this.reload();
	}

	reload(){
		this.setState({action : "LOADING"});
		API.GET(`/user/me/club/${this.context.place.id}/product`)
		.then(res=>{
			this.setState({action:"VIEW", products:res});
		})
		.catch(e => {
			if(e.restCode === "mustUpgradePlan"){
				return this.setState({action:"MUST_UPGRADE", requiredPlan : e.requiredPlan});
			}
			console.error(e, e.stack);
			this.setState({step:"ERROR", values: null, pagination: null});
		})
		;
	}

	add(){
		this.setState({action:"ADD"});
	}

	onCancelCreate(event){
		event.preventDefault();
		this.setState({action:"VIEW"});
	}

	render() {
		if(this.state.action === "LOADING"){
			return <Loader title="Vente en ligne"/>;
		}
		if(this.state.action === "MUST_UPGRADE"){
			return <MustUpgrade title="Vente en ligne" plan={this.state.requiredPlan} clubId={this.context.place.id}/>
		}

		return (
			<section className="club-products-page">
				<ProductPage
					action = {this.state.action}
					products = {this.state.products}
					maxProducts = {this.maxProducts}
					add = {this.add}
					onCancelCreate = {this.onCancelCreate}
					reload = {this.reload}
					typesWithSubValue = {this.typesWithSubValue}
				/>
				<SaleHowTo/>
			</section>
		);
	}

}

const ProductPage = props => {

	let visibilityAdd = (props.action === "VIEW" && props.products.length < props.maxProducts)
		? "visible"
		: "hidden";

	return (
		<section className="club-products-content">
			<header className="titleAndAdd">
				<h1>Vos produits ({props.products.length} sur {props.maxProducts})</h1>
				<button className="button-add" style={{visibility : visibilityAdd}} onClick={props.add}><img
					src={whiteAddIcon} alt="Ajouter"/></button>
			</header>
			<div className="club-products-list">
				{ props.action === "ADD" &&
					<Product key='newOne' product={null}
						isNew={true}
						onCancelCreate={props.onCancelCreate}
						reload={props.reload}
						typesWithSubValue={props.typesWithSubValue}
					/>
				}
				{
					props.products.map((p)=>{
						return <Product key={p.id}
									product={p}
									reload={props.reload}
									typesWithSubValue={props.typesWithSubValue}
								/>
					})
				}
			</div>
		</section>
	);
};

const SaleHowTo = props => {
	return (
		<section className="sale-how-to">
			<header>
				<h1>Vente en ligne : fonctionnement</h1>
			</header>
			<section>
				<ul>
					<li>Pour activer la vente en ligne il faut paramétrer 1 à 3 offres en cliquant sur le bouton "+".</li>
					<li>Les notifications de vente se font en temps réel par email et dans la section "Vos ventes".</li>
					<li>MaSalledeSport encaisse le produit des ventes réalisées sur le site et reverse ensuite aux clubs le montant payé par l’internaute moins une commission de 20%.</li>
					<li>Les virements aux clubs sont automatiques et effectués chaque début de mois pour les ventes du mois d'avant.</li>
				</ul>
			</section>
		</section>
	);
};


export default Products;
