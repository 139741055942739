import React from 'react';
import PropTypes from 'prop-types';

import logo from '../static/logos/logo.svg';

import { AppVersionContext } from '../react_contexts';

const BASEPATH = process.env.REACT_APP_BASEPATH;

export default class VersionChecker extends React.Component {
	static childContextTypes = {
		appVersion: PropTypes.string
	};

	state = {
		mustUpgrade: false
	};

	intervalHandle = null;

	getChildContext = () => {
		return { appVersion: this.props.initial };
	};

	componentDidMount() {
		if (!this.props.enabled) {
			return;
		}
		console.debug('[VersionChecker]', 'Initial version', this.props.initial);
		console.debug(
			'[VersionChecker]',
			'Starts interval',
			this.props.delay,
			'ms'
		);
		this.intervalHandle = setInterval(this.test, this.props.delay);
	}

	componentWillUnmount() {
		if (!this.props.enabled) {
			return;
		}
		console.debug('[VersionChecker]', 'Cleans interval');
		clearInterval(this.intervalHandle);
	}

	test = () => {
		fetch(`${BASEPATH}version.json`)
			.then(res => {
				if (res.ok) return res.json();
				console.error(
					'[VersionChecker]',
					'Cannot get version, status is:',
					res.status
				);
				return false;
			})
			.then(json => {
				if (!json) return;
				let mustUpgrade = this.props.initial != json.version;
				console.debug(
					'[VersionChecker]',
					'Initial version:',
					this.props.initial,
					'Actual version:',
					json.version
				);
				if (this.state.mustUpgrade !== mustUpgrade)
					this.setState({ mustUpgrade });
			})
			.catch(e => {
				console.error('[VersionChecker]', 'Cannot get version', e);
			});
	};

	render() {
		if (this.state.mustUpgrade) {
			return (
				<article className="VersionChecker_mustUpdate">
					<img src={logo} alt="msds" />
					<h1>
						Une nouvelle version de l'application est en ligne, veuillez
						recharger la page
					</h1>
				</article>
			);
		}

		return (
			<AppVersionContext.Provider value={this.props.initial}>
				{this.props.children}
			</AppVersionContext.Provider>
		);
	}
}
