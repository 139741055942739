import React from 'react';
import PropTypes from 'prop-types';
import MagicForm from '../components/magicForm';
import { Link } from 'react-router-dom';

class Coord extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		placeUpdate : PropTypes.func.isRequired,
	};

	constructor(props, context){
		super(props, context);
		let adress = this.context.place.adress;
		let coordonnes = this.context.place.coordonnes;
		this.state = this.coordToState(adress, coordonnes);
	}

	coordToState = (adress, coordonnes) => {
		return {
			form : {
				phone : { value : coordonnes.phone },
				website : { value : coordonnes.website }
			},
			editing : false
		};
	};

	render() {
		return (
			<MagicForm
			title="Coordonnées"
			formClass='club-coord-form'
			editing = {this.state.editing}
			onCancelEdit = {this.onCancelEdit}
			onSubmit = {this.onSubmit}
			onEdit={this.onEdit}
			>
					<label>
						<span className="picto-tel">Téléphone</span>
						<input type="text" name="phone" placeholder="01 XX XX XX XX" value={this.state.form.phone.value} onChange={this.onChange}/>
					</label>
					{this.state.editing &&
					<label>
						<span className="picto-web">Site Internet</span>
						<input type="text" name="website" placeholder="Votre site internet" value={this.state.form.website.value} onChange={this.onChange}/>
					</label>
					}
					{!this.state.editing && this.state.form.website.value &&
						<Link target="_blank" to={this.state.form.website.value}>{this.state.form.website.value}</Link>
					}
					{!this.state.editing && !this.state.form.website.value &&
						<input type="text" name="website" placeholder="Votre site internet" value={this.state.form.website.value} onChange={this.onChange}/>
					}
			</MagicForm>
		);
	}

	onSubmit = (event) => {
		event.preventDefault();
		let formValues = {
			coordonnes : {
				phone : this.state.form.phone.value,
				website : this.state.form.website.value
			}
		};

		this.context.placeUpdate(formValues)
		.then(reponse =>{
			this.setState({editing:false});
		})
		.catch(e => {
			console.error(e, e.stack);
		});
	};

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = { value: event.target.value};
		this.setState(newState);
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let adress = this.context.place.adress;
		let coordonnes = this.context.place.coordonnes;
		this.setState(this.coordToState(adress, coordonnes));
	};

	onEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		event.nativeEvent.stopImmediatePropagation();
		this.setState({editing:true});
	};
}

export default Coord;
