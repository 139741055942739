import React from 'react';
import PropTypes from 'prop-types';
import FacebookLinkConfiguration from '../containers/FacebookLinkConfiguration';


class FacebookLink extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render() {
		return (
			<section style={{display:'flex', flexDirection:'column'}}>
				<section className='widget-help-page' style={{marginBottom:"1rem"}}>
					<h1 style={{marginBottom:"1rem"}}>Explication</h1>
					<p>Grâce au bouton Facebook vous pouvez récupérer les données (email + portable) des internautes qui visitent votre page Facebook et ensuite les rediriger vers votre site internet.</p>
					<p>L'url à utiliser dans Facebook est :</p>
					<code>
						https://www.masalledesport.com/fbOffer/{this.context.place.id}
					</code>
				</section>
				<FacebookLinkConfiguration />
			</section>
		);
	}
}

export default FacebookLink;
