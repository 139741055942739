import React from 'react';
import moment from 'moment';

class SubscriptionBasket extends React.Component {

	constructor(props){
		super(props);
		this.state = { cgu : false, invalid:false};
	}

	onChange = (event) => {
		this.setState({cgu : !this.state.cgu, invalid:false});
	};

	valid = (event) => {
		event.preventDefault();
		if(!this.state.cgu){
			this.setState({invalid:true});
			return;
		}
		this.props.valid();
	};

	render(){
		return (
			<section>
				<table style={{margin:'1rem'}}>
					<tbody>
						<tr>
							<td><strong>Formule {this.props.plan}</strong></td>
							<td><strong>{this.props.price}</strong></td>
						</tr>
						<tr>
							<td colSpan='2'><em>Abonnement 12 mois du {moment().format('DD/MM/YYYY')} au {moment().add(1,'year').format('DD/MM/YYYY')}</em></td>
						</tr>
						{this.props.activation && <tr>
							<td>Frais d'activation (valable une seule fois)</td>
							<td>{this.props.activation}</td>
						</tr>
						}
						{this.props.refund && <tr>
							<td>Réduction Prorata Formule Basic Comptant</td>
							<td>{this.props.refund}</td>
						</tr>
						}
						<tr>
							<td><strong>Total sur 12 mois (€ HT)</strong></td>
							<td><strong>{this.props.total}</strong></td>
						</tr>
					</tbody>
				</table>
				<label className={this.state.invalid ? "checkbox inputInvalid club-cgu" : "checkbox club-cgu"}>
					<input type="checkbox" name="cgu" checked={this.state.cgu} onChange={this.onChange}/>
					<span>
						J'ai lu et accepté les <a target='_blank' rel="noopener noreferrer" href="https://www.masalledesport.com/referencement-cgv">conditions générales de vente</a> de MaSalleDeSport.com
					</span>
				</label>

				<button className="button-primary" onClick={this.valid}>{this.props.label}</button>
			</section>
		);
	}

}


export default SubscriptionBasket;
