import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import { Link } from 'react-router-dom';
import OffreDetail from '../containers/OffreDetail';

class ClubOffre extends React.Component {
	static contextTypes = {
		place: PropTypes.object.isRequired,
		placeUpdate : PropTypes.func.isRequired,
	};

	offerGet = (clubId) => {
		return API.GET(`/user/me/club/${clubId}/offer`);
	};

	offerUpdate = (clubId, body) => {
		return API.PUT(`/user/me/club/${clubId}/offer`, body);
	};

	render() {
		return (
			<div >
				<OffreDetail offerGet={this.offerGet} offerUpdate={this.offerUpdate}/>
				<Link className="button-primary" to ={`/club/${this.context.place.id}/prospects/essais`}>Voir vos demandes</Link>
			</div>

		);
	}
}

export default ClubOffre;
