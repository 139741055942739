import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom'
import API from '../utils/api';

export default class Abo extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
	};

	constructor(props) {
		super(props);
		this.state = {
			step: 'INIT',
			abo: null
		}
	}

	componentDidMount() {
		API.GET(`/api/abo/abo/${this.props.match.params.aboId}`)
			.then(response => {
				this.setState({step: "OK", abo: response});
			})
			.catch(e => {
				if (e.restCode === "mustUpgradePlan") {
					return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
				}
				console.error(e);
				this.setState({step: "ERROR", abo: null});
			});
	}

	render() {
		if (this.state.step === 'INIT') {
			return (
				<strong>Loading...</strong>
			)
		}

		if (this.state.step === 'ERROR') {
			return (
				<p>ERROR</p>
			)
		}

		const abo = this.state.abo;
		var parentUrl = this.props.match.url.split('/').slice(0, -1).join('/');
		var monthly = abo.version === 2 ? abo.plan.monthlyPrice : abo.plan.price;

		return (
			<section className={"abo-page"}>
				<h1><Link to={parentUrl}>Vos abonnements</Link></h1>

				<div className={"abo-client"}>
					<h2>Client</h2>
					<strong>{abo.client.firstname} {abo.client.lastname}</strong>
					<a href={`mailto:${abo.client.email}`}>{abo.client.email}</a>
					<a href={`tel:${abo.client.phone}`}>{abo.client.phone}</a>
				</div>

				<div className={"abo-plan"}>
					<h2>Formule</h2>
					<Link to={`/club/${this.context.place.id}/shop/abos`}>
						<strong>{abo.plan.name}</strong>
					</Link>
					<span>
						{monthly / 100}€ / mois
					</span>
					{abo.plan.fees && <p>Frais supplémentaires</p>}
					{abo.plan.fees && <span>
						{abo.plan.fees.amount / 100}€
					</span>
					}
				</div>

				<div className={"abo-options"}>
					<h2>Options</h2>
					{!!abo.options.length ?
					<ul>
						{abo.options.map(o =>
							<li key={o.id}>
								<Link to={`/club/${this.context.place.id}/shop/options`}>
									<strong>
										{o.name}
									</strong>
								</Link>
								<span>
								{o.price / 100}€ / mois
							</span>
							</li>
						)}
					</ul>
					:
					<p>Aucune option souscrite</p>
					}
				</div>

				<div className={"abo-rib"}>
					<h2>Sepa</h2>
					<strong>{abo.paymentMethod.owner}</strong>
					<p>{abo.paymentMethod.rib}</p>
					{abo.sepaFormUrl &&
					<a target='_blank' rel="noopener noreferrer" download href={abo.sepaFormUrl}>Télécharger</a>
					}
				</div>
			</section>
		)
	}
}