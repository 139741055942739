import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';

import whiteAddIcon from '../static/icons/picto-add--white.svg';
import whiteCancelIcon from '../static/icons/picto-cancel--white.svg';
import whiteDeleteIcon from '../static/icons/picto-delete--white.svg';

class Options extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.reload = this.reload.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.addOption = this.addOption.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state ={
			step : "LOADING",
			options : null,
			form: {
				name: {
					value: '',
					error: null
				},
				price: {
					value: '',
					error: null
				},
				description: {
					value: '',
					error: null
				}
			}
		};
	}

	componentDidMount() {
		this.reload();
	}

	reload(){
		this.setState({action : "LOADING"});
		API.GET(`/api/abo/abo/option?place.id=${this.context.place.id}`)
		.then(res=>{
			this.setState({step:"VIEW", options:res});
		})
		.catch(e => {
			console.error(e, e.stack);
			this.setState({step:"ERROR"});
		})
		;
	}

	onDelete(optionId, event){
		event.preventDefault();
		let result = window.confirm("Êtes-vous sûr de vouloir supprimer cette option ?");
		if(result){
			this.setState({action : "LOADING"});
			API.DELETE(`/api/abo/abo/option/${optionId}`)
			.then(res=>{
				this.reload();
			})
			.catch(e => {
				console.error(e, e.stack);
				this.setState({step:"ERROR"});
			})
		}
	}

	addOption() {
		this.setState({action: "ADD"});
	}

	onChange(e) {
		let newState = Object.assign({}, this.state);
		newState.form[e.target.name] = {value : e.target.value} ;
		this.setState(newState);
	}

	onSubmit(e) {
		e.preventDefault();
		let newState = Object.assign({}, this.state);
		let errors = 0;
		['name', 'price', 'description'].forEach(f => {
			if (!newState.form[f].value) {
				newState.form[f].error = "Veuillez remplir ce champ";
				errors++;
			}
		});

		if (errors !== 0) {
			this.setState(newState);
			return ;
		}
		let option = {
			name: newState.form.name.value,
			price: newState.form.price.value * 100,
			description: newState.form.description.value
		};

		API.POST(`/api/abo/abo/option?place.id=${this.context.place.id}`, option)
		.then(() => {
			this.reload();
		})
		.catch(e => {
			this.setState({step: 'ERROR'});
			console.error(e, e.stack);
		});
	}

	render() {
		if(this.state.step === 'LOADING'){
			return <p>Loading</p>;
		}
		if(this.state.step === 'ERROR'){
			return <p>Une erreur est survenue</p>;
		}

		return (
			<section className="club-options-page">
				<header>
					<h1>Vos options ({this.state.options.length})</h1>
					<button className="button-add"  onClick={this.addOption}>
						<img src={whiteAddIcon} alt="Ajouter"/>
					</button>
				</header>
				<ul className={'club-options-list'}>
					{this.state.options.map(o =>
						<li key={o.id}>
							<Option o={o} onDelete={this.onDelete.bind(this, o.id)}/>
						</li>
					)}
					{this.state.action === 'ADD' &&
						<li key={'newOption'}>
							<form className={'club-option-form'}>
								<header>
									<button onClick={this.reload}><img src={whiteCancelIcon} alt="Annuler"/></button>
									<input name={'name'} type="text" placeholder={'Intitulé'}
									       value={this.state.form.name.value} onChange={this.onChange} className={this.state.form.name.error?'inputInvalid':''}/>
									<input name={'price'} type="number" placeholder={'Prix (€/mois)'}
									       value={this.state.form.price.value} onChange={this.onChange} className={this.state.form.price.error?'inputInvalid':''}/>
								</header>
								<textarea name="description" placeholder={"Description de l'option"}
								          defaultValue={this.state.form.description.value} onChange={this.onChange} className={this.state.form.description.error?'inputInvalid':''}/>
								<button className='button-primary' onClick={this.onSubmit}>Valider</button>
								<button className='button-secondary' onClick={this.onCancelCreate}>Annuler</button>
							</form>
						</li>
					}
				</ul>
			</section>
		);
	}
}

var Option = ({o, onDelete})=>{
	return (
		<article>
			<header>
				<h2>{o.name}</h2>
				<p className={'price'}>{o.price/100}€/mois</p>
				<button onClick={onDelete}><img src={whiteDeleteIcon} alt="Suppr."/></button>
			</header>
			<p>
				{o.description}
			</p>
	</article>
	);
};

export default Options;
