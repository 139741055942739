import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import cancelIcon from '../static/icons/picto-cancel.svg';

const INITIAL_DELAY = 1000;
const DISPLAY_DELAY = 1000 * 3600 * 24 ;

class SubscriptionIncitation extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		user: PropTypes.object.isRequired
	};


	constructor(props, context){
		super(props, context);
		this.state = {
			step: 'LOADING'
		};
	}

	componentDidMount() {
		if (this.context.user.isAdmin) {
			return;
		}
		setTimeout(this.refreshStatus, INITIAL_DELAY);
		var intervalId = setInterval(this.refreshStatus, DISPLAY_DELAY / 10);
		this.setState({intervalId});

	}

	componentWillUnmount () {
		if (this.context.user.isAdmin) {
			return;
		}

		clearInterval(this.state.intervalId);
	}

	refreshStatus = ()=>{
		if (this.state.step === 'ACTIVATE' || this.state.step === 'UPGRADE') {
			return ;
		}

		var plan = this.context.place.subscription.plan;

		if(plan.startsWith("PERFORMANCE"))
			return this.setState({step:'IGNORE'});

		if(plan.indexOf("DEMO") !== -1)
			return this.setState({step:'IGNORE'});

		if(!this.mustIncitate(this.context.place.id)){
			return this.setState({step:'IGNORE'});
		}

		this.storeIncitationLastDisplayed(this.context.place.id);

		if(plan === 'ANNUAIRE'){
			return this.setState({step:"ACTIVATE"})
		}

		if(plan.startsWith('PREMIUM')){
			return this.setState({step:"UPGRADE"})
		}
	};

	mustIncitate = (placeId) => {
		try {
			var lastTime = localStorage.getItem(`incitation-${placeId}`);
			if(!lastTime) return true;
			return new Date().getTime() - lastTime > DISPLAY_DELAY;
		}catch(e){
			console.error("Error while loading incitation from localstorage", e, e.stack);
			return false;
		}
	};

	storeIncitationLastDisplayed = (placeId) => {
		try {
			localStorage.setItem(`incitation-${placeId}`, new Date().getTime());
		}catch(e){
			console.error("Error while storing incitation to localstorage", e, e.stack);
		}
	};

	doClose = (event)=>{
		event.preventDefault();
		this.storeIncitationLastDisplayed(this.context.place.id);
		this.setState({step:"IGNORE"});
	};


	render(){
		const placeId = this.context.place.id;

		if(this.state.step === 'LOADING'){
			return this.props.children;
		}

		if(this.state.step === 'IGNORE'){
			return this.props.children;
		}

		if(this.state.step === 'ACTIVATE'){
			return <Incitation title='Activez votre compte' doClose={this.doClose} link={{url:"/club/"+ placeId +"/subscription/subscribe", label:"Voir les offres"}}>
				<p>Choisissez une offre pour commencer à recevoir des prospects</p>
				<ol>
					<li><strong>Offre Basic :</strong> Environ 100 prospects par an</li>
					<li><strong>Offre Performance :</strong> Environ 300 prospects par an + Module E-Réputation</li>
				</ol>
			</Incitation>
		}

		if(this.state.step === 'UPGRADE'){
			return <Incitation title="Upgradez votre abonnement" doClose={this.doClose} link={{url:"/club/"+ placeId +"/subscription/upgrade", label:"Voir l'offre Performance"}}>
				<p>Recevez 3x plus de prospects avec l'offre Performance</p>
				<ul>
					<li><strong>Option Booster :</strong> Soyez plus efficaces dans la collecte de prospects</li>
					<li><strong>Module E-Reputation :</strong> Reprenez le contrôle de votre réputation sur Internet</li>
				</ul>
			</Incitation>
		}
	}

}

const Incitation = (props) => {
	return(
		<div className="modal-mask">
			<div className="modal incitation-modal">
				<header>
					<h2>{props.title}</h2>
					<img onClick={props.doClose} src={cancelIcon} alt="X"/>
				</header>
				<div className="modal-content">
					{props.children}
					<footer>
						<Link className="button-primary" to={props.link.url}>{props.link.label}</Link>
					</footer>
				</div>
			</div>
		</div>
	);
};

export default SubscriptionIncitation;
