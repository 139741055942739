import React from 'react';

export default initialProps => {
	let props = Object.assign({}, initialProps);

	["validOnChange"].forEach((k)=>{
		if(props[k] === undefined){
			props[k] = true;
		}
	});
	if(props.invalidClassName === undefined){
		props.invalidClassName = "inputInvalid";
	}

	const onChange = (realOnChange, event) =>{
		const name = event.target.name;
		let value = event.target.value;
		let valid;
		if( props.validOnChange ){
			if(!props.validator){
				//DO A WARN
			} else {
				let validation = props.validator(value);
				value = validation.cleaned;
				valid = validation.valid;
			}
		}
		realOnChange({ target : {name, value, valid} });
	}

	let classNames= props.className ? [props.className] : [];

	if( props.valid === false ){
		if(!props.invalidClassName){
			//DO A WARN
		}else{
			classNames.push( props.invalidClassName );
		}
	}

	return <input type="text"
			name={props.name}
			value = {props.value}
			onChange={onChange.bind(null, props.onChange)}
			placeholder={props.placeholder}
			className={classNames.join(" ")}
		/>
	;
}
