import React, { useContext } from 'react';
import ClubsChooser from '../components/clubsChooser';

import { PlaceContext, UserContext } from '../react_contexts';

export default function ClubChooserContainer() {
	const {places, place} = useContext(PlaceContext);
	const user = useContext(UserContext);

	return (
		<ClubsChooser
			canAddClub={user?.isAdmin}
			clubs={places.filter(x => x.id !== (place && place.id))}
			selected={place}
		/>
	);
}
