import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import MagicForm from '../components/magicForm';
import Loader from '../components/loader';
import Duplicate from '../components/Duplicate';


class Activities extends React.Component {
	static contextTypes = {
		place: PropTypes.object.isRequired,
		places : PropTypes.array.isRequired,
		applicationDatas : PropTypes.object.isRequired
	};

	state = {
		editing: false,
		step: 'LOADING',
	};

	componentDidMount() {
		this.reload();
	}

	onEdit = (event) => {
		event.preventDefault();
		this.setState({editing: true});
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		let newState = this.activitiesToState(this.state.activities);
		this.setState(newState);
	};

	onSubmit = (event) => {
		event.preventDefault();
		this.setState({step: "LOADING"});

		let activityIds = Object
			.keys(this.state.form)
			.filter((k) => this.state.form[k])
			.map((i) => +i);

		var body = {
			activities : activityIds
		};

		API.PUT(`/user/me/club/${this.context.place.id}/activity`, body)
		.then(res => {
			this.reload();
		})
		.catch(e=> console.error(e, e.stack))
		;
	};

	updateForPlace = (placeId) => {
		let activityIds = Object
		.keys(this.state.form)
		.filter((k) => this.state.form[k])
		.map((i) => +i);

		var body = {
			activities : activityIds
		};

		return API.PUT(`/user/me/club/${placeId}/activity`, body);
	};


	reload = () => {
		this.setState({step:'LOADING'});
		API.GET(`/user/me/club/${this.context.place.id}/activity`)
		.then((res) => {
			let newState = this.activitiesToState(res);
			this.setState(newState);
		})
		.catch((e) => {
			console.error(e);
		});
	};

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = !newState.form[event.target.name];
		this.setState(newState);
	};

	activitiesToState = (activities) => {
		let state = {
			editing: false,
			step: "OK",
			form: {},
			activities: activities
		};
		this.context.applicationDatas.activities
			.forEach((category) => {
				category.children.forEach((activity) => {
					let found = activities.find(s => {
						return s.id == activity.id;
					});
					state.form[activity.id] = !!found;
				});

			});
		return state;
	};

	duplicate = (event) => {
		event.preventDefault();
		this.setState({step:'DUPLICATE'});
	};

	render() {
		if(this.state.step === 'DUPLICATE'){
			return (
				<Duplicate
					title="Dupliquer les activités"
					places={this.context.places.filter(p=>p.id !== this.context.place.id)}
					updateOne = {this.updateForPlace}
					cancel = {()=> this.setState({step:'OK'})}
					done = {this.reload}
				>
					<div>
						Sélectionnez les clubs pour lesquells vous voulez dupliquer les activités
					</div>
				</Duplicate>
			);
		}

		if (this.state.step === "LOADING") {
			return <Loader title="Activités"/>
		}
		let activitiesList = this.context.applicationDatas.activities;

		return (
			<div>
				{!this.state.edit && !!this.context.places.length && <button onClick={this.duplicate} className='button-duplicate'>Dupliquer</button>}
				<MagicForm
					title="Activités"
					formClass='club-activities-page'
					editing={this.state.editing}
					onCancelEdit={this.onCancelEdit}
					onSubmit={this.onSubmit}
					onEdit={this.onEdit}
				>
					<div className="club-activities-content">
						{activitiesList
							.sort( (a,b) => a.name.localeCompare(b.name) )
							.map((category) => {
							return <Category key={category.id}
							                 category={category}
							                 onChange={this.onChange}
							                 state={this.state}
							/>;
						})}
					</div>
				</MagicForm>
			</div>
		);
	}
}


const Category = (props) => {
	let category = props.category;

	let count = 0;
	let actif = 0;
	category.children.forEach((a) => {
		count++;
		if (props.state.form[a.id]) {
			actif++;
		}
	});

	return (
		<div className="club-activities-category" key={category.id}>
			<h3>{category.name + " ( " + actif + " / " + count + " )"}</h3>
			<section>{category.children
				.sort( (a,b) => a.name.localeCompare(b.name) )
				.map((activity) => {
				return <Activity key={activity.id}
				                 activity={activity}
				                 onChange={props.onChange}
				                 state={props.state}
				/>;
			})}
			</section>
		</div>
	);
};

class Activity extends React.Component {

	static contextTypes = {
		user: PropTypes.object.isRequired
	};

	render(){
			let activity = this.props.activity;
			let id = `activity_${activity.id}_input`;
			return (
				<label htmlFor={"activity_" + id + "_check"}
				       className={this.props.state.form[activity.id] ? "checked" : ""}>
					<input
						id={"activity_" + id + "_check"}
						disabled = { activity.protected && (!this.context.user || !this.context.user.isAdmin)}
						type="checkbox"
						name={activity.id}
						onChange={this.props.onChange}
						checked={this.props.state.form[activity.id]}
					/>
					<span>{activity.name}</span>
				</label>
			);
	}
}

export default Activities;
