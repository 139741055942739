import React from 'react';
import Subscription from '../containers/Subscription';

class SubscriptionContainer extends React.Component {
	render() {
		return (
			<section className="club-subscription-page">
				<Subscription/>
			</section>
		);
	}
}



export default SubscriptionContainer;