import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import MagicForm from '../components/magicForm';
import Loader from '../components/loader';
import Duplicate from '../components/Duplicate';

class ClubServices extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		places: PropTypes.array.isRequired,
		applicationDatas : PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = {step: "LOADING", editing: false, form: {}};
	}

	componentDidMount() {
		API.GET(`/user/me/club/${this.context.place.id}/service`)
		.then(res => {
			let newState = this.servicesToState(res);
			this.setState(newState);
		})
		.catch(e=> {
			console.error(e, e.stack);
		})
		;
	}

	servicesToState = (services) => {
		let state = {
			step: "OK",
			editing: false,
			form: {},
			services: services
		};
		this.context.applicationDatas.services
			.forEach((se) => {
				let found = services.find(s => {
					return s.id === se.id;
				});
				state.form[se.id] = !!found;
			});
		return state;
	};

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = !newState.form[event.target.name];
		this.setState(newState);
	};

	onSubmit = (event) => {
		event.preventDefault();

		let actifs = Object
		.keys(this.state.form)
		.filter((id) => {
			return this.state.form[id];
		})
		.map((i) => +i);

		var body = {
			services : actifs
		};

		API.PUT(`/user/me/club/${this.context.place.id}/service`, body)
		.then(res => {
			this.setState({editing: false});
		}).catch(e => {
			console.error(e, e.stack);
		})
		;
	};

	updateForPlace = (placeId) => {
		let actifs = Object
		.keys(this.state.form)
		.filter((id) => {
			return this.state.form[id];
		})
		.map((i) => +i);

		var body = {
			services : actifs
		};

		API.PUT(`/user/me/club/${placeId}/service`, body)
		.then(res => {
			this.setState({editing: false});
		}).catch(e => {
			console.error(e, e.stack);
		})
		;
	};


	onEdit = (event) => {
		event.preventDefault();
		this.setState({editing: true});
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.setState(this.servicesToState(this.state.services));
	};

	duplicate = (event) => {
		event.preventDefault();
		this.setState({step:'DUPLICATE'});
	};

	render() {
		if (this.state.step === "LOADING") {
			return <Loader title="Services"/>;
		}

		if(this.state.step === 'DUPLICATE'){
			return (
				<Duplicate
					title="Dupliquer les services"
					places={this.context.places.filter(p=>p.id !== this.context.place.id)}
					updateOne = {this.updateForPlace}
					cancel = {()=> this.setState({step:'OK'})}
					done = {this.reload}
				>
					<div>
						Sélectionnez les clubs pour lesquells vous voulez dupliquer les services
					</div>
				</Duplicate>
			);
		}

		let servicesList = this.context.applicationDatas.services.filter(e => e.selectable);
		let title = "Services (" + Object.keys(this.state.form).filter((k) => this.state.form[k]).length + "/" + this.context.applicationDatas.services.length + ")";
		return (
			<div>
				{!this.state.edit && !!this.context.places.length && <button onClick={this.duplicate} className='button-duplicate'>Dupliquer</button>}
				<MagicForm formClass='club-services-form' title={title}
						editing={this.state.editing} onCancelEdit={this.onCancelEdit} onSubmit={this.onSubmit}
						onEdit={this.onEdit}>
					<section className='club-services-list'>
						{servicesList
							.sort( (a,b) => a.name.localeCompare(b.name) )
							.map((c) => {
							return (
								<label className={this.state.form[c.id] ? "checked" : ""} key={c.id}>
									<input type="checkbox" name={c.id}
									       checked={this.state.form[c.id]}
									       onChange={this.onChange}
									/>
									<span>{c.name}</span>
								</label>
							)
						})}
					</section>
				</MagicForm>
			</div>
		);
	}
}

export default ClubServices;
