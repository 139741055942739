import React from 'react';
import PropTypes from 'prop-types';
import MagicForm from '../components/magicForm';

class Presentation extends React.Component {

	static contextTypes = {
		user: PropTypes.object.isRequired,
		place: PropTypes.object.isRequired,
		placeUpdate: PropTypes.func.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCancelEdit = this.onCancelEdit.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.onChange = this.onChange.bind(this);

		let presentation = this.context.place.presentation;
		this.state = Presentation.presentationToState(presentation);

		this.fields = [
			{ apiField: "introduction", title: "Introduction" }
			, { apiField: "content", title: "Contenu" }
		];
	}

	static presentationToState(presentation) {
		return {
			initial: presentation,
			form: {
				visite: {
					editing: false,
					value: presentation.visite
				}
				, introduction: {
					editing: false,
					value: presentation.introduction
				}
				, content: {
					editing: false,
					value: presentation.content
				},
				trialButtonText: {
					editing: false,
					value: presentation.trialButtonText
				}
			}
		};
	}

	onSubmit(key, event) {
		event.preventDefault();
		event.stopPropagation();

		let data = {
			presentation: {
				[key]: this.state.form[key].value,
			}
		};

		let newState = Object.assign({}, this.state);
		newState.form[key].editing = false;

		this.context.placeUpdate(data)
			.then(response => {
				this.setState(newState);
			})
			.catch(e => {
				console.error(e, e.stack);
			})
			;
	}

	onCancelEdit(key, event) {
		event.preventDefault();

		let newState = Object.assign({}, this.state);
		newState.form[key].editing = false;
		newState.form[key].value = this.state.initial[key];
		this.setState(newState);
	}

	onEdit(key, event) {
		event.preventDefault();

		let newState = Object.assign({}, this.state);
		newState.form[key].editing = true;
		this.setState(newState);
	}

	onChange(event) {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name].value = event.target.value;
		this.setState(newState);
	}

	render() {
		return (
			<section className="club-presentation-page">
				<h1 className="pageTitle">Présentation du club</h1>
				{
					this.fields.map((type) => {
						return (
							<MagicForm key={type.apiField}
								formClass='presentation'
								title={type.title}
								editing={this.state.form[type.apiField].editing}
								onCancelEdit={this.onCancelEdit.bind(this, type.apiField)}
								onSubmit={this.onSubmit.bind(this, type.apiField)}
								onEdit={this.onEdit.bind(this, type.apiField)}
							>
								<textarea name={type.apiField}
									placeholder={type.title}
									onChange={this.onChange}
									value={this.state.form[type.apiField].value}>
								</textarea>
							</MagicForm>
						)
					})
				}
				<MagicForm
					key="visite"
					formClass='virtualForm'
					title="Visite virtuelle"
					editing={this.state.form.visite.editing}
					onCancelEdit={this.onCancelEdit.bind(this, "visite")}
					onSubmit={this.onSubmit.bind(this, "visite")}
					onEdit={this.onEdit.bind(this, "visite")}
				>
					{!this.state.form.visite.editing && this.state.form.visite.value
						&& <div dangerouslySetInnerHTML={{ __html: this.state.form.visite.value }}></div>
					}
					{!this.state.form.visite.editing && !this.state.form.visite.value
						&& <div>Vous n'avez pas de visite virtuelle</div>
					}
					{this.state.form.visite.editing
						&& <textarea name="visite"
							placeholder="Code iframe à insérer"
							onChange={this.onChange}
							value={this.state.form.visite.value}>
						</textarea>
					}
				</MagicForm>
				{['PREMIUM', 'PREMIUM_DEMO', 'PERFORMANCE', 'PERFORMANCE_DEMO'].includes(this.context.place.subscription.plan) && (
					<MagicForm
						key="trialButtonText"
						formClass='virtualForm'
						title="Bouton séance d'essai"
						editing={this.state.form.trialButtonText.editing}
						onCancelEdit={this.onCancelEdit.bind(this, "trialButtonText")}
						onSubmit={this.onSubmit.bind(this, "trialButtonText")}
						onEdit={this.onEdit.bind(this, "trialButtonText")}
					>
						{!this.state.form.trialButtonText.editing && this.state.form.trialButtonText.value
							&& <div dangerouslySetInnerHTML={{ __html: this.state.form.trialButtonText.value }}></div>
						}
						{!this.state.form.trialButtonText.editing && !this.state.form.trialButtonText.value
							&& <div>Recevez une séance d'essai</div>
						}
						{this.state.form.trialButtonText.editing
							&& <textarea name="trialButtonText"
								placeholder="texte du bouton"
								onChange={this.onChange}
								value={this.state.form.trialButtonText.value}
								maxLength={150}
							>
							</textarea>
						}
					</MagicForm>
				)}


			</section>
		);
	}
}

export default Presentation;
