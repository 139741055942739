import React from 'react';

export default class Duplicate extends React.Component {

	constructor(props){
		super(props);
		var state = {step:'INIT', places:{}};
		this.props.places
		.sort( (a,b) => a.name.localeCompare(b.name))
		.forEach(p=>{
			state.places[p.id] = {
				id   : p.id,
				name : p.name,
				selected : true
			};
		});
		this.state = state;
	}

	doUpdate = async (event) => {
		event.preventDefault();
		var count = Object.values(this.state.places).filter(p=>p.selected).length;
		if(!count) return;

		var state = Object.assign({}, this.state);
		state.step = 'PROGRESS';
		Object.values(this.state.places).forEach(p => {
			delete p.success;
		});
		this.setState(state);

		var finalState = Object.assign({}, state);
		finalState.step = 'SUCCESS';

		await Object.values(finalState.places).filter(p=>p.selected).forEach(async (p)=>{
			try {
				await (this.props.updateOne(p.id));
				finalState.places[p.id].success = true;
			} catch(e) {
				console.error(e);
				finalState.places[p.id].success = false;
			}
			await this.setState(finalState);
		});
	};

	close = (event) => {
		event.preventDefault();
		this.props.cancel();
	};

	cancel = (event) => {
		event.preventDefault();
		this.props.cancel();
	};


	onChange = (placeId, event) => {
		var places = Object.assign({}, this.state.places);
		places[placeId].selected = !places[placeId].selected;
		this.setState({places});
	};

	render(){
		let disabled = this.state.step === 'PROGRESS';

		return (
			<div className='duplicate-form'>
				<h1>{this.props.title}</h1>
				{this.props.children}
				<h3>Vos clubs :</h3>
				<ul>
					{Object.values(this.state.places).map(p=>{
						var style = {};
						if( this.state.places[p.id].success === true ) {
							style.color = 'green';
						}
						if( this.state.places[p.id].success === false ) {
							style.color = 'red';
						}
						return (
							<li key={p.id}>
								<label style={style}>
									<input disabled={disabled} type='checkbox' name={p.id} checked={this.state.places[p.id].selected} onChange={this.onChange.bind(this, p.id)}/>
									<span>{p.name}</span>
								</label>
							</li>
						);
					})}
				</ul>

				<footer>
					{this.state.step === 'PROGRESS' &&
					<p>Duplication en cours, veuillez patienter</p>
					}
					{this.state.step === 'SUCCESS' &&
					<p>Duplication terminée <button onClick={this.close} className='button-primary'>Fermer</button></p>
					}
					{this.state.step === 'INIT' && [
						<button key='cancel' className='button-secondary' onClick={this.cancel}>Annuler</button>
						,
						<button key='duplicate' className='button-primary' onClick={this.doUpdate}>Dupliquer</button>
					]}
				</footer>
			</div>
		);
	}
}
