import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Loader from '../components/loader';
import Pagination from '../components/pagination';
import SocialReasonContainer from '../containers/SocialReasonContainer';
import moment from 'moment';

class InvoicesContainer extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);

		this.state = {
			step : "LOADING",
			page : 1
		};
		this.changePage = this.changePage.bind(this);
	}

	changePage(event){
		event.preventDefault();
		this.setState({page: parseInt(event.target.dataset.page)});
	}

	componentDidMount() {
		API.GET(`/user/me/club/${this.context.place.id}/invoice`)
		.then(res=>{
			this.setState({step:"OK", invoices:res});
		})
		.catch(e=>{
			console.error(e, e.stack);
		})
		;
	}

	render() {
		if(this.state.step === "LOADING"){
			return <Loader title="Vos factures"/>;
		}
		if(this.state.invoices.length === 0){
			return (
				<div>
					<SocialReasonContainer/>
					<section className="club-invoices">
						<h1 className="pageTitle">Vos factures</h1>
						<p className="msdsAdvice">Votre 1ère facture sera disponible une fois que le 1er prélèvement sera effectué</p>
					</section>
				</div>
			);
		}
		var page = this.state.page;
		var nbPerPage = 10;
		var displayed = this.state.invoices.slice( (page-1) * nbPerPage, page*nbPerPage);
		const pageMax = Math.ceil(this.state.invoices.length / nbPerPage );

		return (
			<div>
				<SocialReasonContainer/>
				<section className="club-invoices">
					<h1 className="pageTitle">Vos factures</h1>
					<Pagination currentPage={this.state.page} maxPage = {pageMax} onClick={this.changePage}/>
					<div className="invoices">
						<table>
							<thead>
							<tr>
								<th>Date</th>
								<th>Type</th>
								<th>Status</th>
								<th>Téléchargez</th>
							</tr>
							</thead>
							<tbody>
							{
								displayed.map((invoice)=>{
									return <InvoiceLine key={invoice.id} invoice={invoice}/>;
								})
							}
							</tbody>
						</table>
					</div>
				</section>
			</div>
		);
	}

}

const InvoiceLine = (props) => {
	const invoice = props.invoice;

	if( invoice.url ){
		return  (
			<tr key={invoice.id}>
				<td>{moment(invoice.date).format("DD/MM/YYYY")}</td>
				<td>{invoice.type}</td>
				<td>{invoice.status}</td>
				<td><a target="_blank" rel="noopener noreferrer" download href={invoice.url} className="button-download">Facture n° {invoice.name}</a></td>
			</tr>
		);
	}
	return (
		<tr key={invoice.id}>
			<td>{invoice.date}</td>
			<td>Facture n° {invoice.name}</td>
		</tr>
	);
};

export default InvoicesContainer;
