import React from 'react';

import pictoDelete from '../static/icons/picto-delete--red.svg';
import pictoDownload from '../static/icons/picto-download.svg';

export default props => {
	return (
		<div className="club-gallery-item">
			<img src={props.url} alt="club"/>
			{props.onClick && <div className="club-image-action">
				<button className='button-delete' onClick={props.onClick}>
					<img src={pictoDelete} alt="Supprimer" title="Supprimer cette photo"/>
				</button>
				<a className="button-download" href={props.originalUrl} download={true}>
					<img src={pictoDownload} alt="Télécharger" title="Télécharger la photo originale"/>
				</a>
			</div>
			}
		</div>
	);
}
