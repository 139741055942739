import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import MagicForm from '../components/magicForm';
import Loader from '../components/loader';

class PlaceInfo extends React.Component {

	static contextTypes = {
		user: PropTypes.object.isRequired,
		place : PropTypes.object.isRequired,
		applicationDatas : PropTypes.object.isRequired
	};

	constructor(props, context) {		super(props);
		this.state ={
			step : "LOADING",
			editing:false
		};
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.setState({step : "LOADING"});
		let clubId = this.context.place.id;

		return API.GET(`/user/me/club/${clubId}/info`)
		.then(infos => {
			this.setState({
				step : "OK",
				infos : infos,
				editing:false
			});
		})
		.catch(e=>{
			console.error(e, e.stack);
			this.setState({step:"ERROR", error :e});
		})
		;
	};

	onSubmit = (event) => {
		event.preventDefault();
		let clubId = this.context.place.id;
		return API.PUT(`/user/me/club/${clubId}/info`, this.state.infos)
		.then(res => {
			this.reload();
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error(e, e.stack);
			this.setState({step:"ERROR", error :e});
		})
		;
	};


	onEdit = (event) => {
		event.preventDefault();
		this.setState({editing:true});
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		this.reload();
	};

	onChange = (event) => {
		var newState = Object.assign({}, this.state);
		newState.infos[event.target.name] = event.target.value;
		return this.setState(newState);
	};


	render (){
		if(this.state.step === "LOADING"){
			return <Loader title="Votre club"/>;
		}
		if (this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>
		}


		let networks = this.context.applicationDatas.networks.filter(n=>n.selectable).sort((a,b)=>{return a.name.localeCompare(b.name)});
		let clubTypes = this.context.applicationDatas.clubTypes.filter(n=>n.selectable).sort((a,b)=>{return a.name.localeCompare(b.name)});
		return (
			<MagicForm
					editing={this.state.editing}
					onEdit={this.context.user.isAdmin && this.onEdit}
					title="Votre club"
					onCancelEdit={this.onCancelEdit}
					onSubmit={this.onSubmit}
					formClass=""
			>
				<div>
					<div style={{textAlign:'right'}}>
						<a className="button-secondary" href={`https://www.masalledesport.com/salle/${this.context.place.id}`} target="_blank" rel="noopener noreferrer">Voir ma fiche club</a>
					</div>
					<label>
						<span>Nom</span>
						<input type="text" name="name" value={this.state.infos.name} onChange={this.onChange} />
					</label>
					<label>
						<span>Type</span>
						<select name='type' onChange={this.onChange} value={this.state.infos.type}>
							{clubTypes.map((n)=>{
								return <option key={n.id} value={n.id}>{n.name}</option>
							})}
						</select>
					</label>
					<label>
						<span>Reseau</span>
						<select name='franchising' onChange={this.onChange} value={this.state.infos.franchising}>
							<option key='0' value="0">Independant</option>
							{networks.map((n)=>{
								return <option key={n.id} value={n.id}>{n.name}</option>
							})}
						</select>
					</label>
				</div>
			</MagicForm>

		);
	}
}

export default PlaceInfo;
