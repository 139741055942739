import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import MagicForm from '../components/magicForm';
import Loader from '../components/loader';
import Duplicate from '../components/Duplicate';

class Conforts extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		places: PropTypes.array.isRequired,
		applicationDatas : PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = { step : "LOADING", editing: false, form : {} };
	}

	componentDidMount() {
		API.GET(`/user/me/club/${this.context.place.id}/confort`)
		.then(res=>{
			let newState = this.confortsToState(res);
			this.setState(newState);
		})
		.catch(e=>{
			console.error(e, e.stack);
		})
		;
	}

	confortsToState = (conforts) => {
		let state = {
			step : "OK",
			editing : false,
			form : {},
			conforts : conforts
		};
		this.context.applicationDatas.conforts
		.forEach((co)=>{
			let found = conforts.find(s => {
				return s.id == co.id;
			});
			state.form[co.id] = !!found;
		});
		return state;
	};

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = !newState.form[event.target.name];
		this.setState(newState);
	};

	onSubmit = (event) => {
		event.preventDefault();
		let clubId = this.context.place.id;

		let actifs = Object
		.keys(this.state.form)
		.filter((id)=>{
			return this.state.form[id];
		})
		.map((i)=>+i);

		var body = {
			conforts : actifs
		};

		API.PUT(`/user/me/club/${clubId}/confort`, body)
		.then(res => {
			this.setState({editing: false});
		}).catch(e => {
			console.error(e, e.stack);
		})
		;
	};

	updateForPlace = (placeId) => {
		let actifs = Object
		.keys(this.state.form)
		.filter((id)=>{
			return this.state.form[id];
		})
		.map((i)=>+i);

		var body = {
			conforts : actifs
		};

		API.PUT(`/user/me/club/${placeId}/confort`, body)
		.then(res => {
			this.setState({editing: false});
		}).catch(e => {
			console.error(e, e.stack);
		})
		;
	};


	onEdit = (event) => {
		event.preventDefault();
		this.setState({editing:true});
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		this.setState(this.confortsToState(this.state.conforts));
	};

	duplicate = (event) => {
		event.preventDefault();
		this.setState({step:'DUPLICATE'});
	};

	render() {
		if (this.state.step === "LOADING") {
			return <Loader title="Conforts" />
		}

		if(this.state.step === 'DUPLICATE'){
			return (
				<Duplicate
					title="Dupliquer les Conforts"
					places={this.context.places.filter(p=>p.id !== this.context.place.id)}
					updateOne = {this.updateForPlace}
					cancel = {()=> this.setState({step:'OK'})}
					done = {this.reload}
				>
					<div>
						Sélectionnez les clubs pour lesquells vous voulez dupliquer les Conforts
					</div>
				</Duplicate>
			);
		}

		let confortsList = this.context.applicationDatas.conforts.filter(e=>e.selectable);
		let title = "Conforts (" + Object.keys(this.state.form).filter((k)=>this.state.form[k]).length + "/" + confortsList.length+")";
		return (
			<div>
				{!this.state.edit && !!this.context.places.length && <button onClick={this.duplicate} className='button-duplicate'>Dupliquer</button>}
				<MagicForm
					title={title}
					formClass='club-comforts-form'
					editing = {this.state.editing}
					onCancelEdit = {this.onCancelEdit}
					onSubmit = {this.onSubmit}
					onEdit={this.onEdit}
				>
					<section className='club-comforts-content'>
					{confortsList
						.sort( (a,b) => a.name.localeCompare(b.name) )
						.map((c)=>{
						return (
							<label className={this.state.form[c.id] ? "checked":""} key={c.id}>
								<input type="checkbox" name={c.id}
								       checked={this.state.form[c.id]}
								       onChange={this.onChange}
								/>
								<span>{c.name}</span>
							</label>
						)
					})}
					</section>
				</MagicForm>
			</div>
		);
	}
}

export default Conforts;
