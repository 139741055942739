import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import ProductComponent from '../components/product';

class Product extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		applicationDatas : PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);

		this.onChange = this.onChange.bind(this);
		this.toState = this.toState.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onCreate = this.onCreate.bind(this);
		this.getReduc = this.getReduc.bind(this);

		this.state = this.toState(this.props.product);
	}

	getReduc(publicPrice, msdsPrice){
		if(!publicPrice || !msdsPrice){
			return 0;
		}
		return Math.floor(
			((+publicPrice) - (+msdsPrice)) / (+publicPrice) * 100
		);
	}
	onCreate(event){
		event.preventDefault();

		let error = false;
		let newState = Object.assign({}, this.state);

		if(!newState.form.publicPrice.value || +newState.form.publicPrice.value < 0){
			newState.form.publicPrice.invalid = true;
			error = true;
		}
		if(!newState.form.msdsPrice.value || +newState.form.msdsPrice.value < 0){
			newState.form.msdsPrice.invalid = true;
			error = true;
		}

		if(!newState.form.durationValue.value){
			newState.form.durationValue.invalid = true;
			error = true;
		}
		if(!newState.form.durationType.value){
			newState.form.durationType.invalid = true;
			error = true;
		}
		if(this.props.typesWithSubValue.indexOf(newState.form.durationType.value) !== -1 && !newState.form.durationSubValue.value)	{
			newState.form.durationSubValue.invalid = true;
			error = true;
		}
		if(!newState.form.detail.value){
			newState.form.detail.invalid = true;
			error = true;
		}

		if(error){
			this.setState(newState);
			return;
		}
		let clubId = this.context.place.id;

		let data = {
			duration : {
				value : +this.state.form.durationValue.value
				,type : this.state.form.durationType.value
			}
			, publicPrice : +this.state.form.publicPrice.value
			, msdsPrice : +this.state.form.msdsPrice.value
			, detail : this.state.form.detail.value
		};
		if(this.props.typesWithSubValue.indexOf(data.duration.type) !== -1){
			data.duration.subValue = +this.state.form.durationSubValue.value;
		}

		API.POST(`/user/me/club/${clubId}/product`, data)
			.then(()=>{ this.props.reload();})
			.catch(e=>console.error(e, e.stack))
		;
	}
	onDelete(event){
		event.preventDefault();
		let clubId = this.context.place.id;

		let result = window.confirm("Etes-vous sûr de vouloir supprimer cet offre ?");
		if(!result){
			return;
		}
		API.DELETE(`/user/me/club/${clubId}/product/${this.state.id}`)
			.then(()=>{ this.props.reload();})
			.catch(e=>console.error(e, e.stack))
		;
	}

	onCancelEdit(event){
		event.preventDefault();
		this.setState(this.toState(this.props.product));
	}

	toState(product){
		if(!product){
			product = {
				"duration":{
					"value":""
					,"type":""
					, "subValue":""
				}
				,"publicPrice": ""
				, msdsPrice:""
				, "detail":""
			};
		}
		return {
			editing : this.props.isNew || false,
			id : product.id,
			date : product.creation || "",
			form : {
				durationValue : {value:product.duration.value},
				durationType : {value:product.duration.type},
				durationSubValue : {value:product.duration.subValue},
				publicPrice : {value:product.publicPrice},
				msdsPrice : {value: product.msdsPrice},
				detail : {value:product.detail}
			},
			reduc: this.getReduc(product.publicPrice, product.msdsPrice)
		};
	}

	render() {
		let productDescription = this.context.applicationDatas.productDef;

		return (
			<ProductComponent
				typesWithSubValue = {this.props.typesWithSubValue}
				date = {this.state.date}
				editing={this.state.editing}
				onDelete={this.onDelete}
				onSubmit={this.onCreate}
				onChange={this.onChange}
				onCancel ={this.props.onCancelCreate}
				durationValue = {this.state.form.durationValue}
				durationValues = {productDescription.values}
				durationType = {this.state.form.durationType}
				durationTypes = {productDescription.types}
				durationSubValue = {this.state.form.durationSubValue}
				durationSubValues = {productDescription.subValues}
				publicPrice={this.state.form.publicPrice}
				msdsPrice={this.state.form.msdsPrice}
				detail={this.state.form.detail}
				reduc={this.state.reduc}
			/>
		);
	}

	onChange(event){
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = { value : event.target.value };

		if(event.target.name === "publicPrice" || event.target.name === "msdsPrice"){
			newState.form[event.target.name] = { value : event.target.value.trim().replace(/[^0-9]/, "") };
			newState.reduc = this.getReduc(newState.form.publicPrice.value, newState.form.msdsPrice.value);
		}

		this.setState(newState);
	}
}

export default Product;
