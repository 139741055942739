import React from 'react';
import PropTypes from 'prop-types';
import {Route, Redirect,Switch} from 'react-router-dom';
import ClubMenu from "../components/clubMenu";
import MultiDashboard from './MultiDashboard';
import MultiLeadsContainer from './MultiLeadsContainer';
import MultiInvoices from './MultiInvoices';
import MultiReview from './MultiReview';

export default class MultiClubContainer extends React.Component {
	static contextTypes = {
		user: PropTypes.object.isRequired,
		placeSelected: PropTypes.func.isRequired
	};

	componentDidMount() {
		this.context.placeSelected(null);
	}

	render() {
		let links1 = [
			 {name: "Dashboard", url: `${this.props.match.url}/dashboard`}
			,{name: "Factures", url: `${this.props.match.url}/invoices`}
			,{name: "Leads", url: `${this.props.match.url}/lead`}
			,{name: "Avis Client", url: `${this.props.match.url}/review`}
		];

		return (
			<section className='club-container'>
				<nav className="club-menu">
					<h5 className="picto-dashboard">Multi club</h5>
					<ClubMenu links={links1}/>
				</nav>
				<div className="club-content">
					<Switch>
						<Route path = {`${this.props.match.url}/dashboard`} component={MultiDashboard}/>
						<Route path = {`${this.props.match.url}/lead`} component={MultiLeadsContainer} />
						<Route path = {`${this.props.match.url}/invoices`} component={MultiInvoices} />
						<Route path = {`${this.props.match.url}/review`} component={MultiReview} />
						<Route path='*' component={() => <Redirect to={`${this.props.match.url}/dashboard`}/> } />
					</Switch>
				</div>
			</section>
		);
	}
}
