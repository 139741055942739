import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Loader from '../components/loader';
import PlanSelector from '../containers/PlanSelector';
import SepaForm from '../components/sepaForm';
import VendorDesc from '../components/vendorDesc';
import SubscriptionBasket from '../containers/SubscriptionBasket';
import moment from 'moment';

class SubscriptionUpgrade extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step : "LOADING",
			selectedPlan: ""
		};
	}

	componentDidMount(){
		this.reload();
	}

	reload = () => {
		this.setState({step:'LOADING'});
		API.GET(`/user/me/club/${this.context.place.id}/subscription`)
		.then(res=>{
			if(res.plan !== "PREMIUM")
				return this.props.history.replace(`/club/${this.context.place.id}/subscription`);
			this.setState({step: "UPGRADE", subscription:res});
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e,e.stack);
		})
		;
	};

	render() {
		if (this.state.step === "LOADING") {
			return <Loader title="Votre abonnement"/>;
		}

		if (this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>;
		}

		if (this.state.step === "UPGRADE") {
			return <Upgrade selectedPlan={this.state.selectedPlan} sepa={this.state.subscription.sepa} subscription={this.state.subscription}/>;
		}
	}
}

class Upgrade extends React.Component {

	static contextTypes = {
		user: PropTypes.object.isRequired,
		place : PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props);
		this.state = {
			step: "CONFIGURE",
			configureStep: 1,
			plan: null,
			monthly : false,
			price: null
		};

		this.sepaLength = 27;
	}

	componentDidMount() {
		if (this.context.user.isAdmin) {
			return;
		}
		API.POST(`/user/me/club/${this.context.place.id}/notification/onUpgradePage`)
		.catch(e => console.error(e, e.stack))
		;
	}


	selectPlan = (plan, monthly, price) => {
		this.setState({plan, monthly, price, configureStep: 2});
	};

	retry = (event) => {
		event.preventDefault();
		this.setState({step: "CONFIGURE"});
	};

	doUpgrade = () => {
		let selected = this.state.plan + "_";
		if (this.state.monthly) {
			selected += "MENSUEL"
		} else {
			selected += "COMPTANT"
		}
		let data = {plan: selected};

		this.setState({step: "CREATING"});
		API.PUT(`/user/me/club/${this.context.place.id}/subscription`, data)
		.then((res) => {
			this.setState({step: "SUCCESS"});
		})
		.catch((e) => {
			this.setState({step: "ERROR"});
			console.error(e, e.stack);
		})
		;
	};

	nextStep = () => {
		this.setState({
			configureStep: this.state.configureStep + 1
		});
	};


	render() {

		if (this.state.step === "CREATING") {
			return <Loader title="Abonnement" className="msg-page">
				<p>Votre souscription est en cours de validation</p>
				<p>Veuillez ne pas fermer ni recharger cette page</p>
			</Loader>;
		}

		if (this.state.step === "SUCCESS") {
			return (
				<section className="club-subscription-form msg-page">
					<header>
						<h1 className="pageTitle">Abonnement</h1>
					</header>
					<div className="success">
						<h1>Votre souscription a été validée</h1>
						<p>Veuillez rechargez la page afin que tous les changements soient pris en compte</p>
					</div>
				</section>
			);
		}

		if (this.state.step === "ERROR") {
			return (
				<section className="club-subscription-form msg-page">
					<header>
						<h1 className="pageTitle">Abonnement</h1>
					</header>
					<div className="error">
						<h1>Une erreur est survenue</h1>
						<p>Si le problème persiste, n'hésitez pas à nous contacter via "Nous contacter"</p>
						<p>
							<button onClick={this.retry} className="button-primary">Revenir au formulaire</button>
						</p>
					</div>
				</section>
			);
		}

		var refund = null;
		if(this.props.subscription.paymentType === 'COMPTANT'){
			var subscriptionFrom = moment(this.props.subscription.from);
			var subscriptionTo = moment(this.props.subscription.to);
			var subscriptionTotalDays = subscriptionTo.diff(subscriptionFrom, 'days');
			var subscriptionActualDays = moment().diff(subscriptionFrom, 'days');
			var subscriptionRemainingDays = subscriptionTotalDays - subscriptionActualDays;
			refund = Math.floor((subscriptionRemainingDays/subscriptionTotalDays) * 419);
		}

		var planName = `${this.state.plan} - ${this.state.monthly?"Mensuel":"Comptant"}`;
		if(this.state.monthly){
			planName += ' (12 * '+this.state.price+' €HT)';
		}else{
			planName += ' (10% de réduction)';
		}

		return (
			<div className="club-subscription-form">
				<header>
					<h1 className="pageTitle">Abonnement</h1>
				</header>
				<section className="plan-global">
					<div className="plan-selection">
						<h2><span>1</span> Choisissez votre formule</h2>
						<div className="club-subscription-plans">
							<PlanSelector
								actual = {'PREMIUM'}
								plan={this.state.plan}
								monthly = {this.state.monthly}
								reduction={10}
								planSelected={this.selectPlan}
							/>
						</div>
					</div>
					<div className={"plan-payment" + (this.state.configureStep < 2 ? " inactive": "")}>
						<h2 className="pageTitle"><span>2</span> Vérifiez vos coordonnées bancaires</h2>
						<div>
							<SepaForm editing={false}
								sepaIban={{ value:this.props.sepa.iban }}
								sepaOwner={{ value:this.props.sepa.owner }}
								socialReasonName={{ value:this.props.sepa.organization }}
							/>
							<VendorDesc />
						</div>
						<br/>
						{this.state.configureStep === 2 &&
							<button className="button-primary" onClick={this.nextStep}>Valider</button>
						}
					</div>
					<br/><br/>
					<div className={"plan-basket" + (this.state.configureStep < 3 ? " inactive": "")}>
						<h2 className="pageTitle"><span>3</span> Validez votre panier</h2>
						{this.state.configureStep === 3 && <section>
							<SubscriptionBasket valid = {this.doUpgrade}
								label="Valider"
								plan={planName}
								price = {`${this.state.price*(this.state.monthly ? 12 : 1)}`}
								total = {`${this.state.price*(this.state.monthly ? 12 : 1) - refund}`}
								refund = {refund}
							/>
						</section>
						}
					</div>
				</section>
			</div>
		)
	}
}

export default SubscriptionUpgrade;
