import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Actor from '../containers/Actor';
import Loader from '../components/loader';

import whiteAddIcon from '../static/icons/picto-add--white.svg'

class Actors extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.addActor = this.addActor.bind(this);
		this.state ={
			action : "LOADING",
			actors : null
		};
		this.onCancelCreate = this.onCancelCreate.bind(this);
		this.reload = this.reload.bind(this);
	}

	componentDidMount() {
		this.reload();
	}

	reload(){
		this.setState({action : "LOADING"});
		API.GET(`/user/me/club/${this.context.place.id}/actor`)
		.then(res=>{
			this.setState({action:"VIEW", actors:res});
		})
		.catch(e=>console.error(e, e.stack))
		;
	}

	onCancelCreate(){
		this.setState({action:"VIEW"});
	}

	addActor(){
		this.setState({action:"ADD"});
	}

	render() {
		if(this.state.action === "LOADING"){
			return <Loader title="Vos coachs"/>;
		}

		let visibilityAdd = this.state.action === "VIEW" ? "visible" : "hidden";

		return (
			<section className="club-trainers-page">
				<header className="titleAndAdd">
					<h1>Vos coachs</h1>
					<a className="button-add" style={{visibility : visibilityAdd}} onClick={this.addActor}>
						<img src={whiteAddIcon} alt="Ajouter"/>
					</a>
				</header>
				{ this.state.action === "ADD" &&
					<Actor placeId={this.context.place.id} actor={{"fullname":"", "job": "", description:"" }}
						isNew={true}
						onCancelCreate={this.onCancelCreate}
						reload={this.reload}
					/>
				}
				{this.state.actors.length === 0 &&
					this.state.action !== "ADD" &&
					<FillMeAdvice/>
				}
				<ul className="club-trainers-list">
				{
					this.state.actors.map((a)=>{
						return <Actor placeId={this.context.place.id} key={`clubactor_${a.id}`} actor={a} reload={this.reload}/>
					})
				}
				</ul>
			</section>
		)
	}
}

const FillMeAdvice = ()=>{
	return <p className="msdsAdvice">
		N'hésitez pas à ajouter les photos de vos coachs (parcours, diplome etc..)
		pour donner encore plus envie aux internautes de venir dans votre salle.
	</p>;
};


export default Actors;
