import React from 'react';
import PropTypes from 'prop-types';
import Event from '../containers/Event';
import Loader from '../components/loader';
import API from '../utils/api';

import whiteAddEventIcon from '../static/icons/picto-add-event--white.svg';

class Events extends React.Component {

	static contextTypes = {
		place: PropTypes.object
	};

	constructor(props, context){
		super(props, context);
		this.state = {step: "LOADING"};
	}

	componentWillReceiveProps(nextProps) {
		this.setState({step: "VIEW"});
	}

	onCancelCreate = () => {
		this.setState({step: "VIEW"});
	};

	addEvent = () => {
		this.setState({step: "ADD"});
	};

	reload = () => {
		this.setState({step:'LOADING'})
		let clubId = this.context.place.id;
		API.GET(`/user/me/club/${clubId}/event`)
		.then(res=>{
			this.setState({step:'VIEW', events:res})
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e, e.stack);
		})
	};

	onDelete = (eventId) => {
		this.setState({step:'LOADING'})
		let clubId = this.context.place.id;
		API.DELETE(`/user/me/club/${clubId}/event/${eventId}`)
		.then(()=>{
			this.reload();
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e, e.stack);
		})
		;
	};

	onCreate = (values) => {
		this.setState({step:'LOADING'})
		let clubId = this.context.place.id;
		API.POST(`/user/me/club/${clubId}/event`, values)
		.then(()=>{
			this.reload();
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e, e.stack);
		})
		;
	};

	onUpdate = (eventId, values) => {
		this.setState({step:'LOADING'})
		let clubId = this.context.place.id;
		API.PUT(`/user/me/club/${clubId}/event/${eventId}`, values)
		.then(()=>{
			this.reload();
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e, e.stack);
		})
		;
	};

	componentDidMount() {
		this.reload();
	}

	render() {
		if (this.state.step === "LOADING") {
			return <Loader title="Evénements"/>
		}
		if (this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>
		}

		let visibilityAdd = this.state.step === "VIEW" ? "visible" : "hidden";

		return (
			<section className="club-events-page">
				<header>
					<h1>Evénements</h1>
					<button className="button-add" style={{visibility: visibilityAdd}}
					        onClick={this.addEvent}>
						<img src={whiteAddEventIcon} alt="Ajouter"/>
					</button>
				</header>
				<div className="club-events-list">
					{this.state.step === "ADD" &&
					<Event
						event={{"name": "", "type": "", "description": "", "from": null, "to": null}}
						isNew={true}
						onCreate = {this.onCreate}
						onCancelCreate={this.onCancelCreate}
					/>
					}
					{this.state.step === "VIEW" && !this.state.events.length && <FillMeAdvice/>}
					{this.state.events.map((e) => {
						return <Event key={e.id} event={e} onDelete={this.onDelete} onUpdate={this.onUpdate} />
					})}
				</div>
			</section>);
	}
}

const FillMeAdvice = () => {
	return <p className="msdsAdvice">
		Annoncez aux internautes les différents événements organisés dans votre salle
		afin de capter un maximum de nouveaux prospects (ex: Journées Portes Ouvertes, soirée Halloween, ...)
	</p>;
};

export default Events;
