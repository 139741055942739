import React from 'react';
import API from '../utils/api';

import cancelIcon from '../static/icons/picto-cancel.svg';
import loaderImage from '../static/loader.svg';
import successIcon from '../static/icons/success-icon.svg';
import failureIcon from '../static/icons/failure-icon.svg';

class ModerationModal extends React.Component {
	constructor(props) {
		super(props);

		this.submitModeration = this.submitModeration.bind(this);

		this.state = {
			step: "INIT"
		}
	};

	submitModeration(event) {
		event.preventDefault();
		this.setState({
			step: "PENDING"
		});

		let reviewId = this.props.reviewId;

		API.POST(`/review/${reviewId}/moderation`)
		.then(res => {
			this.setState({step:"SUCCESS"});
			this.props.review.status = "MODERATION_ASKED";
			return;
		})
		.catch(e => {
			console.error(e);
			this.setState({step:"ERROR"});
		});
	}

	render() {
		return (
			<div className="modal-mask">
				<div className="modal moderation-modal">
					<header>
						<h2>Demande de modération</h2>
						<img onClick={this.props.closeModal} src={cancelIcon} alt="X"/>
					</header>
					{this.state.step === "INIT" &&
					<div className="modal-content">
						<p>MaSalledeSport.com se réserve le droit de modérer les avis publiés sur le site dans les cas où ils enfreignent les conditions d'utilisation</p>
						<ul className="has-bullets">
							<li>Message à caractère diffamatoire ou sexuel</li>
							<li>Message incitant à la haine</li>
							<li>SPAM et escroqueries</li>
						</ul>
						<p>En cliquant sur Confirmer, vous soumettez cet avis pour modération auprès de notre équipe, qui analysera son contenu et le retirera du site le cas échéant</p>
						<footer>
							<button className="button-primary" data-review-id={this.props.reviewId} onClick={this.submitModeration}>Confirmer</button>
						</footer>
					</div>
					}
					{this.state.step === "PENDING" &&
					<div className="modal-content pending">
						<img src={loaderImage} alt=""/>
						<p>
							Envoi de la demande en cours...<br/>
							Merci de patienter.
						</p>
					</div>
					}
					{this.state.step === "SUCCESS" &&
						<div className="modal-content success">
							<img src={successIcon} alt=""/>
							<p>
								Votre demande a bien été envoyée et sera traitée dans les plus brefs délais.<br/>
							</p>
							<footer>
								<button className="button-primary" onClick={this.props.closeModal}>Fermer</button>
							</footer>
						</div>
					}
					{this.state.step === "ERROR" &&
						<div className="modal-content success">
							<img src={failureIcon} alt=""/>
							<p>
								Une erreur est survenue, veuillez réessayer ultérieurement.<br/>
							</p>
							<footer>
								<button className="button-primary" onClick={this.props.closeModal}>Fermer</button>
							</footer>
						</div>
					}
				</div>
			</div>
		);
	}

}

export default ModerationModal;