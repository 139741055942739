import React from 'react';
import PropTypes from 'prop-types';
import MagicForm from '../components/magicForm';
import API from '../utils/api';

class Formule extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state = this.toState(props.formule);
	}

	toState = (formule) =>{
		return {
			id: formule.id,
			form: {
				name: {value: formule.name}
				, detail: {value: formule.detail}
				, price: {value: formule.price}
				, type: {value: formule.type}
			},
			editing: this.props.isNew || false
		};
	};

	onDelete =(event) => {
		event.preventDefault();
		let clubId = this.context.place.id;
		//TODO better confirm please
		let result = window.confirm("Êtes-vous sûr de vouloir supprimer cette formule ?");
		if (result) {
			API.DELETE(`/user/me/club/${clubId}/formule/${this.state.id}`)
			.then(() => {
				this.props.reload();
			})
			.catch((e) => console.error(e))
			;
		}
	};

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = {value: event.target.value};

		if (event.target.name === "price") {
			let value = event.target.value.replace(/,/g, ".").replace(/[^0-9.]/g, "");
			let lastDot = value.lastIndexOf(".");
			let firstDot = value.indexOf(".");
			if (lastDot !== firstDot) {
				value = value.slice(0, firstDot);
			}
			newState.form[event.target.name].value = value;
		}
		this.setState(newState);
	};

	validate = () => {
		let newState = Object.assign({}, this.state);
		let ok = true;

		["name", "detail", "type"].forEach((type) => {
			if (!this.state.form[type].value) {
				this.state.form[type].invalid = true;
				ok = false;
			}
		});

		if (!ok) {
			this.setState(newState);
		}
		return ok;
	};

	onUpdate = (event) => {
		event.preventDefault();
		let clubId = this.context.place.id;
		let formuleId = this.state.id;

		let form = this.state.form;

		if (!this.validate()) {
			return;
		}

		var body = {
			name: form.name.value,
			type: form.type.value,
			detail: form.detail.value,
			price:  +form.price.value
		};
		API.PUT(`/user/me/club/${clubId}/formule/${formuleId}`, body)
		.then(res => {
			this.setState({editing: false});
		})
		.catch(e => console.error(e))
		;
	};


	onCreate = (event) => {
		event.preventDefault();

		let clubId = this.context.place.id;

		if (!this.validate()) {
			return;
		}

		let form = this.state.form;

		var body = {
			name: form.name.value,
			type: form.type.value,
			detail: form.detail.value,
			price: +form.price.value
		};

		API.POST(`/user/me/club/${clubId}/formule`, body)
		.then(res => {
			this.props.reload();
		})
		.catch(e => console.error(e))
		;
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();

		let {formule} = this.props;
		this.setState(this.toState(formule));
	};

	onEdit = (event) => {
		event.preventDefault();
		this.setState({editing: true});
	};

	render() {
		let types = this.props.types;

		let visibilityPrice = "visible";
		if (!this.state.editing && this.state.form.price.value === 0) {
			visibilityPrice = "hidden";
		}

		return <MagicForm
			title={this.state.form.name.value}
			formClass="club-plans-form"
			editing={this.state.editing}
			onChange={this.onChange}
			onSubmit={this.props.isNew ? this.onCreate : this.onUpdate}
			onCancelEdit={ this.props.isNew ? this.props.onCancelCreate : this.onCancelEdit}
			onEdit={ this.onEdit}
			onDelete={this.onDelete}
		>
			{this.state.editing &&
			<label>
				<input
					type="text" name="name" value={this.state.form.name.value}
					placeholder="Nom de l'offre"
					onChange={this.onChange}
					className={this.state.form.name.invalid ? "inputInvalid" : ""}
				/>
			</label>
			}
			<div style={{"visibility": visibilityPrice}}>
					<span>À partir de </span>
					<input
						type="text" name="price" value={this.state.form.price.value}
						placeholder="prix"
						onChange={this.onChange}
						className={this.state.form.price.invalid ? "inputInvalid" : ""}
						size="8"
					/> €
			</div>

			<label>
				<select
					name="type" value={this.state.form.type.value}
					onChange={this.onChange}
					className={this.state.form.type.invalid ? "inputInvalid" : ""}
				>
					<option value="" disabled>Type d'offre</option>
					{
						types.map(t => {
							return <option key={t.code} value={t.code}>{t.display}</option>
						})
					}
				</select>
			</label>

			<label>
			<textarea
				onChange={this.onChange}
				name="detail"
				value={this.state.form.detail.value}
				className={this.state.form.detail.invalid ? "inputInvalid" : ""}
			/>
			</label>
		</MagicForm>;
	}
}

export default Formule;
