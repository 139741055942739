import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import AboPlan from '../containers/AboPlan';
import Loader from '../components/loader';
import MustUpgrade from '../components/mustUpgrade';

import whiteAddIcon from '../static/icons/picto-add--white.svg';

export default class AboPlans extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);

		this.state ={
			action : "LOADING",
			plans : null
		};

		this.maxPlans = 3;
		this.typesWithSubValue = ['CLASS', 'COACHING'];
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.setState({action : "LOADING"});
		API.GET(`/api/abo/abo/plan?place.id=${this.context.place.id}&noCache=${Date.now()}`, null, {'msds-beta-plan-version' : 2})
		.then(res=>{
			this.setState({action:"VIEW", plans:res});
		})
		.catch(e => {
			if(e.restCode === "mustUpgradePlan"){
				return this.setState({action:"MUST_UPGRADE", requiredPlan : e.requiredPlan});
			}
			console.error(e, e.stack);
			this.setState({action:"ERROR", values: null, pagination: null});
		});
	};

	add = () => {
		this.setState({action:"ADD"});
	};

	onCancelCreate = (event) => {
		event.preventDefault();
		this.setState({action:"VIEW"});
	};

	onDelete = (planId) => {
		this.setState({action:'LOADING'});
		API.DELETE(`/api/abo/abo/plan/${planId}`, null, {'msds-beta-plan-version' : 2})
			.then(()=>{
				this.reload();
			})
			.catch(e=>{
				const msg = e.userMsg || 'Une erreur est survenue';
				this.setState({action:'ERROR', msg});
				console.error(e, e.stack);
			})
		;
	};

	render() {
		if(this.state.action === "LOADING"){
			return <Loader title="Abonnement en ligne"/>;
		}
		if(this.state.action === "MUST_UPGRADE"){
			return <MustUpgrade title="Abonnement en ligne" plan={this.state.requiredPlan} clubId={this.context.place.id}/>
		}

		if(this.state.action === 'ERROR') {
			return (
				<section className="club-plans-content new">
					<p style={{color:"red", textAlign:'center', fontWeigth:'bold', padding:'2rem'}}>{this.state.msg}</p>
					<button onClick={this.reload} className='button-secondary'>Réessayer</button>
				</section>
			);
		}
		return (
			<section className="club-plans-config-page">
				<PlanPage
					action = {this.state.action}
					plans = {this.state.plans}
					maxPlans = {this.maxPlans}
					add = {this.add}
					onCancelCreate = {this.onCancelCreate}
					onDelete={this.onDelete}
					reload = {this.reload}
					typesWithSubValue = {this.typesWithSubValue}
					createPlan={this.onCreatePlan}
				/>
			</section>
		);
	}

}

const PlanPage = props => {
	let visibilityAdd = (props.action === "VIEW" && props.plans.length < props.maxPlans)
		? "visible"
		: "hidden";

	return (
		<section className="club-plans-content new">
			<header className="titleAndAdd">
				<h1>Vos abonnements ({props.plans.length} sur {props.maxPlans})</h1>
				<button className="button-add" style={{visibility : visibilityAdd}} onClick={props.add}>
					<img src={whiteAddIcon} alt="Ajouter"/>
				</button>
			</header>
			<ul>
				{props.plans.map(p =>
				<li key={p.id}>
					<AboPlan plan={p} reload={props.reload} onDelete={props.onDelete} />
				</li>
				)}
				{props.action === "ADD" &&
				<li key='newOne' className='new'>
					<AboPlan plan={null} isNew={true}
						onCancelCreate={props.onCancelCreate}
						reload={props.reload}/>
				</li>
				}
			</ul>
		</section>
	);
};
