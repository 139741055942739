import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import Loader from '../components/loader';
import MagicForm from '../components/magicForm';
import ColorPicker from '../components/ColorPicker';
import MustUpgrade from '../components/mustUpgrade';

import whiteCancelIcon from '../static/icons/picto-cancel--white.svg';

class WidgetConfiguration extends React.Component {

	static contextTypes = {
		user: PropTypes.object.isRequired,
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.state ={
			step : "LOADING",
			widget : null,
			editing:false
		};
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		this.setState({step : "LOADING"});
		let clubId = this.context.place.id;

		return API.GET(`/user/me/club/${clubId}/widget`)
		.then(widget => {
			this.setState({
				step: "OK",
				widget: widget,
				form: Object.assign({}, widget),
				editing:false
			});
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error('[WidgetConfiguration]', "Cannot get WdigetConfiguration", e);
			this.setState({step:"ERROR", error :e});
		})
		;
	};

	onChangeColor = (color, field) => {
		let newState = Object.assign({}, this.state);

		if (field === "headerBgColor") {
			newState.form.style.header.bg = color;
			return this.setState(newState);
		}
		if (field === "headerTxtColor") {
			newState.form.style.header.text = color;
			return this.setState(newState);
		}
		if (field === "buttonBgColor") {
			newState.form.style.button.bg = color;
			return this.setState(newState);
		}
		if (field === "globalBgColor") {
			newState.form.style.global.bg = color;
			return this.setState(newState);
		}
		if (field === "buttonTxtColor") {
			newState.form.style.button.text = color;
			return this.setState(newState);
		}
	};

	onChange = (event) => {
		var name = event.target.name;
		var value = event.target.value;

		let newState = Object.assign({}, this.state);

		if ( name === 'reducedImgUrl') {
			newState.form.reducedImgUrl = value.trim();
			return this.setState(newState);
		}
		if( name === 'askBirthdate' ) {
			newState.form.trial.askBirthdate = !newState.form.trial.askBirthdate;
			return this.setState(newState);
		}
		if( name === 'inviteUrl' ) {
			newState.form.trial.inviteUrl = value;
			return this.setState(newState);
		}
		if( name === 'buttonText' ) {
			newState.form.trial.buttonText = value;
			return this.setState(newState);
		}
		if( name === 'hideOptIn' ) {
			newState.form.trial.hideOptIn = event.target.checked;
			return this.setState(newState);
		}
		if( name === 'handleQuit.enabled' ) {
			if (newState.form.handleQuit) newState.form.handleQuit = null;
			else newState.form.handleQuit = { enabled: true, step: 'try', delay: 1, noSpam: 120 };
			return this.setState(newState);
		}
		if ( name === 'handleQuit.delay' ) {
			newState.form.handleQuit.delay = value;
			return this.setState(newState);
		}
		if ( name === 'handleQuit.noSpam' ) {
			newState.form.handleQuit.noSpam = value;
			return this.setState(newState);
		}
		if ( name === 'handleQuit.step' ) {
			newState.form.handleQuit.step = value;
			return this.setState(newState);
		}

		if( name === 'useConfigDefault.title' ) {
			newState.form.useConfigDefault = newState.form.useConfigDefault || {};
			newState.form.useConfigDefault.title = value;
			return this.setState(newState);
		}
		if( name === 'useConfigDefault.clubName' ) {
			newState.form.useConfigDefault = newState.form.useConfigDefault || {};
			newState.form.useConfigDefault.clubName = value;
			return this.setState(newState);
		}
		if( name === 'useConfigDefault.clubSelectPlaceholder' ) {
			newState.form.useConfigDefault = newState.form.useConfigDefault || {};
			newState.form.useConfigDefault.clubSelectPlaceholder = value;
			return this.setState(newState);
		}

		if( name === 'confirmMessage'){
			newState.form.trial.confirmMessage = value;
			return this.setState(newState);
		}

		if( name === 'confirmImgUrl'){
			newState.form.trial.confirmImgUrl = value.trim();
			return this.setState(newState);
		}

		if( name === 'redirectOnSuccessClose'){
			newState.form.trial.redirectOnSuccessClose = value;
			return this.setState(newState);
		}

		if(name === "auto"){
			if(newState.form.auto) newState.form.auto = null;
			else 				   newState.form.auto = {delay:3, noSpam:60, step:'try'};

			return this.setState(newState);
		}

		if(name === "beta"){
			newState.form.beta = !newState.form.beta;
			return this.setState(newState);
		}

		if(name === "menu"){
			if(newState.form.menu) newState.form.menu = null;
			else 				   newState.form.menu = {try:true, review:true, contact:true, event:true, abo:true, appointment : false};
			return this.setState(newState);
		}

		if(name === "delay"){
			newState.form.auto.delay = +(event.target.value.replace(/[^0-9]/g,''));
			return this.setState(newState);
		}

		if(name === "noSpam"){
			newState.form.auto.noSpam = +(event.target.value.replace(/[^0-9]/g,''));
			return this.setState(newState);
		}

		if(name === "try"){
			newState.form.menu.try = !newState.form.menu.try;
			return this.setState(newState);
		}

		if(name === "review"){
			newState.form.menu.review = !newState.form.menu.review;
			return this.setState(newState);
		}

		if(name === "abo"){
			newState.form.menu.abo = !newState.form.menu.abo;
			return this.setState(newState);
		}

		if(name === "contact"){
			newState.form.menu.contact = !newState.form.menu.contact;
			return this.setState(newState);
		}

		if(name === "event"){
			newState.form.menu.event = !newState.form.menu.event;
			return this.setState(newState);
		}

		if(name === "appointment"){
			newState.form.menu.appointment = !newState.form.menu.appointment;
			return this.setState(newState);
		}
	};

	onEdit = (event) => {
		var name = event.target.name;
		let newState = Object.assign({}, this.state);

		if(name === "activated"){
			newState.form.activated = !newState.form.activated;
			return this.setState(newState);
		}
		if(name === "activated"){
			newState.form.activated = !newState.form.activated;
			return this.setState(newState);
		};
		this.setState({editing:true});
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		this.reload();
	};

	onSubmit = (event) => {
		event.preventDefault();
		let clubId = this.context.place.id;
		return API.PUT(`/user/me/club/${clubId}/widget`, this.state.form)
		.then(res => {
			this.reload();
		})
		.catch(e=>{
			if (e.restCode === "mustUpgradePlan") {
				return this.setState({step: "MUST_UPGRADE", requiredPlan:e.requiredPlan});
			}
			console.error('[WidgetConfiguration]', "Cannot update WidgetConfiguration", e);
			this.setState({step:"ERROR", error :e});
		})
		;
	};

	trialFormChange = (type, event) => {
		var newState = Object.assign({}, this.state);
		newState.form.trial.form.type = type;
		return this.setState(newState);
	};

	trialFormChangeValue = (event) => {
		var newState = Object.assign({}, this.state);
		newState.form.trial.form[event.target.name] = event.target.value;
		return this.setState(newState);
	};

	render() {
		if(this.state.step === "LOADING"){
			return <Loader title="Widget"/>;
		}
		if (this.state.step === "MUST_UPGRADE") {
			return <MustUpgrade title={this.props.title} plan={this.state.requiredPlan} clubId={this.context.place.id}/>
		}
		if (this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>
		}

		const logoStyle = {
			transform: 'rotate(360deg)',
			transition: 'transform 0.15s cubic-bezier(0.4, 0, 1, 1), background-image 0.15s',
			backgroundSize: '50%',
			backgroundPosition: 'center',
			backgroundRepeat: 'no-repeat',
			boxShadow: 'none',
			width:'80%',
			heigth:'80%',
			textAlign:'center',
		};

		const logoDivStyle = {
			display:'flex',
			justifyContent:'center',
			borderRadius: '60px',
			width: '60px',
			height: '60px',color:this.state.form.style.button.text,
			backgroundColor:this.state.form.style.button.bg,
		};

		return (
			<MagicForm
					editing={this.state.editing}
					onEdit={this.onEdit}
					title="Configuration du widget"
					onCancelEdit={this.onCancelEdit}
					onSubmit={this.onSubmit}
					formClass="widget-settings-page"
			>
				<div style={{justifyContent:'space-between', margin:"2rem"}}>
					<section>
						<label className="checkbox">
							<input type="checkbox" name="auto" checked={!!this.state.form.auto} onChange={this.onChange}/>
							<h3>Ouverture automatique</h3>
						</label>
						{this.state.form.auto &&
							<label>
								<span>Délai avant ouverture</span>
								<input type="text" name="delay" value = {this.state.form.auto.delay} onChange={this.onChange}/>
							</label>
						}
						{this.state.form.auto &&
							<label>
								<span>Délai avant ré-ouverture</span>
								<input type="text" name="noSpam" value = {this.state.form.auto.noSpam} onChange={this.onChange}/>
							</label>
						}
					</section>
					<section>
						<label className="checkbox">
							<input type="checkbox" name="handleQuit.enabled" checked={!!this.state.form.handleQuit} onChange={this.onChange}/>
							<h3>Pop-Up lors de la sortie de page</h3>
						</label>
						{this.state.form.handleQuit &&
							<label>
								<span>Délai d'ouverture (secondes)</span>
								<input type="number" name="handleQuit.delay" value = {this.state.form.handleQuit.delay} onChange={this.onChange}/>
							</label>
						}
						{this.state.form.handleQuit &&
							<label>
								<span>Délai avant ré-ouverture (secondes)</span>
								<input type="number" name="handleQuit.noSpam" value = {this.state.form.handleQuit.noSpam} onChange={this.onChange}/>
							</label>
						}
						{this.state.form.handleQuit &&
							<label>
								<span>Mode du widget</span>
								<select name={'handleQuit.step'} value={this.state.form.handleQuit.step} onChange={this.onChange}>
									<option value={'try'}>Demande d'essai</option>
									<option value={'contact'}>Demande de contact</option>
									<option value={'review'}>Laisser un avis</option>
									<option value={'event'}>En ce moment</option>
								</select>
							</label>
						}
					</section>
					<section>
						<label className="checkbox">
							<input type="checkbox" name="menu" checked={this.state.form.menu} onChange={this.onChange}/>
							<h3>Menu (en bas à droite)</h3>
						</label>
						{this.state.form.menu &&
							<label className="checkbox">
								<input type="checkbox" name="try" checked = {this.state.form.menu.try} onChange={this.onChange}/>
								<span>Séance d'essai</span>
							</label>
						}
						{this.state.form.menu &&
							<label className="checkbox">
								<input type="checkbox" name="review" checked = {this.state.form.menu.review} onChange={this.onChange}/>
								<span>Laisser un avis</span>
							</label>
						}
						{this.state.form.menu &&
							<label className="checkbox">
								<input type="checkbox" name="abo" checked = {this.state.form.menu.abo} onChange={this.onChange}/>
								<span>Abonnement</span>
							</label>
						}
						{this.state.form.menu &&
							<label className="checkbox">
								<input type="checkbox" name="contact" checked = {this.state.form.menu.contact} onChange={this.onChange}/>
								<span>Contactez-nous</span>
							</label>
						}
						{this.state.form.menu &&
							<label className="checkbox">
								<input type="checkbox" name="event" checked = {this.state.form.menu.event} onChange={this.onChange}/>
								<span>En ce moment</span>
							</label>
						}
						{this.state.form.menu &&
							<label className="checkbox">
								<input type="checkbox" name="appointment" checked = {this.state.form.menu.appointment} onChange={this.onChange}/>
								<span>Prendre un rendez-vous</span>
							</label>
						}
					</section>
					<section>
						<h3>Logo</h3>
						<br/>
						{this.state.editing && <input type="text" name="reducedImgUrl" value={this.state.form.reducedImgUrl} onChange={this.onChange}/>}
						{!this.state.editing && (
							<div style={logoDivStyle}>
								<img style={logoStyle} src={this.state.form.reducedImgUrl} alt="logo"/>
							</div>
						)}
					</section>
					<section>
						<h3>Couleurs personnalisées</h3>
						<div>
							<strong>Barre de titre</strong>
							<label className="checkbox">
								<ColorPicker name="headerBgColor" color={this.state.form.style.header.bg} disabled={!this.state.editing} onChangeColor={this.onChangeColor}/>
								<span>Couleur de fond</span>
							</label>
							<label className="checkbox">
								<ColorPicker name="headerTxtColor" color={this.state.form.style.header.text} disabled={!this.state.editing} onChangeColor={this.onChangeColor}/>
								<span>Couleur du texte</span>
							</label>
							<strong>Boutons</strong>
							<label className="checkbox">
								<ColorPicker name="buttonBgColor" color={this.state.form.style.button.bg} disabled={!this.state.editing} onChangeColor={this.onChangeColor}/>
								<span>Couleur de fond</span>
							</label>
							<label className="checkbox">
								<ColorPicker name="buttonTxtColor" color={this.state.form.style.button.text} disabled={!this.state.editing} onChangeColor={this.onChangeColor}/>
								<span>Couleur du texte</span>
							</label>
							<strong>Global</strong>
							<label className="checkbox">
								<ColorPicker name="globalBgColor" color={this.state.form.style.global.bg} disabled={!this.state.editing} onChangeColor={this.onChangeColor}/>
								<span>Couleur de fond</span>
							</label>
						</div>
						<aside style={{backgroundColor:this.state.form.style.global.bg}}>
							<header style={{backgroundColor:this.state.form.style.header.bg, color:this.state.form.style.header.text}}>
								<h2>Barre de titre</h2>
								<img src={whiteCancelIcon} alt="Fermer"/>
							</header>
							<div>
								<button style={{backgroundColor:this.state.form.style.button.bg, color:this.state.form.style.button.text}}>Bouton</button>
							</div>
						</aside>
					</section>
				</div>

				<br/>
				<div style={{flexDirection : 'column'}}>
					<h3>Formulaire découverte</h3>
					<br/>
					<section style={{display:'flex',marginLeft:'3rem'}}>
						<section>
							<strong>A côté du formulaire</strong>
							<br/>
							<div>
								<label className="checkbox">
									<input type="radio" name="text" checked={this.state.form.trial.form.type === 'text'} onChange={this.trialFormChange.bind(this, 'text')}/>
									<span>Texte et horaire {this.state.form.trial.form.type}</span>
								</label>
								{this.state.form.trial.form.type === 'text' &&
									<textarea cols='100' rows='5' name='text' value={this.state.form.trial.form.text} onChange={this.trialFormChangeValue}/>
								}
								<label className="checkbox">
									<input type="radio" name="image" checked={this.state.form.trial.form.type === 'image'} onChange={this.trialFormChange.bind(this, 'image')}/>
									<span>Image personnalisée</span>
								</label>
								{this.state.form.trial.form.type === 'image' && this.state.editing &&
									<input type = 'text' size='100' name = 'image' placeholder="url de l'image" value = {this.state.form.trial.form.image} onChange={this.trialFormChangeValue}/>
								}
								{this.state.form.trial.form.type === 'image' && !this.state.editing &&
									<img src={this.state.form.trial.form.image}/>
								}
								{this.context.user.isAdmin && (
									<label style={{display:'flex', flexDirection:'row'}}>
										<input type='checkbox' name='askBirthdate' checked={!!this.state.form.trial.askBirthdate} onChange={this.onChange}/>
										<span>Demander la date de naissance (admin only)</span>
									</label>
								)}
								{this.context.user.isAdmin && (
									<label style={{display:'flex', flexDirection:'column'}}>
										<span>Lien présent dans l'email d'invitation (admin only)</span>
										<input type='text' name='inviteUrl' value={this.state.form.trial.inviteUrl} onChange={this.onChange}/>
									</label>
								)}
								{this.context.user.isAdmin && (
									<label style={{display:'flex', flexDirection:'column'}}>
										<span>Texte du boutton (admin only)</span>
										<input type='text' name='buttonText' value={this.state.form.trial.buttonText} onChange={this.onChange}/>
									</label>
								)}
								{this.context.user.isAdmin && (
									<label style={{display:'flex', flexDirection:'row'}}>
										<input type="checkbox" name="hideOptIn" checked={!!this.state.form.trial.hideOptIn} onChange={this.onChange}/>
										<span>Ne pas afficher l'opt-in (admin only)</span>
									</label>
								)}
								{this.context.user.isAdmin && (
									<>
										<label style={{display:'flex', flexDirection:'column'}}>
											<span>Titre du widget (admin only)</span>
											<input type='text' name='useConfigDefault.title' value={this.state.form.useConfigDefault?.title || ""} onChange={this.onChange}/>
										</label>
										<label style={{display:'flex', flexDirection:'column'}}>
											<span>Nom du club par défaut (admin only)</span>
											<input type='text' name='useConfigDefault.clubName' value={this.state.form.useConfigDefault?.clubName || ""} onChange={this.onChange}/>
										</label>
										<label style={{display:'flex', flexDirection:'column'}}>
											<span>Placeholder du select (multi club) (admin only)</span>
											<input type='text' name='useConfigDefault.clubSelectPlaceholder' value={this.state.form.useConfigDefault?.clubSelectPlaceholder || ""} onChange={this.onChange}/>
										</label>
									</>
								)}
							</div>
						</section>
						<section>
							<strong>Ecran de confirmation</strong>
							<br/>
							<div>
								<label>
									<span>Texte</span>
									<textarea rows='10' cols='100' name='confirmMessage' value={this.state.form.trial.confirmMessage} onChange={this.onChange}></textarea>
								</label>
								<label>
									<span>Image</span>
									{this.state.editing && <input type='text' name='confirmImgUrl' value={this.state.form.trial.confirmImgUrl} onChange={this.onChange}/>}
									{!this.state.editing && <img width="100" src ={this.state.form.trial.confirmImgUrl} /> }
								</label>
							</div>
						</section>

					</section>
				</div>

				<br/>
				<br/>

				<div style={{flexDirection : 'column'}}>
					<h3>Redirection après validation de la séance découverte</h3>
					<br/>
					<section>
						<label>
							<span>Url de redirection (laissez vide pour une simple fermeture du widget)</span>
							<input type = 'text' size='100' name = 'redirectOnSuccessClose' placeholder="url de redirection" value = {this.state.form.trial.redirectOnSuccessClose ||''} onChange={this.onChange}/>
						</label>
					</section>
				</div>
			</MagicForm>
		)
	}
}

export default WidgetConfiguration;
