import React from 'react';
import PropTypes from 'prop-types';
import ExternalPageOAuthConfigurator from '../containers/ExternalPageOAuthConfigurator';

import googleLogo from '../static/logos/google.svg';

class GoogleOAuthConfigurator extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render(){
		let clubId = this.context.place.id;

		let state = encodeURIComponent(btoa(clubId));
		let redirectUrl =`https://accounts.google.com/o/oauth2/v2/auth`
				+ `?client_id=${process.env.REACT_APP_GOOGLE_APP_CLIENT_ID}`
				+ `&redirect_uri=${process.env.REACT_APP_THIS_URL}/oauth/google`
				+ `&response_type=token`
				+ `&scope=https://www.googleapis.com/auth/plus.business.manage`
				+ `&state=${state}`
		;

		let external = {
			name : "googleMyBusiness",
			display :"Google",
			className:"googleMyBusiness-configurator",
			icon: {
				alt: "Google",
				url: googleLogo
			},
			connectionIcon: {
				alt: "Google",
				url: googleLogo
			},
			getRedirectUrl : (state)=> {return redirectUrl;}
		};
		return <ExternalPageOAuthConfigurator clubId = {clubId} external = {external}/>;
	}
}

export default GoogleOAuthConfigurator;

