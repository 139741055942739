export const days = [
	{
		enum: 'MONDAY',
		display: 'Lundi'
	}, {
		enum: 'TUESDAY',
		display: 'Mardi'
	}, {
		enum: 'WEDNESDAY',
		display: 'Mercredi'
	}, {
		enum: 'THURSDAY',
		display: 'Jeudi'
	}, {
		enum: 'FRIDAY',
		display: 'Vendredi'
	}, {
		enum: 'SATURDAY',
		display: 'Samedi'
	}, {
		enum: 'SUNDAY',
		display: 'Dimanche'
	}
];


function toTimeToStr(h,m){
	let hString = h;
	if(h < 10){
		hString="0"+h;
	}
	let mString = m;
	if( m < 10){
		mString="0"+m;
	}
	return hString + ":" + mString;
}

export function generateScheduleList(from, to){
	let res = [];
	res.push("");
	let h=from; let m=0;

	while( !( h===to && m===15) ){
		res.push(toTimeToStr(h,m));
		m+=15;
		if(m%60===0){
			h++;
			m=0;
		}
	}
	return res;
}
