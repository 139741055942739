import React, { useContext } from 'react';
import UserAvatar from '../containers/UserAvatar';
import ClubChooser from '../containers/ClubChooser';

import logo from '../static/logos/logo.svg';

import { AppVersionContext } from '../react_contexts';

export default function HeaderContainer() {
	const appVersion = useContext(AppVersionContext);

	return (
		<header className="mainHeader">
			<div className="header-group">
				<h1 className="logo">
					<img src={logo} alt="MSDS" />
					<span>
						Ma Salle De Sport
						<em>Pro</em>
						<span>{appVersion}</span>
					</span>
				</h1>
				<ClubChooser />
			</div>
			<UserAvatar />
		</header>
	);
}
