import React from 'react';
import PropTypes from 'prop-types';
import MagicForm from '../components/magicForm';

class ClubAdress extends React.Component {

	static contextTypes = {
		user: PropTypes.object.isRequired,
		place: PropTypes.object.isRequired,
		placeUpdate : PropTypes.func.isRequired,
	};

	constructor(props, context) {
		super(props, context);
		let adress = this.context.place.adress;
		this.state = this.adressToState(adress);
	}

	componentDidMount(){
		if(window.google){
			this.geocoder = new window.google.maps.Geocoder();
		}else{
			console.error("Google not ready");
		}

		let lat = this.context.place.adress.gps.latitude;
		let lng = this.context.place.adress.gps.longitude;
		if(!lat || !lng){
			return;
		}
		this.updateMap(lat, lng);

	}


	render() {
		return (
			<ClubAdressSimple
				editing = {this.state.editing}
				onSubmit = {this.onSubmit}
				onChange = {this.onChange}
				onCancelEdit = {this.onCancelEdit}
				onEdit = {this.context.user.isAdmin && this.onEdit}
				street = {this.state.form.street}
				streetBis = {this.state.form.streetBis}
				zipCode = {this.state.form.zipCode}
				city = {this.state.form.city}
				latitude = {this.state.form.latitude}
				longitude = {this.state.form.longitude}
				geoLocByGoogle = {this.state.geoLocByGoogle}
				canByPassGoogle = {this.context.user.isAdmin}
				/>
		);
	}

	onEdit = (event) => {
		event.preventDefault();
		this.setState({editing:true});
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		let adress = this.context.place.adress;
		this.setState(this.adressToState(adress));
	};

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		if(event.target.name === 'geoLocByGoogle'){
			newState.geoLocByGoogle= !newState.geoLocByGoogle;
		}else {
			newState.form[event.target.name] = { value: event.target.value};
		}
		this.setState(newState);
	};


	updateMap = (lat, lng) => {
		if(!window.google){
			console.error("Google not ready");
			return;
		}
		let zoom = 14;
		const mapConfig = Object.assign({}, {
			center: {lat,lng},
			zoom: zoom
		});

		const map = new window.google.maps.Map(document.getElementById('gMap'), mapConfig);

		new window.google.maps.Marker({
			position: {lat,lng},
			map: map
		});
	};

	adressToState = (adress, coordonnes) => {
		return {
			geoLocByGoogle :true,
			form : {
				street : { value : adress.street },
				streetBis : { value : adress.streetBis },
				zipCode : { value : adress.zipCode },
				city : { value : adress.city },
				latitude : { value : adress.gps.latitude  },
				longitude : { value : adress.gps.longitude  }
			},
			editing : false
		};
	};

	onSubmit = (event) => {
		event.preventDefault();

		let formValues = {
			adress : {
				street : this.state.form.street.value,
				streetBis : this.state.form.streetBis.value,
				zipCode : this.state.form.zipCode.value,
				city : this.state.form.city.value,
				gps : {
					latitude : this.state.form.latitude.value,
					longitude :  this.state.form.longitude.value
				}
			}
		};

		let fullAddress = "";
		fullAddress += formValues.adress.street;
		fullAddress += " " + (formValues.adress.streetBis || "");
		fullAddress += " " + formValues.adress.zipCode;
		fullAddress += " " + formValues.adress.city;

		let setState = this.setState.bind(this);

		let updateMap = this.updateMap.bind(this);

		if(!this.state.geoLocByGoogle){
			this.context.placeUpdate(formValues)
			.then(reponse =>{
				setState({editing:false});
				updateMap(+formValues.adress.gps.latitude, +formValues.adress.gps.longitude);
			})
			.catch(e => {
				console.error(e, e.stack);
			});
			return;
		}

		this.geocoder.geocode({ 'address' : fullAddress}, (results, status) => {
			if (status !== window.google.maps.GeocoderStatus.OK || !results.length) {
				alert("Nous ne pouvons pas géolocaliser votre adresse:"+fullAddress);
				return;
			}

			results[0].address_components.forEach((c)=>{
				if(c.types.indexOf("locality")!==-1)
					formValues.adress.city= c.long_name;
				if(c.types.indexOf("postal_code")!==-1)
					formValues.adress.zipCode= c.long_name;

			});

			formValues.adress.gps = {
				latitude : results[0].geometry.location.lat(),
				longitude :  results[0].geometry.location.lng()
			};

			this.context.placeUpdate(formValues)
			.then(reponse =>{
				setState({editing:false});
				updateMap(+formValues.adress.gps.latitude, +formValues.adress.gps.longitude);
			})
			.catch(e => {
				console.error(e, e.stack);
			});
		});
	};
}

export default ClubAdress;


const ClubAdressSimple = (props) => {

	return (
		<MagicForm
			title = "Adresse"
			formClass = 'club-address-form'
			editing = {props.editing}
			onCancelEdit = {props.onCancelEdit}
			onSubmit = {props.onSubmit}
			onEdit = {props.onEdit}
		>
			<section>
				<label className="club-address-inputs">
					<span className="picto-address svg">Adresse</span>
					<input type="text" name="street" placeholder="Adresse" value={props.street.value}
						   onChange={props.onChange}/>
					<input type="text"
						   name="streetBis" placeholder="Adresse - suite (facultatif)" value={props.streetBis.value}
						   onChange={props.onChange}/>
					<input size="7" type="text" name="zipCode" placeholder="codePostal" value={props.zipCode.value}
						   onChange={props.onChange}/>
					<input type="text" name="city" placeholder="Ville" value={props.city.value}
						   onChange={props.onChange}/>

					<br/>

					<span className="picto-address svg">Géoloc</span>

					{props.editing && props.canByPassGoogle && <label style={{display:'flex', flexDirection:'row'}}>
						<input type='checkbox' name='geoLocByGoogle' checked={props.geoLocByGoogle} onChange={props.onChange} />
						<span>Use google</span>
					</label>
					}

					{ ( (props.editing && !props.geoLocByGoogle) || !props.editing) &&
						<input type="text" name="latitude" placeholder="Latitude" value={props.latitude.value}
							   onChange={props.onChange}/>
					}
					{ ( (props.editing && !props.geoLocByGoogle) || !props.editing) &&
						<input type="text" name="longitude" placeholder="Longitude" value={props.longitude.value}
							   onChange={props.onChange}/>
					}
				</label>
				<div id='gMap' className='gmap'>Loading map...</div>
			</section>
		</MagicForm>
	);
};
