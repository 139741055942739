import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import SuperComponent from '../containers/SuperComponent';
import Source from '../components/source';
import queryString from 'query-string';
import moment from 'moment';

import cancelIcon from '../static/icons/picto-cancel.svg';

class Contact extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);
		this.doAction = this.doAction.bind(this);
		this.state =  {
			page: queryString.parse(this.props.location.search).page || 1,
			display:null
		};
	}

	componentWillReceiveProps(nextProps, oldsProps){
		if(nextProps.location.search && queryString.parse(nextProps.location.search).page){
			if(this.state.page !== queryString.parse(nextProps.location.search).page){
				this.setState({page:queryString.parse(nextProps.location.search).page});
			}
		}
	}

	doAction(page){
		return API.GET(`/user/me/club/${this.context.place.id}/contact?page=${page}`);
	}

	show = (contact, e) => {
		e.preventDefault();
		this.setState({display:contact});
	};

	hide = (e) => {
		e.preventDefault();
		this.setState({display:null});
	};

	render() {
		let clubId = this.context.place.id;
		return (
			<div>
				<SuperComponent
					clubId={clubId}
					title="Demandes d'informations"
					page={this.state.page}
					specificClassName="contacts-page"
					doAction ={this.doAction}
					pagination={{baseUrl : `${this.props.match.url}?`}}
				>
					<SimpleContainer show = {this.show}/>
				</SuperComponent>
				{this.state.display &&
				<div className='modal-mask'>
					<div className='modal contacts-show'>
						<header>
							<h2>{this.state.display.firstname} {this.state.display.lastname}</h2>
							<img src={cancelIcon} alt="X" onClick={this.hide}/>
						</header>
						<div className="modal-content">
							<span>Le {moment(this.state.display.date).format("DD/MM/YYYY")}</span>
							{(this.state.display.email || this.state.display.phone) &&
							<span>
								<strong>Contact :</strong>
								{this.state.display.email &&
								<a href={`mailto:${this.state.display.email}`}>{this.state.display.email}</a>
								}
								{this.state.display.phone &&
								<a href={`tel:${this.state.display.phone}`}>{this.state.display.phone}</a>
								}
							</span>
							}
							<p>
							{this.state.display.message}
							</p>
						</div>
					</div>
				</div>
				}
			</div>
		);
	}
}


const SimpleContainer = (props) => {
	let contacts = props.values;
	if( contacts.length === 0 ){
		return <p className="msdsAdvice">
			Vous n'avez pas encore reçu de demandes d'information
		</p>;
	}
	return (
		<section>
			<table className="contacts">
			<thead>
				<tr>
					<th>Source</th>
					<th>Date</th>
					<th>Nom, Prénom</th>
					<th>Téléphone</th>
					<th>Email</th>
					<th>Voir</th>
				</tr>
			</thead>
			<tbody>
			{
				contacts.map((e)=>{
					return <ContactLine contact={e} key={`contact_${e.id}`} show={props.show}/>;
				})
			}
			</tbody>
			</table>
		</section>
	);
};

function convertCivilite(civilite){
	switch(civilite){
		case "MADAME":
			return "Mme";
		case "MONSIEUR":
			return "M.";
		case "MADEMOISELLE":
			return "Mlle.";
		default:
			//TODO do a warn
		return ";"
	}
}

function ContactLine(props){
	return (
		<tr>
			<td><Source source={props.contact.source}/></td>
			<td>{moment(props.contact.date).format("DD/MM/YYYY")}</td>
			<td>{convertCivilite(props.contact.civilite)} {props.contact.lastname} {props.contact.firstname}</td>
			<td>{props.contact.phone}</td>
			<td>{props.contact.email}</td>
			<td><button onClick={props.show.bind(null, props.contact)}>Lire</button></td>
		</tr>
	);
}
export default Contact;
