import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import {Link} from 'react-router-dom';
import Loader from '../components/loader';

import downloadIcon from '../static/icons/picto-download.svg';

class Planning extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.onSubmit = this.onSubmit.bind(this);
		this.onChange = this.onChange.bind(this);
		this.reload = this.reload.bind(this);
		this.deletePlanning = this.deletePlanning.bind(this);

		this.state = {
			step: "LOADING"
			, file: null
		};
	}

	reload() {
		this.setState({step: "LOADING"});

		API.GET(`/user/me/club/${this.context.place.id}}/planing`)
		.then(res => {
			this.setState({ step: "OK", plannings: res });
		})
		.catch(e => {
			this.setState({ step: "ERROR", error: e });
			console.error(e, e.stack);
		})
		;
	}

	componentDidMount() {
		this.reload();
	}

	deletePlanning(event) {
		event.preventDefault();
		API.DELETE(`/user/me/club/${this.context.place.id}/planing`)
		.then( res => {
		 	this.reload();
		})
		.catch(e => {
			console.error(e, e.stack);
			this.reload();
		})
		;
	}
	onSubmit(event) {
		event && event.preventDefault();
		this.setState({step: "UPLOADING"});

		const reader = new FileReader();

		reader.onloadend = () => {

			var body = {
				type: this.state.file.type
				, name: this.state.file.name
				, content: reader.result
			};

			API.POST(`/user/me/club/${this.context.place.id}/planing`, body)
			.then( res => {
			 	this.reload();
			})
			.catch(e => {
				console.error(e, e.stack);
				this.reload();
			})
			;
		};
		reader.readAsDataURL(this.state.file);
	}

	onChange(event) {
		this.setState({file: event.target.files[0]}, this.onSubmit);
	}

	render() {
		if (this.state.step === "LOADING") {
			return <div className="club-planning-page">
				<Loader title="Planning"/>
			</div>;
		}
		if (this.state.step === "UPLOADING") {
			return <div className="club-planning-page">
				<Loader title="Planning > uploading"/>
			</div>;
		}

		return (
			<div className="club-planning-form">
				<h1>Planning</h1>
				<form onSubmit={this.onSubmit} className="club-planning-content">
					{this.state.plannings.length === 1 &&
					<p className="club-planning-current">Planning actuel :&nbsp;
						<Link download={this.state.plannings[0].name} target="_blank" to={this.state.plannings[0].url}>
							{this.state.plannings[0].name}&nbsp;
							<img src={downloadIcon} alt="Télécharger"/>
						</Link>
					</p>
					}
					<div style={{display:'flex'}}>
						<label className="club-planning-upload">
							<span className="button-primary">
								{this.state.plannings.length === 0 ? "Ajouter un planning" : "Changer de planning"}
							</span>
							<input type="file" onChange={this.onChange}/>
						</label>
						{this.state.plannings.length !== 0 && 
							<button className='button-secondary' onClick={this.deletePlanning}>Supprimer le planning</button>
						}
					</div>
				</form>
			</div>
		);
	}
}

export default Planning;
