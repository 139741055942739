import React from 'react';
import moment from 'moment';

import { Chart } from 'react-google-charts';

var options={
	title: "Répartition des notes sur les 12 derniers mois",
	colors: [
		"red",
		"orange",
		"green"
	],
	legend: 'top',
	isStacked: true,
	chartArea: {
		left: '5%',
		width: '95%',
		height: '75%'
	},
	vAxis: {
		gridlines: { count: 0},
		viewWindow: {
			min: 0
		}
	}
};

class ReviewsChart extends React.Component {

	constructor(props) {
		super(props);
		this.compute = this.compute.bind(this);
		this.initDateArray = this.initDateArray.bind(this);

		this.state = {
			step: "LOADING",
			reviews: []
		};
	}

	componentDidMount() {
		if (this.props.reviews.length === true) {
			this.setState({
				step: "ERROR"
			})
		}
		this.setState({
			step:"OK",
			reviews:this.compute(this.props.reviews)
		});
	}

	initDateArray() {
		let res = {};
		let today = moment();

		for (let i = 0; i < 12; i++) {
			res[today.format("MM/YY")] = {
				high: 0,
				mid: 0,
				low: 0
			};
			today.add(-1, "months");
		}
		return res;
	}

	compute(reviews) {
		let res = this.initDateArray();
		let computed = [];

		reviews
		.filter(r => r.status === "OK")
		.filter(r => !r.hidden)
		.forEach(r => {
			let rDate = moment(r.date).format("MM/YY");
			if (!res[rDate]) return;
			let rate = r.marks.average;

			if (rate <= 5 && rate > 3) {
				res[rDate].high++;
			} else if (rate < 3) {
				res[rDate].low++;
			} else {
				res[rDate].mid++;
			}
		});

		computed.push(["Date", "1-2 ★", "3 ★", "4-5 ★"]);
		Object.keys(res).reverse().forEach(p => {
			let line = [p, res[p].low, res[p].mid, res[p].high];
			computed.push(line);
		});
		return computed;
	}

	render() {
		if (this.state.step === "LOADING") {
			return <p>LOADING</p>
		}
		if (this.state.step === "ERROR") {
			return <p>ERROR</p>
		}
		return (
			<Chart
				chartType="ColumnChart"
				data={this.state.reviews}
				width="100%"
				options={options}
			/>
		);
	}
}

export default ReviewsChart;