import React from 'react';
import UserAvatarComponent from '../components/userAvatar';

class UserAvatar extends React.Component {
	render() {
		return (
			<UserAvatarComponent />
		);
	}
}

export default UserAvatar;
