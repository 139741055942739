import React from 'react';
import PropTypes from 'prop-types';
import ScheduleDay from '../containers/ScheduleDay';
import MagicForm from '../components/magicForm';
import { days,generateScheduleList } from '../utils/days.js';
import Loader from '../components/loader';
import API from '../utils/api';

import infoIcon from '../static/icons/picto-info.svg';

class Schedule extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);

		this.hoursList = generateScheduleList(0,24);
		this.scheduleDays = [];

		this.state = { step:'LOADING', editing:false };
	}

	componentDidMount() {
		this.reload();
	}

	reload = ()=> {
		this.setState({step:'LOADING'})
		let clubId = this.context.place.id;
		API.GET(`/user/me/club/${clubId}/${this.props.endpoint}`)
		.then(res=>{
			this.setState({step:'OK', editing:false, schedule:res})
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e, e.stack);
		})
		;
	};

	onEdit = (event) => {
		event.preventDefault();
		this.setState({editing:true});
	};

	onCancelEdit = (event) => {
		event.preventDefault();
		this.setState({editing:false});
	};

	onSubmit = (event) => {
		event.preventDefault();
		event.stopPropagation();
		let clubId = this.context.place.id;
		let json = this.stateToJson();
		this.setState({step:'LOADING'});

		API.PUT(`/user/me/club/${clubId}/${this.props.endpoint}`, json)
		.then(()=>{
			this.reload();
		})
		.catch(e=>{
			this.setState({step:'ERROR'});
			console.error(e, e.stack);
		})
		;
	};

	stateToJson = () => {
		let json = {};
		Object.keys(this.scheduleDays).forEach((k)=>{
			if(!this.scheduleDays[k]){
				return;
			}
			json[k] = this.scheduleDays[k].stateToJson();
			if(Object.keys(json[k]).length === 0){
				delete json[k];
			}
		});
		return json;
	};

	render() {
		if(this.state.step === 'LOADING'){
			return <Loader title="Horaires d'ouvertures" />;
		}

		if(this.state.step === 'ERROR'){
			return <p>Une erreur est survenue</p>;
		}

		this.scheduleDays = {};

		let tempTable = days.map((day)=>{
			return <ScheduleDay key={`schedule-${day.enum}`} schedule={this.state.schedule[day.enum]}
				ref={(r)=>{this.scheduleDays[day.enum]=r}}
				dayName ={day.display}
				hoursList={this.hoursList} />
		});

		return (
			<MagicForm
					title={this.props.title}
					formClass="club-schedule-page"
					editing = {this.state.editing}
					onCancelEdit = {this.onCancelEdit}
					onSubmit = {this.onSubmit}
					onEdit={this.onEdit}
					onDelete = {this.onDelete}
					>
				<div >
					<table>
						<thead>
							<tr>
								<th></th>
								<th colSpan="2">Créneau 1</th>
								<th colSpan="2">Créneau 2</th>
							</tr>
							<tr>
								<th>Jour</th>
								<th>de</th>
								<th>à</th>
								<th>de</th>
								<th>à</th>
							</tr>
						</thead>
						<tbody>
							{tempTable}
						</tbody>
					</table>
				</div>
			</MagicForm>
		);
	}
}

class ScheduleContainer extends React.Component {
	render() {
		return (
			<>
				<div style={{ margin: '.25rem 0 0 0', padding:"10px", display: "flex", justifyContent: "center", alignItems: "center", gap: "1rem"}} >
				<img src={infoIcon} alt="Informations"/><p>Le choix des horaires de rendez-vous se fait désormais sur LEAD dans l'onglet «Paramètres» à la section «Rendez-vous»</p>
				</div>
				<div style={{ margin: '0 0 .25rem 0'}}>
					<Schedule
						title={"Horaires d'ouverture"}
						endpoint={"schedule"}
					/>
				</div>
			</>
		);
	}
}

export default ScheduleContainer;
