import React from 'react';

export default props => {
	return (
		<section className={props.className}>
			<h1 className="pageTitle">{props.title}</h1>
			<div className="loading">
				<h1>Chargement en cours...</h1>
				<p>
					{props.children}
				</p>
			</div>
		</section>
	);
}
