import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import SuperComponent from '../containers/SuperComponent';
import queryString from 'query-string';

import downloadIcon from '../static/icons/picto-download.svg';

class Refunds extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		this.doAction = this.doAction.bind(this);
		this.state =  { page: queryString.parse(this.props.location.search).page || 1 };
	}

	componentWillReceiveProps(nextProps, oldsProps){
		if(nextProps.location.search && queryString.parse(nextProps.location.search).page){
			if(this.state.page !== queryString.parse(nextProps.location.search).page){
				this.setState({page:queryString.parse(nextProps.location.search).page});
			}
		}
	}

	doAction(page){
		return API.GET(`/user/me/club/${this.context.place.id}/refund`)
	}


	render() {
		let clubId = this.context.place.id

		return (
			<SuperComponent
				clubId={clubId}
				title="Vos remboursements"
				page={this.state.page}
				specificClassName="club-refunds-page"
				doAction ={this.doAction}
				pagination={{baseUrl : `${this.props.match.url}?`}}
			>
				<RefundsContainer/>
			</SuperComponent>
		);
	}

}

const RefundsContainer = (props)=>{
	let refunds = props.values;
	if(refunds.length === 0){
		return <p className="msds-advice">Vous n'avez pas encore de remboursements pour votre salle.</p>;
	}
	return <section>
		<table>
			<thead>
				<tr>
					<th>Période</th>
					<th>Référence</th>
					<th>Montant</th>
					<th>Télécharger</th>
				</tr>
			</thead>
			<tbody>
			{ refunds.map((refund)=>{
				return <RefundLine key={`remb_${refund.id}`} refund = {refund}/>
			})}
			</tbody>
		</table>
	</section>
	;
};

const RefundLine = (props) => {
	const refund = props.refund;
	return <tr>
		<td>{refund.period}</td>
		<td>{refund.reference}</td>
		<td>{refund.value} €</td>
		<td>
			<a target="_blank" download href={refund.url} className="button-download" rel="noopener noreferrer">
				<img src={downloadIcon} alt="Télécharger"/>
			</a>
		</td>
	</tr>;
};

export default Refunds;
