import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

const Overlay = ({ onClose, alphaBackground }) => {
  return (
    <div
      className={cn('overlay', {
        'alpha-background': alphaBackground,
      })}
      onClick={onClose}
    ></div>
  );
};

Overlay.propTypes = {
  alphaBackground: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

Overlay.defaultProps = {
  alphaBackground: false,
};

export default Overlay;
