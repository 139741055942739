import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import ClubImage from '../components/clubImages';
import Cropper from 'react-cropper';
import Loader from '../components/loader';

import whiteAddIcon from '../static/icons/picto-add--white.svg';

class Galerie extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);

		this.state = { action :"LOADING", "images" : null};
		this.maxImages = 10;
	}

	componentDidMount() {
		this.reload()
	}

	reload = () => {
		this.setState({action:"LOADING"});
		let clubId = this.context.place.id;
		API.GET(`/user/me/club/${clubId}/galerie`)
		.then(res=>{
			this.setState({action:"VIEW", images: res})
		})
		.catch(e=>{
			console.error(e, e.stack);
		})
		;
	};

	orderChange = () => {
		let ordering = [];
		this.state.images.forEach((photo)=>{
			ordering.push( { id : photo.id, url : photo.url } );
		});
		this.setState({ action :"ORDER", ordering : ordering });
	};

	saveOrder = (event) => {
		event.preventDefault();
		let clubId = this.context.place.id;

		let ids = this.state.ordering.map(p=>p.id);
		this.setState({ action :"UPLOADING" });

		var body = {
			images : ids
		};

		API.PUT(`/user/me/club/${clubId}/galerie`, body)
		.then(res=>{
			this.setState({action:"VIEW"});
			this.reload();
		})
		.catch(e=>console.error(e, e.stack))
		;
	};

	deleteImage = (id, event) => {
		event.preventDefault();
		let clubId = this.context.place.id;
		//TODO better confirm please
		let result = window.confirm("Etes-vous sûr de vouloir supprimer cette photo ?");
		if(!result){
			return;
		}


		API.DELETE(`/user/me/club/${clubId}/galerie/${id}`)
		.then(res=>{
			this.reload();
		})
		.catch(e=>console.error(e, e.stack))
		;
	};

	cancel = () => {
		this.setState({action :"VIEW"});
	};

	addImage = (e) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();
		reader.onload = () => {
			this.setState({ src: reader.result });
		};
		reader.readAsDataURL(files[0]);
		this.setState({action:"ADDING", fileType:files[0].type});
	};

	cropImage = () => {
		if (typeof this.cropper.getCroppedCanvas() === 'undefined') {
			return;
		}

		this.setState({action :"UPLOADING"});

		let img = this.cropper.getCroppedCanvas().toDataURL(this.state.fileType);

		this.setState({
			cropResult: img,
		});
		let clubId = this.context.place.id;

		var body = {
			image : img
		};

		API.POST(`/user/me/club/${clubId}/galerie`, body)
		.then(res =>{
			this.setState({action :"VIEW"});
			this.reload();
		})
		.catch((e)=>{
			this.setState({action :"VIEW"});
			console.error(e, e.stack);
		})
		;
	};


	dragStart = (e) => {
		this.dragged = e.currentTarget;
		e.dataTransfer.effectAllowed = 'move';
		// Firefox requires calling dataTransfer.setData
		// for the drag to properly work
		e.dataTransfer.setData("text/html", e.currentTarget);
	};

	dragEnd = (e) => {
		if(!this.over){
			return;
		}

		let ordering = this.state.ordering;
		let from = Number(this.dragged.dataset.index);
		let to = Number(this.over.dataset.index);
		if(from < to) to--;
		ordering.splice(to, 0, ordering.splice(from, 1)[0]);
		this.setState({ordering: ordering});
	};

	dragOver = (e) => {
		e.preventDefault();
		if(!e.target.dataset.index){
			return;
		}
		this.over = e.target;
	};

	render() {
		if(this.state.action === "LOADING" ){
			return <Loader title="Mes images" />
		}

		// let uid = new Date().getTime()+"_"+Math.random();

		return (
			<div className="club-gallery">
				{this.state.action === "UPLOADING" &&
					<div>
						<h1>Mes Images</h1>
						<p>Votre image est en cours de téléchargement</p>
					</div>
				}

				{this.state.action === "ORDER" &&
					<section className="club-gallery-page">
						<header>
							<h1>Mes Images</h1>
						</header>
						<div className="club-gallery-instructions">
							<p>Vous pouvez changer l'ordre avec un simple Drag and Drop.</p>
							<p>Enfoncez le bouton gauche de la souris sur l'image que vous désirez déplacer.</p>
							<p>Tout en gardant le bouton enfoncé, déplacez là où vous désirez qu'elle apparaisse</p>
							<p>Relâchez alors le bouton de la souris</p>
						</div>
						<ul className="club-gallery-list" onDragOver={this.dragOver}>
							{this.state.ordering.map((photo, index) => {
								return <li className="dragOver" key={photo.url}
										onDragEnd={this.dragEnd}
										onDragStart={this.dragStart} data-index={index}>
											<img alt={photo.url} data-index={index} width="200px" src={photo.url} />
										</li>
							})}
						</ul>
						<div className="club-gallery-actions">
							<button className="button-cancel" onClick={this.cancel}>Annuler</button>
							<button className="button-save" onClick={this.saveOrder}>Sauvegarder</button>
						</div>
					</section>
				}

				{this.state.action === "VIEW" &&
					<section className="club-gallery-page">
						<header>
							<h1>Mes Images ({this.state.images.length}/{this.maxImages})</h1>
							<div className="club-gallery-actions">
								{this.state.images.length!== 0 &&
									<button className="button-secondary" onClick={this.orderChange}>Changer l'ordre</button>
								}
								{
									this.state.images.length < this.maxImages &&
									<label className="button-add">
										<img src={whiteAddIcon} alt="Ajouter"/>
										<input className="fileUpload_input" type="file" accept="image/*" onChange={this.addImage}/>
									</label>
								}
							</div>
						</header>
						<div className="club-gallery-list">
							{this.state.images.map((photo) => {
								return <ClubImage key={photo.id} url={photo.url} originalUrl={photo.originalUrl} onClick={this.deleteImage.bind(this,photo.id)}	/>;
							})}
							{this.state.images.length === 0
								&& <p className="msdsAdvice">Il est nécessaire d'ajouter des photos claires et lumineuses de votre salle (maximum 10) pour avoir un maxium de séances d'essai.</p>
							}
						</div>
					</section>
				}

				{this.state.action === "ADDING" &&
					<div className="galerie_add">
						<h1 className="pageTitle">Mes Images > Ajout</h1>
						<section>
							<div className="galerie_add_cropper">
								<Cropper
									style={{"maxHeight": 300, "maxWidth": 400}}
									aspectRatio={4 / 3}
									guides={false}
									src={this.state.src}
									ref={cropper => { this.cropper = cropper; }} />
							</div>
							<div className="galerie_add_actions">
								<button className="button-save" onClick={this.cropImage}>Valider</button>
								<button className="button-cancel" onClick={this.cancel}>Annuler</button>
							</div>
						</section>
					</div>
				}
			</div>
		);
	}
}

export default Galerie;
