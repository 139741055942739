import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../components/loader';
import Formule from '../containers/Formule';
import API from '../utils/api';

import whiteAddIcon from '../static/icons/picto-add--white.svg';

class Formules extends React.Component {

	static contextTypes = {
		applicationDatas: PropTypes.object.isRequired,
		place: PropTypes.object
	};

	constructor(props, context){
		super(props, context);
		this.state = {
			step: "LOADING"
		};
	}

	componentDidMount() {
		this.reload();
	}

	reload = () => {
		API.GET(`/user/me/club/${this.context.place.id}/formule`)
		.then(res => {
			this.setState({step: "VIEW", formules: res})
		})
		.catch(e => console.error(e))
		;
	};

	onCancelCreate = () =>  {
		this.setState({step: "VIEW"});
	};

	addFormule = () =>  {
		this.setState({step: "ADD"});
	};

	render() {
		if (this.state.step === "LOADING") {
			return <Loader title="Tarifs / Abonnements"/>
		}
		let types = this.context.applicationDatas.formuleTypes;
		let visibilityAdd = this.state.step === "VIEW" ? "visible" : "hidden";

		return (
			<section className="club-plans-page">
				<header>
					<h1>Tarifs / Abonnements</h1>
					<button className="button-add" style={{visibility: visibilityAdd}} onClick={this.addFormule}>
						<img src={whiteAddIcon} alt="Ajouter"/>
					</button>
				</header>

				<section className="club-plans-list">
					{ this.state.step === "ADD" &&
					<Formule formule={{"name": "", "type": "", detail: "", price: ""}}
					         isNew={true}
					         onCancelCreate={this.onCancelCreate}
					         reload={this.reload}
					         types={types}
					/>
					}
					{this.state.step === "VIEW" && this.state.formules.length === 0 &&
					<FillMeAdvice/>
					}
					{
						this.state.formules.map((f) => {
							return <Formule key={f.id} formule={f} types={types} reload={this.reload}/>
						})
					}
				</section>
			</section>
		);
	}
}

const FillMeAdvice = () => {
	return <p className="msdsAdvice">
		Merci d'indiquer au moins une formule d'abonnement disponible en club (au minimum l'abonnement annuel). Il est
		important pour les internautes d'avoir ce type d'information avant de faire une demande d'essai
	</p>;
};

export default Formules;
