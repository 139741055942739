import React from 'react';
import PropTypes from 'prop-types';
import API from './utils/api';
import Loader from './components/loader';

import { UserContext } from './react_contexts';

let origin = window.location.origin;
if (!origin) {
	origin =
		window.location.protocol +
		'//' +
		window.location.hostname +
		(window.location.port ? ':' + window.location.port : '');
}

export default class SessionManager extends React.Component {
	// @TODO (sinewyk): clean deprecated context APIs
	static childContextTypes = {
		user: PropTypes.object,
	};

	getChildContext = () => {
		return { user: this.state.user, };
	};

	state = {
		step: 'LOADING',
		user: null
	};

	componentDidMount() {
		this.refreshUser();
	}

	logout = () => {
		API.DELETE('/api/auth/session/this')
		.then( () => this.refreshUser() );
	};

	refreshUser = () => {
		API.GET('/user/me')
			.then(res => {
				if( !res ) {
					//Probably a 401 on GET, so redirect with API
					return;
				}
				var user = {
					id: res.id,
					firstname: res.firstname,
					lastname: res.lastname,
					isAdmin: res.admin,
					// @note: only used to show the admin link in the upper right
					// to access articles if blogger
					isBlogger: res.blogger
				};
				user.logout = this.logout;
				try {
					window.zE && window.zE('webWidget', 'prefill', {
						name: {
							value: res.firstname+' '+res.lastname,
							readOnly:true
						},
						email: {
							value:res.email,
							readOnly:true
						}
					});
				} catch(e) {
					console.error(e)
				}

				this.setState({
					step: 'OK',
					user,
				});

				fetch(`${origin}/api/auth/session/this`, {method:'PATCH'})
				.then( response => {
					//Nothing
				})
				.catch( console.error );

				window.TrackJS && window.TrackJS.configure({ userId: '' + user.id });
			})
			.catch(e => {
				this.setState({ step: 'OK', user: null });
				console.error('[SessionManager]', e);
			});
	};

	render() {
		if (this.state.step === 'LOADING') {
			return <Loader />;
		}

		return (
			<UserContext.Provider value={this.state.user}>
				{this.props.children}
			</UserContext.Provider>
		);
	}
}
