const AUTH_URL = process.env.REACT_APP_AUTH_URL;

function request(path, method, _body, _headers) {
	const body = _body && JSON.stringify(_body);

	const headers = _headers || {};
	headers['content-type'] = 'application/json';

	let origin = window.location.origin;
	if (!origin) {
		origin =
			window.location.protocol +
			'//' +
			window.location.hostname +
			(window.location.port ? ':' + window.location.port : '');
	}
	var fullPath = origin;
	if (path.startsWith('/api')) fullPath += path;
	else fullPath += '/api/pro' + path;

	return fetch(fullPath, { body, headers, method }).then((response) => {
		if (!response) return Promise.reject(null);

		if (!response.ok) {
			if (response.status === 401) {
				// Redirect to login page with current page as 'returnUrl'
				window.location.href = `${AUTH_URL}/signIn?returnUrl=${encodeURIComponent(window.location.href)}`;
				return Promise.reject(false);
			}

			return response.json().then((json) => {
				return Promise.reject(json);
			});
		}

		if (response.status === 204) return null;

		return response.json();
	});
}


var Api = {
	DELETE: (path, body, headers) => request(path, 'DELETE', body, headers),
	GET: (path, body, headers) => request(path, 'GET', body, headers),
	PATCH: (path, body, headers) => request(path, 'PATCH', body, headers),
	POST: (path, body, headers) => request(path, 'POST', body, headers),
	PUT: (path, body, headers) => request(path, 'PUT', body, headers)
};

export default Api;
