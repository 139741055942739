import React from 'react';
import PropTypes from 'prop-types';
import {NavLink} from 'react-router-dom';

class Reviews extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render() {
		const place = this.context.place;


		const { children } = this.props;
		var childrenWithProps = React.Children.map(children, child =>
			React.cloneElement(child, { places: [place] })
		);


		return (
			<section className="club-reviews">
				<ul className="tabs">
					<li><NavLink to={`/club/${place.id}/avis/container`}>Avis clients</NavLink></li>
					<li><NavLink to={`/club/${place.id}/avis/facebook`}>Facebook</NavLink></li>
					<li><NavLink to={`/club/${place.id}/avis/google`} >Google</NavLink></li>
				</ul>
				{childrenWithProps}
			</section>
		);
	}


}
export default Reviews;
