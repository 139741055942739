import React from 'react';
import moment from 'moment';
import MagicForm from '../components/magicForm';

export default props => {
	let visibilityReduc = (props.reduc ? "visible" : "hidden");
	let visibilitySubDuration = (props.typesWithSubValue.indexOf(props.durationType.value) !== -1) ? "visible" : "hidden";

	let title = props.durationValue.value;
	let found = props.durationTypes.filter(d => d.code == props.durationType.value);
	if (found.length === 1) {
		if (found[0].display === "entrées" && title === 1) {
			title += " entrée";
		} else {
			title += " " + found[0].display;
		}
	}

	if (props.durationSubValue.value && props.typesWithSubValue.indexOf(props.durationType.value) !== -1) {
		title += " de " + props.durationSubValue.value + " minutes";
	}
	return (
		<MagicForm
			title={title}
			editing={props.editing}
			onEdit={props.onEdit}
			onCancelEdit={props.onCancel}
			onDelete={props.onDelete}
			onSubmit={props.onSubmit}
			formClass={"club-product-form"}
		>
			{props.editing && (
				<div className="product_duration">
					<select
						name="durationValue" value={props.durationValue.value}
						onChange={props.onChange}
						className={ "product_duration_value" + (props.durationValue.invalid ? " inputInvalid" : "")}
					>
						<option disabled value="">Qté</option>
						{props.durationValues.map((f) => {
							return <option key={f} value={f}>{f}</option>
						})}
					</select>
					&nbsp;
					<select
						name="durationType" value={props.durationType.value}
						onChange={props.onChange}
						className={props.durationType.invalid ? "inputInvalid" : ""}
					>
						<option disabled value="">Type de produit</option>
						{props.durationTypes.map((f) => {
							return <option key={f.code} value={f.code}>{f.display}</option>
						})}
					</select>
					&nbsp;
					<select
						name="durationSubValue" value={props.durationSubValue.value}
						onChange={props.onChange}
						className={props.durationSubValue.invalid ? "inputInvalid" : ""}
						style={{"visibility": visibilitySubDuration}}
					>
						<option disabled value="">Durée</option>
						{props.durationSubValues.map((f) => {
							return <option key={f} value={f}>de {f} minutes</option>
						})}
					</select>
				</div>
			)}
			{props.editing &&
			<div className="product-price">
				<input
					type="text" name="msdsPrice" value={props.msdsPrice.value}
					placeholder="200"
					onChange={props.onChange}
					size="3"
					className={props.msdsPrice.invalid ? "inputInvalid" : ""}
				/>
				<span> € au lieu de </span>
				<input
					type="text" name="publicPrice" value={props.publicPrice.value}
					placeholder="450"
					onChange={props.onChange}
					size="3"
					className={props.publicPrice.invalid ? "inputInvalid" : ""}
				/>
				<span> €.</span>
				<span style={{"visibility": visibilityReduc}} className="productForm_reduc"> Soit {props.reduc}% de réduction.</span>
			</div>
			}
			{!props.editing &&
			<div className="product-price">
				{props.msdsPrice.value}€ au lieu de {props.publicPrice.value}€.
				<span style={{"visibility": visibilityReduc}} className="productForm_reduc"> Soit {props.reduc}% de réduction.</span>
			</div>
			}
			<label>
				<textarea
					name="detail" value={props.detail.value}
					placeholder="Décrivez votre offre dans ce champ"
					onChange={props.onChange}
					className={props.detail.invalid ? "inputInvalid" : ""}
				>
				</textarea>
			</label>
			{props.date &&
			<div className="product-date">Créé le {moment(props.date).format("DD/MM/YYYY")}</div> }
		</MagicForm>
	);
};
