import React, { useContext, useEffect, useState } from 'react';
import {
	Route,
	Redirect,
	Switch,
	useParams,
	useRouteMatch,
} from 'react-router-dom';
import ClubMenu from '../components/clubMenu';
import API from '../utils/api';

import Dashboard from '../containers/Dashboard';
import AdressAndAccess from '../containers/AdressAndAccess';
import Responsable from '../containers/Responsable';
import Stats from '../containers/Stats';
import Activities from '../containers/Activities';
import Infra from '../containers/Infra';
import Reviews from '../containers/Reviews';
import ReviewsContainer from '../containers/ReviewsContainer';
import FacebookOAuthConfigurator from '../containers/FacebookOAuthConfigurator';
import GoogleOAuthConfigurator from '../containers/GoogleOAuthConfigurator';
import Essais from '../containers/Essais';
import Contact from '../containers/Contact';
import Actors from '../containers/Actors';
import Offre from '../containers/Offre';
import PresentationContainer from '../containers/PresentationContainer';
import Presentation from '../containers/Presentation';
import GalerieAndSearchPicture from '../containers/GalerieAndSearchPicture';
import Events from '../containers/Events';
import ReserveServiceList from '../containers/ReserveServiceList';
import Schedule from '../containers/Schedule';
import Invoices from '../containers/Invoices';
import SubscriptionContainer from '../containers/SubscriptionContainer';
import SubscriptionActivate from '../containers/SubscriptionActivate';
import SubscriptionUpgrade from '../containers/SubscriptionUpgrade';
import Formules from '../containers/Formules';
import Products from '../containers/Products';
import Users from '../containers/Users';
import SalesAndRefund from '../containers/SalesAndRefund';
import Prospects from '../containers/Prospects';
import ProspectsExport from '../containers/ProspectsExport';
import Sales from '../containers/Sales';
import Abos from '../containers/Abos';
import Abo from '../containers/Abo';
import Shop from '../containers/Shop';
import Options from '../containers/Options';
import AboEnLigne from '../containers/AboEnLigne';
import Refunds from '../containers/Refunds';
import WidgetAndFacebook from '../containers/WidgetAndFacebook';
import Widget from '../containers/Widget';
import FacebookLink from '../containers/FacebookLink';
import MetaGoogleAds from '../containers/MetaGoogleAds';
import Calls from '../containers/Calls';
import MobileApp from '../containers/MobileApp';
import Bookings from '../containers/Bookings';
import Resamania2Vel from '../vel-r2/index';

import { PlaceContext, UserContext } from '../react_contexts';

const LEAD_URL = process.env.REACT_APP_LEAD_URL;

export default function ClubContainer() {
	const { place, selectPlace } = useContext(PlaceContext);
	const { clubId } = useParams();
	const [state, setState] = useState('LOADING');
	const { url } = useRouteMatch();
	const user = useContext(UserContext);

	useEffect(() => {
		API.GET(`/place/${clubId}`)
			.then((res) => {
				selectPlace(res);
				setState('OK');
			})
			.catch((e) => {
				setState('ERROR');
				console.error(e, e.stack);
			});
	}, [clubId, selectPlace]);

	if (state === 'LOADING') {
		return <div>LOADING</div>;
	}
	if (state === 'ERROR') {
		return <div>Une erreur est survenue</div>;
	}

	let alertSubscription = undefined;

	if (['NONE', 'ANNUAIRE'].indexOf(place.subscription.plan) !== -1) {
		alertSubscription = 'Activer';
	} else if (place.subscription.plan === 'PREMIUM') {
		alertSubscription = 'Upgrader';
	}

	const links1 = [
		{ name: 'Résumé', url: `${url}/dashboard` },
		{ name: 'Mes statistiques', url: `${url}/stats` },
		{ name: 'Mes factures', url: `${url}/invoices` },
		{
			name: 'Mon abonnement',
			url: `${url}/subscription`,
			alert: alertSubscription,
		},
	];

	const links2 = [
		{ name: 'Vos ventes', url: `${url}/sales/` },
		{ name: 'Vos prospects', url: `${url}/prospects` },
		{ name: 'Vos avis Clients', url: `${url}/avis` },
	];

	const links3 = [
		{ name: 'Vente en ligne', url: `${url}/shop` },
		{ name: 'Invitation / Pass découverte', url: `${url}/monoffre` },
		{ name: 'Widget & Facebook', url: `${url}/widgetAndFacebook` },
		{ name: 'Présentation', url: `${url}/presentation` },
		{ name: 'Contact du responsable', url: `${url}/responsable` },
		{ name: 'Vos utilisateurs', url: `${url}/users` },
	];

	const links4 = [
		{ name: 'Application Mobile', url :`https://clubconnect.fr/`, alert: 'New', external:true },
		{ name: 'Vente en ligne', url :`${url}/r2-vel/`}
	];

	return (
		<section className="club-container">
			<nav className="club-menu">
				<h5 className="picto-dashboard">Tableau de bord</h5>
				<ClubMenu links={links1} />

				<h5 className="picto-mobile">Application Mobile</h5>
				<ClubMenu links={links4} />
				
				<h5 className="picto-clients">Gestion clientèle</h5>
				<ClubMenu links={links2} />

				<h5 className="picto-club--white">Description</h5>
				<ClubMenu links={links3} />

			</nav>

			<div className="club-content">
				<Switch>
					<Route path={`${url}/r2-vel`} component={Resamania2Vel} />
					<Route path={`${url}/dashboard`} component={Dashboard} />
					<Route path={`${url}/sales`}>
						<SalesAndRefund>
							<Switch>
								<Route exact path={`${url}/sales/abos`} component={Abos} />
								<Route path={`${url}/sales/abos/:aboId`} component={Abo} />
								<Route path={`${url}/sales/list`} component={Sales} />
								<Route path={`${url}/sales/refund`} component={Refunds} />
								<Redirect from="*" to={`${url}/sales/abos`} />
							</Switch>
						</SalesAndRefund>
					</Route>
					<Route path={`${url}/shop`}>
						<Shop>
							<Switch>
								<Route path={`${url}/shop/abos`} component={AboEnLigne} />
								<Route path={`${url}/shop/products`} component={Products} />
								<Route path={`${url}/shop/options`} component={Options} />
								<Redirect from="*" to={`${url}/shop/abos`} />
							</Switch>
						</Shop>
					</Route>
					<Route path={`${url}/abo`}>
						<Route path={`${url}/:aboId`} component={Abo} />
					</Route>
					<Route path={`${url}/prospects`}>
						<Prospects isAdmin={user.isAdmin}>
							<Switch>
								<Route path={`${url}/prospects/essais`} component={Essais} />
								<Route path={`${url}/prospects/contacts`} component={Contact} />
								<Route path={`${url}/prospects/calls`} component={Calls} />
								<Route path={`${url}/prospects/bookings`} render={(props) => <Bookings {...props}/>} />
								<Route
									path={`${url}/prospects/export`}
									component={ProspectsExport}
								/>
								<Redirect from="*" to={`${url}/prospects/essais`} />
							</Switch>
						</Prospects>
					</Route>
					<Route path={`${url}/users`} component={Users} />
					<Route path={`${url}/responsable`} component={Responsable} />
					<Route path={`${url}/stats`} component={Stats} />
					<Route path={`${url}/widgetAndFacebook`}>
						<WidgetAndFacebook>
							<Switch>
								<Route
									path={`${url}/widgetAndFacebook/widget`}
									component={Widget}
								/>
								<Route
									path={`${url}/widgetAndFacebook/facebook`}
									component={FacebookLink}
								/>
								<Route path={`${url}/widgetAndFacebook/meta_google_ads`} component={MetaGoogleAds} />
								<Redirect from="*" to={`${url}/widgetAndFacebook/widget`} />
							</Switch>
						</WidgetAndFacebook>
					</Route>
					<Route path={`${url}/avis`}>
						<Reviews>
							<Switch>
								<Route
									path={`${url}/avis/container`}
									component={ReviewsContainer}
								/>
								<Route
									path={`${url}/avis/facebook`}
									component={FacebookOAuthConfigurator}
								/>
								<Route
									path={`${url}/avis/google`}
									component={GoogleOAuthConfigurator}
								/>
								<Redirect from="*" to={`${url}/avis/container`} />
							</Switch>
						</Reviews>
					</Route>
					<Route path={`${url}/presentation`}>
						<PresentationContainer>
							<Switch>
								<Route
									path={`${url}/presentation/text`}
									component={Presentation}
								/>
								<Route
									path={`${url}/presentation/picture`}
									component={GalerieAndSearchPicture}
								/>
								<Route
									path={`${url}/presentation/address`}
									component={AdressAndAccess}
								/>
								<Route
									path={`${url}/presentation/planning`}
									component={Schedule}
								/>
								<Route
									path={`${url}/presentation/activities`}
									component={Activities}
								/>
								<Route
									path={`${url}/presentation/services`}
									component={Infra}
								/>
								<Route path={`${url}/presentation/actor`} component={Actors} />
								<Route
									path={`${url}/presentation/formules`}
									component={Formules}
								/>
								<Route path={`${url}/presentation/events`} component={Events} />
								<Route path={`${url}/presentation/reserveWithGoogle`} component={ReserveServiceList} />
								<Redirect from="*" to={`${url}/presentation/text`} />
							</Switch>
						</PresentationContainer>
					</Route>
					<Route path={`${url}/monoffre`} component={Offre} />
					<Route path={`${url}/invoices`} component={Invoices} />
					<Route path={`${url}/subscription`}>
						<Switch>
							<Route
								path={`${url}/subscription/actual`}
								component={SubscriptionContainer}
							/>
							<Route
								path={`${url}/subscription/subscribe`}
								component={SubscriptionActivate}
							/>
							<Route
								path={`${url}/subscription/upgrade`}
								component={SubscriptionUpgrade}
							/>
							<Redirect from="*" to={`${url}/subscription/actual`} />
						</Switch>
					</Route>
					<Route path={`${url}/mobileApp`} component = {MobileApp} />
					<Redirect from="*" to={`${url}/dashboard`} />
				</Switch>
			</div>
		</section>
	);
}
