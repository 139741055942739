import React from 'react';
import PropTypes from 'prop-types';

export default class TrackJS extends React.Component {
	static contextTypes = {
		appVersion: PropTypes.string
	};

	componentDidMount() {
		const {enabled, token, application} = this.props;

		if (enabled) {
			if (!token) {
				return console.warn('[TrackJS]', 'No token');
			}
			if (!application) {
				return console.warn('[TrackJS]', 'No application');
			}
			console.debug('[TrackJS]', 'starts');
			var config = {
				enabled,
				token,
				application,
				userId: 'guest',
				version: this.context.appVersion
			};
			window.TrackJS && window.TrackJS.install(config);
		} else {
			return console.debug('[TrackJS]', 'Not enabled');
		}
	}

	render() {
		return this.props.children || null;
	}
}
