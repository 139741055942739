import React from 'react';
import API from '../utils/api';

class ExternalPageOAuthConfigurator extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			step : "LOADING"
		};
	}

	componentDidMount(){
		if (window.location.search && window.location.search === "?init"){
			this.pagesList();
			return;
		}
		this.reload();
	}

	reload = () => {
		this.setState({step:"LOADING"});
		API.GET(`/user/me/club/${this.props.clubId}/externalPage/${this.props.external.name}`)
		.then(externalPage=>{
			this.setState({
				step : "DISPLAY",
				externalPage : externalPage === true ? null : externalPage
			});
		})
		.catch(e=>{
			console.error(e, e.stack);
		});
	};

	pagesList = () => {
		var oAuthToken = window.location.hash.split("&")[0].split('=')[1];
		if(!oAuthToken) return;

		window.history.replaceState({}, "entry page", window.location.href.split("#")[0].split("?init")[0]);
		this.setState({step:"LOADING", token : oAuthToken});
		API.GET(`/${this.props.external.name}/page?access_token=${oAuthToken}`)
		.then(pages=>{
			if(!pages.length){
				this.setState({
					step : "NO_PAGES"
				});
				return;
			}
			this.setState({
				step  : "CHOICE",
				pages : pages
			});
		})
		.catch(e=>{
			console.error(e, e.stack);
			this.setState({step:"ERROR", error :e});
		});
	};

	disconnect = (event) => {
		event.preventDefault();
		this.setState({step:"SETTING"});
		let pageId = this.state.externalPage.id;
		API.DELETE(`/user/me/club/${this.props.clubId}/externalPage/${pageId}`)
		.then(res=>{
			this.reload();
		})
		.catch(e=>{
			console.error(e, e.stack);
			this.setSate({step:"ERROR", error :e});
		});
	};

	render() {
		if(this.state.step === "DISPLAY"){

			var redirectUrl = this.props.external.getRedirectUrl();

			if(!this.state.externalPage){
				return (
					<div className={`club-reviews-configuration ${this.props.external.className}`}>
						<div className="reviews-settings-desc">
							<p>En vous connectant à votre compte {this.props.external.display}, vous pourrez récupérer les avis laissés sur la page de votre club directement dans l'interface pro MaSalledeSport.com.</p>
						</div>
						<a href={redirectUrl} className="button-oauth">
							{this.props.external.connectionIcon && <img src={this.props.external.connectionIcon.url} alt={this.props.external.connectionIcon.alt}/>}
							Connectez-vous avec {this.props.external.display}
						</a>
					</div>
				);
			}
			if(this.state.externalPage.status === "EXPIRED"){
				return (
					<div className={`club-reviews-configuration ${this.props.external.className}`}>
						<div className="reviews-settings-desc">
							<p>En vous connectant à votre compte {this.props.external.display}, vous pourrez récupérer les avis laissés sur la page de votre club directement dans l'interface pro MaSalledeSport.com.</p>
						</div>
						<div className="page-infos">
							<p>
								Votre connexion à la page {this.state.externalPage.name} a expirée:
							</p>
							<div>
								<a href={redirectUrl} className="button-oauth">
									{this.props.external.connectionIcon && <img src={this.props.external.connectionIcon.url} alt={this.props.external.connectionIcon.alt}/>}
									Connectez-vous avec {this.props.external.display}
								</a>
							</div>
						</div>
					</div>
				);
			}
			return (
				<div className={`club-reviews-configuration ${this.props.external.className}`}>
					<div className="reviews-settings-desc">
						<p>En vous connectant à votre compte {this.props.external.display}, vous pourrez récupérer les avis laissés sur la page de votre club directement dans l'interface pro MaSalledeSport.com.</p>
					</div>
					<div className="page-infos">
						<p>
							Vous êtes actuellement connecté avec la page :
						</p>
						<div>
							<a target="_blank" rel="external nofollow noopener noreferrer" href={this.state.externalPage.url}>
								{this.props.external.icon && <img src={this.props.external.icon.url} alt={this.props.external.icon.alt}/>}
								{this.state.externalPage.name}
							</a>
							<button className="button-cancel" onClick={this.disconnect}>Déconnexion</button>
						</div>
					</div>
				</div>
			);
		}

		if(this.state.step === "CHOICE"){
			return <div className={`club-reviews-configuration ${this.props.external.className}`}>
				<form>
					<p>Choisissez la page à connecter</p>
					<ul>
					{this.state.pages.map((p,i)=>{
						return <li key={i}><button onClick={this.choiceConfirm} className="button-oauth" data-index={i}>{p.name}</button></li>;
					})}
					</ul>
					<button onClick={this.goToDisplay} className="button-cancel">Annuler</button>
				</form>
			</div>;
		}

		if(this.state.step === "NO_PAGES"){
			return <p>Vous ne possédez aucun droit sur aucune page avec ce compte {this.props.external.display}</p>;
		}

		if(this.state.step === "SETTING" || this.state.step === "LOADING"){
			return <p>Chargement en cours</p>;
		}

		if(this.state.step === "ERROR"){
			return <p>Une erreur est survenue</p>;
		}

		console.error("Unexpected step on ExternalPageOAuthConfigurator:", this.state.step);
		return <p>{this.state.step}</p>;
	}

	goToDisplay = (event) => {
		event.preventDefault();
		this.setState({step:"DISPLAY"});
	};

	choiceConfirm = (event) => {
		event.preventDefault();
		var page = this.state.pages[event.target.dataset.index];
		this.setState({step:"SETTING"});

		API.PATCH(`/user/me/club/${this.props.clubId}/externalPage/${this.props.external.name}`,page)
		.then(res=>{
			this.reload();
		})
		.catch(e=>{
			console.error(e, e.stack);
			this.setSate({step:"ERROR", error :e});
		});
	};
}


export default ExternalPageOAuthConfigurator;
