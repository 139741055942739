import React from 'react';
import PropTypes from 'prop-types';
import API from './utils/api';
import Loader from './components/loader';

import { PlaceContext } from './react_contexts';

export default class PlacesLoader extends React.Component {
	// @TODO (sinewyk): clean deprecated context APIs
	static childContextTypes = {
		places: PropTypes.array,
		place: PropTypes.object,
		placeSelected: PropTypes.func,
		placeUpdate: PropTypes.func
	};

	getChildContext = () => {
		return {
			places: this.state.placeContext.places,
			place: this.state.placeContext.place,
			placeSelected: this.placeSelected,
			placeUpdate: this.placeUpdate
		};
	};

	state = {
		step: 'LOADING',
		placeContext: {
			places: [],
			place: null
		}
	};

	componentDidMount() {
		API.GET('/place')
			.then(res => {
				this.setState({
					step: 'OK',
					placeContext: {
						...this.state.placeContext,
						places: res,
						selectPlace: this.placeSelected,
						updatePlace: this.placeUpdate
					}
				});
			})
			.catch(e => {
				this.setState({ step: 'ERROR' });
				console.error('[PlacesLoader]', 'Cannot get places', e);
			});
	}

	placeSelected = place => {
		this.setState({ placeContext: { ...this.state.placeContext, place } });
	};

	placeUpdate = body => {
		return API.PATCH(`/user/me/club/${this.state.placeContext.place.id}`, body).then(res => {
			var place = Object.assign({}, this.state.placeContext.place);

			if (body.presentation) {
				Object.keys(body.presentation).forEach(key => {
					place.presentation[key] = body.presentation[key];
				});
			} else if (body.access) {
				Object.keys(body.access).forEach(key => {
					place.access[key] = body.access[key];
				});
			} else {
				place = Object.assign(place, body);
			}
			this.setState({
				placeContext: {
					...this.state.placeContext,
					place
				}
			});
			return true;
		});
	};

	render() {
		if (this.state.step === 'LOADING') {
			return <Loader />;
		}
		if (this.state.step === 'ERROR') {
			return <p>Une erreur est survenue</p>;
		}

		return (
			<PlaceContext.Provider value={this.state.placeContext}>
				{this.props.children}
			</PlaceContext.Provider>
		);
	}
}
