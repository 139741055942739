import React from 'react';
import MagicForm from '../components/magicForm';

import defaultAvatar from '../static/default-avatar.svg';
import deleteIcon from '../static/icons/picto-delete.svg';

export default props => {
	let title = props.fullname + (props.job ? ", " + props.job : "");
	let maxLength = 30;
	title.length > maxLength && (title = title.substring(0, maxLength) + "...");

	return <MagicForm
		formClass='club-trainer-form'
		title={title}
		editing = {props.editing}
		onCancelEdit = {props.onCancelEdit}
		onSubmit = {props.onSubmit}
		onEdit={props.onEdit}
		onDelete = {props.onDelete}
		>
		<section className="trainer-form-content">
		<div className="club-trainer-pic">
			{props.image &&
			<img src={props.image} className="club-trainer-avatar" alt='coach'/>
			}
			{!props.image &&
			<img src={defaultAvatar} className="club-trainer-avatar" alt='coach'/>
			}
			{props.editing && !props.image &&
			<label className="button-primary">
				<span>Ajouter une photo</span>
				<input className="fileUpload_input" type="file" accept="image/*" onChange={props.addImage}/>
			</label>
			}
			{props.editing && props.image &&
				<button className="button-delete" title="Supprimer" onClick={props.deleteImage}><img src={deleteIcon} alt="Supprimer" /></button>
			}
		</div>
		<div className='club-trainer-infos'>
			<div className="inputs-group">
				{props.editing &&
				<label>
					<span>Nom complet</span>
					<input name="fullname" placeholder="Prénom et nom" type="text" value={props.fullname} onChange={props.onChange}/>
				</label>
				}
				{props.editing &&
				<label>
					<span>Job</span>
					<input name="job" placeholder="Job ou spécialité" type="text" value={props.job}
					       onChange={props.onChange}/>
				</label>
				}
			</div>
			<label>
				<span>Bio</span>
				<textarea className="actorDescription" placeholder="À propos de ce coach" name="description" value={props.description} onChange={props.onChange}/>
			</label>
		</div>
		</section>
		</MagicForm>
	;
}
