import React from 'react';
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";
import moment from 'moment';

import API from '../utils/api';

import deleteIcon from '../static/icons/picto-delete.svg';
import whiteAddIcon from '../static/icons/picto-add--white.svg';
import whiteDeleteIcon from '../static/icons/picto-delete--white.svg';

export default class AboPlan extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context) {
		super(props, context);
		var form = this.planToForm(this.props.plan);
		this.state = {
			step:'DISPLAY',
			plan: this.props.plan,
			editing : !this.props.plan,
			form : form
		}
	}

	onCreatePlan = (planForm) => {
		this.setState({step:'LOADING'});
		let plan = {
			name: planForm.name.value,
			description: planForm.description,
			duration : +planForm.duration.value,
			monthlyPrice : (+planForm.monthlyPrice.value) * 100,
			place : {
				id : this.context.place.id
			}
		};
		if(planForm.fees_label.value && planForm.fees_amount.value){
			plan.fees = {
				label: planForm.fees_label.value,
				amount: planForm.fees_amount.value * 100
			};
			if(planForm.fees_was.value){
				plan.fees.was = planForm.fees_was.value * 100;
			}
		}

		plan.promo = {
			default : {
				title : planForm.promo_default_title.value.trim(),
				description :planForm.promo_default_description.value.trim(),
				prices : []
			}
		};

		for(let i=0; i< plan.duration;i++){
			let p =  planForm.promo_default_prices.value[i] * 100 ;
			plan.promo.default.prices.push(p);
		}

		if(planForm.promo_temporary){
			plan.promo.temporary = {
				title : planForm.promo_temporary_title.value.trim(),
				description :planForm.promo_temporary_description.value.trim(),
				prices : [],
				starts : planForm.promo_temporary_starts.value.getTime(),
				ends : planForm.promo_temporary_ends.value.getTime()
			};
			for(let i=0; i< plan.duration;i++){
				let p =  planForm.promo_temporary_prices.value[i] * 100 ;
				plan.promo.temporary.prices.push(p);
			}
		}


		API.POST(`/api/abo/abo/plan`, plan)
		.then(() => {
			this.props.reload();
		})
		.catch(e => {
			const msg = e.userMsg || 'Une erreur est survenue';
			this.setState({step: 'ERROR', msg});
			console.error(e, e.stack);
		});
	};

	modifyPlan = (id, planForm) => {
		this.setState({step:'LOADING'});
		let plan = {
			name: planForm.name.value,
			description: planForm.description,
			duration : +planForm.duration.value,
			monthlyPrice : (+planForm.monthlyPrice.value) * 100
		};
		if(planForm.fees_label.value && planForm.fees_amount.value){
			plan.fees = {
				label: planForm.fees_label.value,
				amount: planForm.fees_amount.value * 100
			};
			if(planForm.fees_was.value){
				plan.fees.was = planForm.fees_was.value * 100;
			}
		}

		plan.promo = {
			default : {
				title : planForm.promo_default_title.value.trim(),
				description :planForm.promo_default_description.value.trim(),
				prices : []
			}
		};
		for(let i=0; i< plan.duration;i++){
			let p =  planForm.promo_default_prices.value[i] * 100 ;
			plan.promo.default.prices.push(p);
		}
		if(planForm.promo_temporary){
			plan.promo.temporary = {
				title : planForm.promo_temporary_title.value.trim(),
				description :planForm.promo_temporary_description.value.trim(),
				prices : [],
				starts : planForm.promo_temporary_starts.value.getTime(),
				ends : planForm.promo_temporary_ends.value.getTime()
			};
			for(let i=0; i< plan.duration;i++){
				let p =  planForm.promo_temporary_prices.value[i] * 100 ;
				plan.promo.temporary.prices.push(p);
			}
		}

		API.PUT(`/api/abo/abo/plan/${id}`, plan)
		.then(() => {
			this.props.reload();
		})
		.catch(e => {
			const msg = e.userMsg || 'Une erreur est survenue';
			this.setState({step: 'ERROR', msg});
			console.error(e, e.stack);
		});
	};

	planToForm = (plan) => {
		var arr = [];
		if(!plan){
			let monthlyPrice = 30;

			for(var i=0;i<12;i++)
				arr.push(monthlyPrice);

			return {
				name: 				{ value: ''},
				duration: 			{ value: 12},
				monthlyPrice :		{ value : monthlyPrice},
				fees_label: 		{ value: ''},
				fees_amount: 		{ value: ''},
				fees_was: 			{ value: ''},
				newDescription: 	{ value: ''},
				promo_default_title : {value:''},
				promo_default_description: {value:''},
				promo_default_prices : {value:arr},

				promo_temporary : false,

				promo_temporary_title : {value:''},
				promo_temporary_description: {value:''},
				promo_temporary_prices : {value:arr.map(e=>'')},
				promo_temporary_starts : {value : new Date() },
				promo_temporary_ends: {value : moment().add(1,'month').toDate() },

				description: []
			};

		}

		return {
			name: 				{ value: plan.name},
			duration:			{ value: plan.duration},
			monthlyPrice : 		{ value: (plan.monthlyPrice / 100).toFixed(2)},
			fees_label: 		{ value: !plan.fees?'':plan.fees.label},
			fees_amount: 		{ value: !plan.fees?'':(plan.fees.amount/100).toFixed(2)},
			fees_was: 			{ value: !plan.fees?'':(plan.fees.was/100).toFixed(2)},
			modification : {
				date : plan.modification.date
			},
			promo_default_title : {value:plan.promo.default.title},
			promo_default_description: {value:plan.promo.default.description},
			promo_default_prices : {value:plan.promo.default.prices.map(e=>e/100)},

			promo_temporary : !!plan.promo.temporary,

			promo_temporary_title : {value: plan.promo.temporary && plan.promo.temporary.title || ''},
			promo_temporary_description: {value: plan.promo.temporary && plan.promo.temporary.description || ''},
			promo_temporary_prices : {value: plan.promo.temporary && plan.promo.temporary.prices.map(e=>e/100) || plan.promo.default.prices.map(e=>e/100) },
			promo_temporary_starts :  {value: plan.promo.temporary && new Date(plan.promo.temporary.starts) || new Date()},
			promo_temporary_ends:    {value: plan.promo.temporary && new Date(plan.promo.temporary.ends) || new Date()},

			newDescription: 	{ value: ''},
			description: plan.description
		};
	};

	toogleTempo = (e) => {
		let newState = Object.assign({}, this.state);
		newState.form.promo_temporary = !newState.form.promo_temporary;
		this.setState(newState);
	};

	deletePlan = (e) => {
		e.preventDefault();
		let result = window.confirm("Êtes-vous sûr de vouloir supprimer cette formule ?");
		if (result) {
			this.props.onDelete(this.state.plan.id);
		}
	};

	modify = (e) => {
		e.preventDefault();
		var form = this.planToForm(this.props.plan);
		this.setState({editing:true, form:form});
	};

	addDescription = (e) => {
		e.preventDefault();
		let newState = Object.assign({}, this.state);

		if (!!this.state.form.newDescription.value) {
			newState.form.description.push(newState.form.newDescription.value);
			newState.form.newDescription.value = '';
		} else {
			newState.form.newDescription.error = "Veuillez remplir le champ";
		}

		this.setState(newState);
	};

	removeDescription = (e) => {
		e.preventDefault();
		let index = e.currentTarget.dataset.descIndex;
		let newState = Object.assign({}, this.state);
		newState.form.description.splice(index, 1);
		this.setState(newState);
	};

	onChange = (e) => {
		let newState = Object.assign({}, this.state);
		newState.form[e.target.name] = {value : e.target.value} ;
		this.setState(newState);
	};

	dateChange = (type, value) => {
		let newState = Object.assign({}, this.state);
		value.setHours(0,0,0,0);
		newState.form[`promo_temporary_${type}`] = { value : value };
		this.setState(newState);
	};

	onCancel = (e) => {
		e.preventDefault();
		if(this.props.plan){
			this.setState({editing:false});
		}else {
			this.props.onCancelCreate(e);
		}
	};

	onSubmit = (e) => {
		e.preventDefault();
		let newState = Object.assign({}, this.state);
		let errors = 0;
		['name', 'duration','promo_default_title','promo_default_description'].forEach(f => {
			if (!newState.form[f].value) {
				newState.form[f].error = "Veuillez remplir ce champ";
				errors++;
			}
		});

		this.state.form.promo_temporary && ['promo_temporary_title','promo_temporary_description'].forEach(f => {
			if (!newState.form[f].value) {
				newState.form[f].error = "Veuillez remplir ce champ";
				errors++;
			}
		});

		//TODO check duration number, integer, between X and y
		newState.form['duration'].value = +(newState.form['duration'].value);

		if (!newState.form.description.length) {
			newState.form.newDescription.error = "Veuillez ajouter un élément de description";
			errors++;
		}




		if (errors !== 0) {
			this.setState(newState);
		} else {
			if(this.props.plan){
				this.modifyPlan(this.props.plan.id, newState.form);
			}else {
				this.onCreatePlan(newState.form);
			}
		}
	};

	onDurationChange = (e) => {
		e.preventDefault();
		var form = Object.assign({}, this.state.form);
		form.duration.value = +e.target.value;
		form.duration.error = false;
		if(!form.duration.value || form.duration.value < 1 || form.duration.value > 24)
			return;

		while(form.promo_default_prices.value.length < form.duration.value){
			form.promo_default_prices.value.push(
				form.promo_default_prices.value[form.promo_default_prices.value.length-1]
			);
		}
		while(form.promo_temporary_prices.value.length < form.duration.value){
			form.promo_temporary_prices.value.push(
				form.promo_temporary_prices.value[form.promo_temporary_prices.value.length-1]
			);
		}

		this.setState({form});
	};

	priceChange = (index, type, e) => {
		e.preventDefault();
		var form = Object.assign({}, this.state.form);
		var prices = form[`promo_${type}_prices`];
		prices.error = false;
		prices.value[index] = +e.target.value;
		this.setState({form});
	};

	render() {
		if ( this.state.step === 'LOADING')
			return <p>Chargement</p>;

		if (this.state.editing) {
			let form = this.state.form;
			return (
				<form className='club-plan-add new'>
					{ this.state.step === 'ERROR' && (
						<p style={{color:"red", textAlign:'center', fontWeigth:'bold', padding:'2rem'}}>{this.state.msg}</p>
					)}
					<section>
						<header>
							<div>
								<strong>Nom de la formule</strong>
								<input type="text" name={'name'} placeholder={'Nom de la formule'} value={form.name.value}
										className={form.name.error?'inputInvalid':''}
										onChange={this.onChange}/>
							</div>

							<div>
								<strong>Prix (hors promo) par mois</strong>
								<input 	name={'monthlyPrice'} placeholder={'Prix / mois'} value={form.monthlyPrice.value}
										type="number" step='0.01'
										className={form.monthlyPrice.error?'inputInvalid':''}
										onChange={this.onChange}/>
							</div>

							<div>
								<strong>Nombre de mois</strong>
								<input 	name={'duration'} placeholder={'Durée'} value={form.duration.value}
										type="number" step='1'
										className={form.duration.error?'inputInvalid':''}
										onChange={this.onDurationChange}/>
							</div>
							<div className={'club-fees-add'}>
								<strong>Frais supplémentaires (optionnel) :</strong>
								<label>
									<span>Intitulé</span>
									<input type="text" name={'fees_label'} placeholder={'Ex: frais de gestion'}
									       value={form.fees_label.value} className={form.fees_label.error?'inputInvalid':''}
									       onChange={this.onChange}/>
								</label>
								<div>
									<label>
										<span>Prix</span>
										<input type="number" name={'fees_amount'} step={'.01'} placeholder={'€'}
										       value={form.fees_amount.value} className={form.fees_amount.error?'inputInvalid':''}
										       onChange={this.onChange}/>
									</label>
									<label>
										<span>Au lieu de</span>
										<input type="number" name={'fees_was'} step={'.01'} placeholder={'€'}
										       value={form.fees_was.value} className={form.fees_was.error?'inputInvalid':''}
										       onChange={this.onChange}/>
									</label>
								</div>
							</div>
							<div className={'club-desc-add'}>
								<strong>Description de la formule :</strong>
								<ol>
									{form.description.map((d, i) =>
										<li key={i}>
											-&nbsp;<span>{d}</span>
											<button className='button--small' onClick={this.removeDescription} data-desc-index={i}>
												<img src={deleteIcon} alt="Suppr."/>
											</button>
										</li>
									)}
								</ol>
								<label>
									<input type="text" placeholder={'Élément de description'} name={'newDescription'}
									       onChange={this.onChange} className={form.newDescription.error ? 'inputInvalid': ''}
									       value={form.newDescription.value}/>
									<button onClick={this.addDescription} className='button--small'>
										<img src={whiteAddIcon} alt="Ajouter"/>
									</button>
								</label>
							</div>
							<label style={{flexDirection:'row', color:'white', paddingTop : '1rem'}}>
								<input type='checkbox' checked = {!!this.state.form.promo_temporary}  name='toogleTemp' onChange = {this.toogleTempo}/>
								<span>Ajouter une promo temporaire</span>
							</label>
						</header>

						<div>
							<strong>Promo web permanente</strong>
							<label>
								<span>Intitulé (30 caractères max)</span>
								<input type="text" name={'promo_default_title'} maxLength={30} placeholder={'Ex: Exclusivité web'}
								       value={form.promo_default_title.value} className={form.promo_default_title.error?'inputInvalid':''}
								       onChange={this.onChange}/>
							</label>
							<label>
								<span>Description complète</span>
								<textarea name={'promo_default_description'} placeholder={'Description de l\'offre'}
								          value={form.promo_default_description.value} className={form.promo_default_description.error?'inputInvalid':''}
								          onChange={this.onChange}/>
							</label>

							<ol>
							{form.promo_default_prices.value.slice(0, form.duration.value).map( (e,i) => {
								return <li key = {i} >
									<span>Mois {i+1} : </span>
									<input style={{width:'60px'}} onChange={this.priceChange.bind(this, i, 'default')} type = 'number' step='0.1' name = {`promo_default_prices${i}`} value = {form.promo_default_prices.value[i]} />
									<span> au lieu de {form.monthlyPrice.value} €</span>
								</li>
							})}
							</ol>
						</div>

						{this.state.form.promo_temporary && <div>
							<strong>Promo web temporaire</strong>
							<label>
								<span>Intitulé (30 caractères max)</span>
								<input type="text" name={'promo_temporary_title'} maxLength={30} placeholder={'Ex: Exclusivité web'}
								       value={form.promo_temporary_title.value} className={form.promo_temporary_title.error?'inputInvalid':''}
								       onChange={this.onChange}/>
							</label>
							<label>
								<span>Description complète</span>
								<textarea name={'promo_temporary_description'} placeholder={'Description de l\'offre'}
								          value={form.promo_temporary_description.value} className={form.promo_temporary_description.error?'inputInvalid':''}
								          onChange={this.onChange}/>
							</label>

							<ol>
							{form.promo_temporary_prices.value.slice(0, form.duration.value).map( (e,i) => {
								return <li key = {i} >
									<span>Mois {i+1} : </span>
									<input style={{width:'60px'}} onChange={this.priceChange.bind(this, i, 'temporary')} type = 'number' step='0.1' name = {`promo_temporary_prices${i}`} value = {form.promo_temporary_prices.value[i]} />
									<span> au lieu de {form.monthlyPrice.value} €</span>
								</li>
							})}
							</ol>
							<DatePicker
								name="promo_temporary_starts"
								selected={this.state.form.promo_temporary_starts.value}
								onChange={this.dateChange.bind(this, 'starts')}
								todayButton={"Aujourd'hui"}
								dateFormat="dd/MM/YYYY"
								isClearable={false}
								placeholderText="JJ/MM/AAAA"
								className={ (this.state.form.promo_temporary_starts.invalid ? " inputInvalid" : "")}
							/>
							<DatePicker
								name="promo_temporary_ends"
								selected={this.state.form.promo_temporary_ends.value}
								onChange={this.dateChange.bind(this, 'ends')}
								todayButton={"Aujourd'hui"}
								dateFormat="dd/MM/YYYY"
								isClearable={false}
								placeholderText="JJ/MM/AAAA"
								className={ (this.state.form.promo_temporary_ends.invalid ? " inputInvalid" : "")}
							/>
						</div>
						}
						<footer>
							<button className='button-primary' onClick={this.onSubmit}>Valider</button>
							<button className='button-secondary button-cancel' onClick={this.onCancel}>Annuler</button>
						</footer>
					</section>
				</form>
			);
		}

		let plan = this.state.plan;

		//TODO use fundisplay from api response
		let funDisplay = [];
		plan.promo.default.prices.forEach( (bm, i) => {
			if(!funDisplay.length || funDisplay[funDisplay.length -1].value !== bm){
				funDisplay.push({
					first: i,
					last : i,
					value : bm
				});
			}
			funDisplay[funDisplay.length -1].last = i;
		});
		funDisplay.forEach(fd=>{
			if(fd.first === fd.last){
				fd.display = `Mois ${fd.first+1} : ${(fd.value/100).toFixed(2)}€`
			}else {
				fd.display = `Mois ${fd.first+1} à ${fd.last+1} : ${(fd.value/100).toFixed(2)}€ / mois`
			}
		});

		let funDisplay2 = null;
		if(plan.promo.temporary){
			funDisplay2 = [];
			plan.promo.temporary.prices.forEach( (bm, i) => {
				if(!funDisplay2.length || funDisplay2[funDisplay2.length -1].value !== bm){
					funDisplay2.push({
						first: i,
						last : i,
						value : bm
					});
				}
				funDisplay2[funDisplay2.length -1].last = i;
			});
			funDisplay2.forEach(fd=>{
				if(fd.first === fd.last){
					fd.display = `Mois ${fd.first+1} : ${(fd.value/100).toFixed(2)}€`
				}else {
					fd.display = `Mois ${fd.first+1} à ${fd.last+1} : ${(fd.value/100).toFixed(2)}€ / mois`
				}
			});
		}
		return (
			<article>
				<header>
					<button className='plan-delete' onClick={this.deletePlan}><img src={whiteDeleteIcon} alt="Supprimer"/></button>
					<button className='plan-modify' onClick={this.modify}>Edit</button>
					<h2>{plan.name}</h2>
					<div className={'duration'}>{plan.duration} mois</div>
					<div className={'price'}>{(plan.monthlyPrice/100).toFixed(2)}€ / mois</div>
				</header>
				{!!plan.fees &&
				<div>
					<span>{plan.fees.label} : {plan.fees.amount / 100}€</span>
					{!!plan.fees.was && <span>(au lieu de {plan.fees.was / 100}€)</span> || <span>&nbsp;</span>}
				</div>
				}
				{!plan.fees &&
				<div>
					<span>Pas de frais de dossier</span>
					<span>&nbsp;</span>
				</div>
				}

				<div style={{borderBottom:'1px solid #e5e5e5', flexGrow:'1', justifyContent:'center'}}>
					<ol>
						{plan.description.map((d, i) =>
							<li key={i}>{d}</li>
						)}
					</ol>
				</div>

				<div style={{borderBottom:'1px solid #e5e5e5', flexGrow:'1', justifyContent:'center'}}>
					<strong style={{paddingBottom:'1rem'}}>{plan.promo.default.title}</strong>
					<ol>
						{funDisplay.map( (e,i) => {
							return <li key = {i}>{e.display}</li>
						})}
					</ol>
				</div>


				<div style={{borderBottom:'1px solid #e5e5e5', flexGrow:'1', justifyContent:'center'}}>
					{plan.promo.temporary && <strong style={{paddingBottom:'1rem'}}>{plan.promo.temporary.title}</strong>}
					{plan.promo.temporary && <ol>
						{funDisplay2.map( (e,i) => {
							return <li key = {i}>{e.display}</li>
						})}
					</ol>
					}
					{!plan.promo.temporary && <strong style={{paddingBottom:'1rem'}}>Pas de promotion temporaire</strong>}
				</div>


				<p style={{fontStyle:'italic',padding:'1rem'}}>Mis à jour le {moment(plan.modification.date).format('DD/MM/YYYY à HH:mm')}</p>
			</article>
		);
	}
}
