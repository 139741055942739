import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import ColorPicker from '../components/ColorPicker';
import RichTextEditor from 'react-rte';

import editIcon from '../static/icons/picto-edit--white.svg';

const defaultToolbar = {
	display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
	INLINE_STYLE_BUTTONS: [
		{label: 'Bold', style: 'BOLD'},
		{label: 'Italic', style: 'ITALIC'},
		{label: 'Underline', style: 'UNDERLINE'}
	],
	BLOCK_TYPE_DROPDOWN: [
		{label: 'Normal', style: 'Normal'},
		{label: 'Heading Large', style: 'Titre 1'},
		{label: 'Heading Medium', style: 'Titre 2'},
		{label: 'Heading Small', style: 'Titre 3'}
	],
	BLOCK_TYPE_DROPDOWN: [
		{label: 'Normal', style: 'unstyled'},
		{label: 'Titre', style: 'header-three'},
	],
	BLOCK_TYPE_BUTTONS: [
		{label: 'UL', style: 'unordered-list-item'},
		{label: 'OL', style: 'ordered-list-item'}
	]
};
export default class AboConfig extends React.Component {

	static contextTypes = {
		place: PropTypes.object,
		user : PropTypes.object
	};

	constructor(props, context){
		super(props, context);
		this.state = {
			step:"LOADING",
			editing: false
		};
		this.reload = this.reload.bind(this);
	}

	componentDidMount(){
		this.reload();
	}

	reload(){
		this.setState({step : "LOADING", editing: false});
		API.GET(`/api/abo/abo/config?place.id=${this.context.place.id}`)
		.then(res=>{
			res.mail_message = res.mail.message || '';
			res.commission_msds = res.commission.msds;
			res.commission_external = res.commission.external;
			delete res.commission;

			this.setState({
				step:"VIEW",
				config:res,
				rulesEditor:RichTextEditor.createValueFromString(res.rules||'<p></p>','html'),
				rules : res.rules ||'',
				cgvEditor:RichTextEditor.createValueFromString(res.cgv||'<p></p>','html'),
				cgv : res.cgv||''
			});
		})
		.catch(e => {
			console.error(e, e.stack);
			this.setState({step:"ERROR"});
		});
	}

	onRulesEditorChange = (value) => {
		var text = value
		.toString('html')
		.replace(/target="_self"/g, '')
		.replace(/<a h/g, "<a target='_blank' h")
		;
		this.setState({rulesEditor:value, rules:text});
	};

	onCGVEditorChange = (value) => {
		var text = value
		.toString('html')
		.replace(/target="_self"/g, '')
		.replace(/<a h/g, "<a target='_blank' h")
		;
		this.setState({cgvEditor:value, cgv:text});
	};

	goEdit = (event)=>{
		event.preventDefault();
		this.setState({editing:true});
	};

	cancel = (event) => {
		event.preventDefault();
		this.reload();
	};

	save = (event)=>{
		event.preventDefault();
		this.setState({step : "LOADING", editing: false});
		var data = {
			colors : {
				primary:this.state.config.colors.primary || '#fff',
				secondary:this.state.config.colors.secondary || '#fff',
				header_bg :this.state.config.colors.header_bg || '#fff',
				header_text :this.state.config.colors.header_text || '#fff',
				promo :this.state.config.colors.promo || '#fff'
			},
			logoPath : this.state.config.logoPath,
			cgv : this.state.cgv||'',
			rules : this.state.rules||'',
			sepa_ics : this.state.config.sepa_ics,
			mail : {
				message : this.state.config.mail_message
			},
			commission : {
				msds : this.state.config.commission_msds,
				external : this.state.config.commission_external
			}
		};
		API.PUT(`/api/abo/abo/config?place.id=${this.context.place.id}`, data)
		.then(res=>{
			return this.reload();
		})
		.catch(e => {
			console.error(e, e.stack);
			this.setState({step:"ERROR"});
		});
	};

	onChangeColor = (name, value) =>{
		var config = Object.assign({}, this.state.config);
		config.colors[name] = value;
		this.setState({config});
	};

	onChange = (event) => {
		var config = Object.assign({}, this.state.config);
		config[event.target.name] = event.target.value;
		this.setState({ config });
	};

	render(){
		if(this.state.step === 'LOADING'){
			return <p>LOADING</p>;
		}
		if(this.state.step === 'ERROR'){
			return <p>Une erreur est survenue</p>;
		}

		return (
			<section className='club-abo-config'>
				<header>
					<h1>Paramètres de l'interface</h1>
					{!this.state.editing && <button onClick={this.goEdit} className={'button-primary button--small'}><img src={editIcon} alt="Modifier"/></button>}
				</header>
				<div>
					<fieldset>
						<h3>Couleurs</h3>
						<label className="checkbox">
							<ColorPicker color={this.state.config.colors.primary} disabled={!this.state.editing} onChangeColor={this.onChangeColor.bind(this, 'primary')}/>
							<span>Couleur dominante</span>
						</label>
						<label className="checkbox">
							<ColorPicker color={this.state.config.colors.secondary} disabled={!this.state.editing} onChangeColor={this.onChangeColor.bind(this, 'secondary')}/>
							<span>Couleur de contraste</span>
						</label>
						<label className="checkbox">
							<ColorPicker color={this.state.config.colors.header_bg} disabled={!this.state.editing} onChangeColor={this.onChangeColor.bind(this, 'header_bg')}/>
							<span>Couleur de header bg</span>
						</label>
						<label className="checkbox">
							<ColorPicker color={this.state.config.colors.header_text} disabled={!this.state.editing} onChangeColor={this.onChangeColor.bind(this, 'header_text')}/>
							<span>Couleur de header txt</span>
						</label>
						<label className="checkbox">
							<ColorPicker color={this.state.config.colors.promo} disabled={!this.state.editing} onChangeColor={this.onChangeColor.bind(this, 'promo')}/>
							<span>Couleur de promo</span>
						</label>
					</fieldset>
					<fieldset>
						<h3>Logo</h3>
						{!this.state.editing &&
						<img alt='logo' width='150' height='150' src={this.state.config.logoPath} />
						}
						{this.state.editing &&
						<input type='text' placeholder={'Adresse du logo'} onChange={this.onChange} name='logoPath' value={this.state.config.logoPath}/>
						}
					</fieldset>
				</div>
				{this.context.user.isAdmin &&
				<fieldset>
					<h3>Commission (en %)</h3>
						<label>
							<span>MSDS</span>
							<input disabled={!this.state.editing} type='text' placeholder={''} onChange={this.onChange} name='commission_msds' value={this.state.config.commission_msds}/>
						</label>
						<label>
							<span>Externe</span>
							<input disabled={!this.state.editing} type='text' placeholder={''} onChange={this.onChange} name='commission_external' value={this.state.config.commission_external}/>
						</label>
					}
				</fieldset>
				}
				<fieldset>
					<h3>Banque</h3>
					<input disabled={!this.state.editing} type='text' placeholder={'Votre banque'} onChange={this.onChange} name='sepa_ics' value={this.state.config.sepa_ics}/>
				</fieldset>
				<fieldset className={'club-abo-texts'}>
					<h3>Textes</h3>

					<strong>Conditions Générales de Vente (CGV)</strong>
					<RichTextEditor height='10' value={this.state.cgvEditor} toolbarConfig={this.defaultToolbar} onChange={this.onCGVEditorChange} readOnly={!this.state.editing}/>

					<strong>Règlement intérieur (facultatif)</strong>
					<RichTextEditor value={this.state.rulesEditor} toolbarConfig={this.defaultToolbar} onChange={this.onRulesEditorChange} readOnly={!this.state.editing}/>
				</fieldset>
				<fieldset>
					<label>
						<strong>Email envoyé aux inscrits</strong>
						<p>Vous pouvez inclure des modalités pratiques dans l'email qui sera reçu par vos prospects.</p>
						<textarea disabled={!this.state.editing} style={{resize:'none', width:'80%', margin:'auto'}} rows='10' name='mail_message' value={this.state.config.mail_message} onChange={this.onChange}/>
					</label>
				</fieldset>
				{this.state.editing &&
				<footer>
					<button onClick={this.cancel}>Annuler</button>
					<button onClick={this.save} className={'button-primary'}>Sauvegarder</button>
				</footer>
				}
			</section>
		);
	}
}