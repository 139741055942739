import React from 'react';
import PropTypes from 'prop-types';
import WidgetConfiguration from '../containers/WidgetConfiguration';
import WidgetOfferConfiguration from '../containers/WidgetOfferConfiguration';

class Widget extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render() {
		const placeId = this.context.place.id;

		return <section className="club-widget-page">
			<WidgetHelp placeId={placeId} />
			<WidgetConfiguration />
			<WidgetOfferConfiguration />
		</section>
	}
}

class WidgetHelp extends React.Component {

	render() {
		const {placeId} = this.props;

		return <section className="widget-help-page">
				<h1 className="pageTitle">Explications</h1>
				<p>Le widget de MaSalleDeSport est un système configurable vous permettant de récolter des avis et des prospects via votre propre site internet.</p>
				<p>Pour mettre en place le widget, demandez à votre webmaster de rajouter le script ci-dessous dans le code source de votre site :</p>
				<code>
					&lt;<span className="tag">script</span> <span className="attr">src</span>=<span className="value">"https://widget.masalledesport.com/api/widget/{placeId}/js?configFrom={placeId}"</span>&gt;&lt;/<span className="tag">script</span>&gt;
				</code>
				<p>Il ne vous reste qu'à configurer vos options ci-dessous.</p>
		</section>
	}

}
export default Widget;
