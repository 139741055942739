import React from 'react';
import PropTypes from 'prop-types';
import {civilites} from '../utils/civilites';
import MagicForm from '../components/magicForm';
import InputValidator from '../components/inputValidator';

const notEmptyValidator = (value) => {
	const cleaned = value.replace(/^\s+/, '');
	const valid = cleaned.trim() !== "";
	return {cleaned, valid};
};

let regex = /^[a-z0-9!#$%&'*+/=?^_`{|}./-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}./-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
const emailValidator = (value) => {
	let cleaned = value.trim();
	const valid = regex.test(cleaned);
	return {cleaned, valid};
};

class ClubResponsable extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		placeUpdate: PropTypes.func.isRequired
	};

	constructor(props, context){
		super(props, context);

		this.onChange = this.onChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.onCancelEdit = this.onCancelEdit.bind(this);
		this.onEdit = this.onEdit.bind(this);
		this.responsableToState = this.responsableToState.bind(this);

		let responsable = this.context.place.responsable;

		this.state = this.responsableToState(responsable);
	}

	responsableToState(responsable) {
		let state = {
			form: {
				civilite: {value: responsable.civilite}
				, firstname: {value: responsable.firstname ||'', validator: notEmptyValidator}
				, lastname: {value: responsable.lastname||'', validator: notEmptyValidator}
				, job: {value: responsable.job||'', validator: notEmptyValidator}
				, email: {value: responsable.email||'', validator: emailValidator}
				, phone: {value: responsable.phone||'', validator: notEmptyValidator}
			}
			, editing: false
		};

		Object.keys(state.form).forEach((k) => {
			if (!state.form[k].validator) {
				return;
			}
			let validation = state.form[k].validator(state.form[k].value);
			state.form[k].value = validation.cleaned;
			state.form[k].valid = validation.valid;
		});

		return state;
	}

	render() {
		return <ClubResponsableSimple
			editing={this.state.editing}
			civilites={civilites}
			onSubmit={this.onSubmit}
			onChange={this.onChange}
			onCancelEdit={this.onCancelEdit}
			onEdit={this.onEdit}
			civilite={this.state.form.civilite}
			firstname={this.state.form.firstname}
			lastname={this.state.form.lastname}
			job={this.state.form.job}
			email={this.state.form.email}
			phone={this.state.form.phone}/>;
	}

	onSubmit(event) {
		event.preventDefault();
		event.stopPropagation();

		let errors = Object
			.keys(this.state.form)
			.filter((k) => {
				return this.state.form[k].valid === false;
			})
		;
		if (errors.length !== 0) {
			return;
		}

		let formData = {};
		Object.keys(this.state.form).filter((k) => {
			return formData[k] = this.state.form[k].value;
		});

		this.context.placeUpdate({responsable: formData})
		.then((reponse) => {
			this.setState({editing: false});
		})
		.catch((error) => {
			//TODO gérer l'erreur... validation?
		});
	}

	onEdit(event) {
		event.preventDefault();
		this.setState({editing: true});
	}

	onCancelEdit(event) {
		event.preventDefault();
		event.stopPropagation();

		let responsable = this.context.place.responsable;
		this.setState(this.responsableToState(responsable));
	}

	onChange(event) {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = {
			value: event.target.value,
			valid: event.target.valid,
			validator: newState.form[event.target.name].validator
		};
		this.setState(newState);
	}

}

export default ClubResponsable;


const ClubResponsableSimple = (props) => {
	return (
		<MagicForm
			title="Responsable"
			formClass="club-manager-form"
			editing={props.editing}
			onCancelEdit={props.onCancelEdit}
			onSubmit={props.onSubmit}
			onEdit={props.onEdit}
		>
			<div>
				<label>
					<span className="picto-perso">Contact</span>
					<div className="inputs-group">
						<select name="civilite" value={props.civilite.value} onChange={props.onChange}>
							{
								props.civilites.map((civ) => {
									return <option key={`civilite-${civ.code}`} value={civ.code}>{civ.name}</option>
								})
							}
						</select>
						<InputValidator name="firstname" placeholder="Prénom" value={props.firstname.value}
						                valid={props.firstname.valid} onChange={props.onChange}
						                validator={props.firstname.validator}/>
						<InputValidator name="lastname" placeholder="Nom" value={props.lastname.value}
						                valid={props.lastname.valid} onChange={props.onChange}
						                validator={props.lastname.validator}/>
					</div>
				</label>

				<label>
					<span className="picto-job">Fonction</span>
					<InputValidator name="job" placeholder="Fonction" value={props.job.value} valid={props.job.valid}
					                onChange={props.onChange} validator={props.job.validator}/>
				</label>
				<div className="inputs-group">
					<label>
						<span className="picto-mail">Email</span>
						<InputValidator name="email" placeholder="exemple@domaine.fr" value={props.email.value}
						                valid={props.email.valid} onChange={props.onChange}
						                validator={props.email.validator}/>
					</label>
					<label>
						<span className="picto-tel">Téléphone</span>
						<InputValidator name="phone" placeholder="Téléphone" value={props.phone.value}
						                valid={props.phone.valid} onChange={props.onChange}
						                validator={props.phone.validator}/>
					</label>
				</div>
			</div>
		</MagicForm>
	);
};
