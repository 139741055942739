import React from 'react';
import PropTypes from 'prop-types';


class MetaGoogleAds extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render() {
		return (
			<section style={{display:'flex', flexDirection:'column'}}>
				<section className='widget-help-page' style={{marginBottom:"1rem"}}>
					<h1 style={{marginBottom:"1rem"}}>Explication</h1>
					<p>Utilisez ces urls dans vos publicités Meta / Google</p>

					<strong>Meta</strong>
					<code>
						https://www.masalledesport.com/try/meta/{this.context.place.id}
					</code>

					<strong>Google</strong>
					<code>
						https://www.masalledesport.com/try/google/{this.context.place.id}
					</code>
				</section>
			</section>
		);
	}
}

export default MetaGoogleAds;
