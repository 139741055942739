import React from 'react';
import PropTypes from 'prop-types';
import ExternalPageOAuthConfigurator from '../containers/ExternalPageOAuthConfigurator';

import facebookLogo from '../static/logos/facebook.svg';
import facebookLogoWhite from '../static/logos/facebook--white.svg';

class FacebookOAuthConfigurator extends React.Component {
	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	render(){
		let clubId = this.context.place.id;
		let state = encodeURIComponent(btoa(clubId));
		let redirectUrl = `https://www.facebook.com/v2.10/dialog/oauth`
			+ `?client_id=${process.env.REACT_APP_FACEBOOK_APP_CLIENT_ID}`
			+ `&response_type=token`
			+ `&scope=email,manage_pages`
			+ `&redirect_uri=${process.env.REACT_APP_THIS_URL}/oauth/facebook`
			+ `&state=${state}`
		;

		let external = {
			name : "facebook",
			display :"Facebook",
			className:"facebook-configurator",
			icon: {
				alt: "FB",
				url : facebookLogo
			},
			connectionIcon: {
				alt: "FB",
				url : facebookLogoWhite
			},
			getRedirectUrl : (state)=> {return redirectUrl;}
		};
		return <ExternalPageOAuthConfigurator clubId = {clubId} external = {external}/>;
	}
}


export default FacebookOAuthConfigurator;
