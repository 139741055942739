import React from 'react';
import MultiStats from './MultiStats';
import MultiStatsVel from './MultiStatsVel';
import MultiLeads from './MultiLeads';

class MultiLeadsContainer extends React.Component {
	render() {
		return (
			<div className="multi-dashboard-page">
				<section className="multi-dashboard-charts">
					<div style={{width:'100%'}}>
						<h2>Vos leads</h2>
						<div style={{display:'flex',flexDirection:'row'}}>
							<div style={{width:'50%'}}><MultiStats/></div>
							<div style={{width:'50%'}}><MultiStatsVel/></div>
						</div>
					</div>
				</section>
				<MultiLeads />
			</div>
		);
	}

}

export default MultiLeadsContainer;
