import React from 'react';
import API from '../utils/api';
import Pagination from '../components/pagination';

import downloadIcon from '../static/icons/picto-download.svg';

class MultiInvoices extends React.Component {

	constructor(props){
		super(props);
		this.state = {
			step : "LOADING",
			selectPeriod : null,
			page : 1,
			placeName : ""
		};
		this.onChange = this.onChange.bind(this);
		this.changePage = this.changePage.bind(this);
	}

	changePage(event){
		event.preventDefault();
		this.setState({page: parseInt(event.target.dataset.page)});
	}

	onChange(event){
		this.setState({[event.target.name] : event.target.value, page:1});
	}

	componentDidMount(){
		API.GET(`/invoice`)
		.then(invoices=>{
			if(invoices === null){
				this.setState({step:"NOTHING"});
				return;
			}
			var periods = invoices.map(i=>i.period).filter((v, i, a) => a.indexOf(v) === i).sort((a,b)=>{if(a>b)return -1; if(a<b) return 1; return 0});
			var selectPeriod = periods.length && periods[0];
			this.setState({step:"OK", invoices: invoices, periods : periods, selectPeriod:selectPeriod, page:1});
		})
		.catch(e=>{
			console.error(e);
			this.setState({step:"ERROR"});
		})
		;
	}

	render(){
		if(this.state.step === "LOADING"){
			return <p>Loading</p>;
		}
		if(this.state.step === "ERROR"){
			return <p>Error</p>;
		}
		if(this.state.step === "NOTHING"){
			return <p>Il semble que vous n'ayez accès à aucun club</p>;
		}
		if(!this.state.invoices.length){
			return <p>Pas de facture</p>;
		}

		const matching = this.state.invoices
		.filter(i=>i.period === this.state.selectPeriod)
		.filter(i=>!this.state.placeName.trim() || i.place.name.toLowerCase().replace(/ /g, '').indexOf(this.state.placeName.toLowerCase().replace(/ +/g, '')) !== -1);

		const nbPerPage = 10;
		const first = (this.state.page-1)*nbPerPage;
		const last = this.state.page *nbPerPage;
		const pageMax = Math.ceil(matching.length / nbPerPage );

		const diplayed = matching.slice(first,last);

		return <section className="multi-dashboard-invoices">
			<h2>Vos factures</h2>
			<form onSubmit={(e)=>e.preventDefault()}>
				<label>
					<span>Periode</span>
					<select name="selectPeriod" onChange={this.onChange}>
					{this.state.periods.map(p=>{
						return <option key={p} value={p}>{p}</option>
					})}
					</select>
				</label>
				<label>
					<span>Nom du club</span>
					<input type="text" placeholder="Nom du club" name="placeName" onChange={this.onChange} />
				</label>
			</form>

			<Pagination currentPage={this.state.page} maxPage = {pageMax} onClick={this.changePage} baseUrl='/clubs/invoices?'/>
			<table>
				<thead>
					<tr>
						<th>Club</th>
						<th>Numéro</th>
						<th>Télécharger</th>
					</tr>
				</thead>
				<tbody>
				{diplayed
					.map(i=>{
					return <tr key={i.id}>
						<td>{i.place.name}</td>
						<td>Facture n° {i.name}</td>
						<td>
							<a target="_blank" rel="noopener noreferrer" download href={i.url} className="button-download">
								<img src={downloadIcon} alt="Téléchargement"/>
							</a>
						</td>
					</tr>
				})}
				</tbody>
			</table>
		</section>;
	}
}

export default MultiInvoices;
