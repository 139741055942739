import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../components/loader';
import API from '../utils/api';
import MagicForm from '../components/magicForm';
import moment from 'moment';
import ColorPicker from '../components/ColorPicker';

import whiteUserAddIcon from '../static/icons/picto-user-add--white.svg';
import whiteEditIcon from '../static/icons/picto-edit--white.svg';
import userRemoveIcon from '../static/icons/picto-user-remove.svg';
import SimpleImageCropper from "../components/SimpleImageCropper";

// @TODO (sinewyk): put it in a single spot somewhere
const allowedRolesForEdit = [
	'ADMIN',
	'MANAGER',
	'COMMERCIAL',
	'COACH',
	'BASIC',
];

const allowedRolesForCreate = [
	'MANAGER',
	'COMMERCIAL',
	'COACH',
	'BASIC',
];

class Users extends React.Component {
	static contextTypes = {
		user: PropTypes.object.isRequired,
		place: PropTypes.object.isRequired,
	};

	constructor(props, context) {
		super(props, context);
		this.state = {
			step: 'LOADING',
		};
	}

	componentDidMount() {
		this.reload();
	}

	onChange = (event) => {
		let newState = Object.assign({}, this.state);
		newState.form[event.target.name] = event.target.value;
		this.setState(newState);
	};

	onColorChange = (color) => {
		let newState = Object.assign({}, this.state);
		newState.form.color = color;
		this.setState(newState);
	};

	onCCRightChange = (event) => {
		const right = event.target.name;
		const newState = Object.assign({}, this.state);
		if( newState.form.clubConnectRights.indexOf( right) === -1 ) {
			newState.form.clubConnectRights.push(right);
			return this.setState(newState);
		} else {
			newState.form.clubConnectRights = newState.form.clubConnectRights.filter( e => e !== right);
			return this.setState(newState);
		}
	};


	addUser = (event) => {
		event.preventDefault();
		this.setState({
			step: 'ADD',
			form: { email: '', firstname: '', lastname: '', role: 'MANAGER', clubConnectRights:[] },
		});
	};

	addUserDo = e => {
		e.preventDefault();
		API.POST(`/user/me/club/${this.context.place.id}/user`, {
			firstname: this.state.form.firstname.trim(),
			lastname: this.state.form.lastname.trim(),
			email: this.state.form.email.trim().toLowerCase(),
			role: this.state.form.role,
			clubConnectRights: this.state.form.clubConnectRights,
			color: this.state.form.color || null
		})
			.then(() => {
				this.reload();
			})
			.catch((e) => {
				console.error(e, e.stack);
				this.setState({step:'ERROR'});
			});
	};

	cancelAddUser = (event) => {
		event.preventDefault();
		this.setState({ step: 'OK' });
	};

	removeUser = (userId, event) => {
		event.preventDefault();
		let result = window.confirm(
			'Êtes-vous sûr de vouloir supprimer cet utilisateur ?'
		);
		if (!result) {
			return;
		}

		API.DELETE(`/user/me/club/${this.context.place.id}/user/${userId}`)
			.then((res) => {
				this.reload();
			})
			.catch((e) => {
				console.error(e, e.stack);
				this.setState({step:'ERROR'});
			});
	};

	edit = (u) => (e) => {
		e.preventDefault();
		this.setState({
			step: 'EDIT',
			form: {
				id: u.id,
				role: u.role,
				clubConnectRights: u.clubConnectRights,
				color: u.color,
				displayName: u.displayName,
				avatar: u.avatar,
				avatar_url: u.avatar_url
			},
		});
	};

	patchCall = () => {
		API.PATCH(
			`/user/me/club/${this.context.place.id}/user/${this.state.form.id}`,
			{
				role: this.state.form.role,
				clubConnectRights: this.state.form.clubConnectRights,
				color: this.state.form.color || '#ffffff',
				displayName: this.state.form.displayName,
				avatar: this.state.form.avatar
			}
		)
		.then(() => {
			this.reload();
		})
		.catch((e) => {
			console.error(e, e.stack);
			this.setState({step:'ERROR'});
		});
	}
	doPatch = (e) => {
		e.preventDefault();
		this.patchCall();
	};

	reload() {
		API.GET(`/user/me/club/${this.context.place.id}/user`)
			.then((res) => {
				this.setState({
					step: 'OK',
					users: res,
				});
			})
			.catch((e) => {
				console.error(e, e.stack);
				this.setState({step:'ERROR'});
			});
	}

	setAvatar = (path) => {
		this.setState({ ...this.state, form: { ...this.state.form, avatar: path } });
		this.patchCall();
	};
	deleteAvatar = () => {
		if ( !window.confirm("Etes-vous sûr de vouloir supprimer l'avatar ?") ) return ;

		const form = this.state.form;
		form.avatar = null;
		this.setState({ ...this.state, form });
		this.patchCall();
	};

	render() {
		const ccRights = [
			['moduleManager', 'clubSubscription', 'general', 'statistics', 'user', 'illustration'],
			['notification', 'parrainage', 'partner', 'shop', 'blog'],
			['planning','concept', 'interoperability'],
			['exercice', 'entrainement', 'packs', 'trainingReport', 'formReport', 'nutrition'],
			['streaming', 'live'],
			['adherent', 'vente', 'selling', 'subscription'],
		];

		const writePermission =
			this.context.place.role === 'ADMIN' ||
			this.context.place.role === 'MANAGER' ||
			this.context.user.isAdmin;

		if (this.state.step === 'LOADING') {
			return <Loader title="Utilisateurs" />;
		}

		if (this.state.step === 'ERROR') {
			return <p>Une erreur est survenue</p>;
		}

		if (this.state.step === 'OK') {
			return (
				<section className="club-users-page">
					<header>
						<h1>Utilisateurs</h1>
						<button
							onClick={this.addUser}
							className="button-add"
							title="Ajouter un utilisateur"
						>
							<img src={whiteUserAddIcon} alt="Ajouter" />
						</button>
					</header>
					<div className="club-users-content">
						<p>
							Vos utilisateurs en attente doivent demander un changement de mot
							de passe sur l'interface avant de pouvoir s'y connecter.
						</p>
						<table className="club-users-table">
							<thead>
								<tr>
									<th>Login</th>
									<th>Role</th>
									<th>Prénom</th>
									<th>Nom</th>
									<th>Email</th>
									<th>Status</th>
									<th>Dernière connexion</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{this.state.users.map((u) => {
									const canDelete =
										writePermission &&
										(this.context.user.id !== u.id ||
											this.context.user.isAdmin);

									const canEdit =
										this.context.place.role === 'ADMIN' ||
										this.context.user.isAdmin;
									return (
										<UserLine
											key={u.id}
											user={u}
											removeUser={canDelete && this.removeUser.bind(this, u.id)}
											edit={canEdit && this.edit}
										/>
									);
								})}
							</tbody>
						</table>
					</div>
				</section>
			);
		}

		if (this.state.step === 'ADD') {
			return (
				<section className="club-users-page">
					<header>
						<h1>Utilisateurs</h1>
					</header>
					<div className="club-users-add">
						<p>Ajoutez un nouvel utilisateur pour la gestion de votre club.</p>
						<MagicForm
							editing={true}
							title="Ajouter un nouvel utilisateur"
							onCancelEdit={this.cancelAddUser}
							onSubmit={this.addUserDo}
							className="club-users-form"
						>
							<div>
								<label>
									<span className="picto-perso">Identité</span>
									<div className="inputs-group">
										<input
											type="text"
											name="firstname"
											value={this.state.firstname}
											placeholder="Prénom"
											onChange={this.onChange}
										/>
										<input
											type="text"
											name="lastname"
											value={this.state.lastname}
											placeholder="Nom"
											onChange={this.onChange}
										/>
									</div>
								</label>
								<label>
									<span className="picto-mail">Email</span>
									<input
										type="text"
										name="email"
										value={this.state.email}
										placeholder="Email"
										onChange={this.onChange}
									/>
								</label>
								<label>
									<span className="picto-club-type">Role</span>
									<select
										name="role"
										onChange={this.onChange}
										value={this.state.form.role}
									>
										{(this.context.place.role === 'ADMIN' || this.context.user.isAdmin) && <option key={'ADMIN'} value={'ADMIN'}>
												ADMIN
											</option>}
										{allowedRolesForCreate.map((r) => (
											<option key={r} value={r}>
												{r}
											</option>
										))}
									</select>
								</label>
								<label>
									<span>Couleur</span>
									<ColorPicker name="color" color={this.state.color || '#ffffff'} onChangeColor={this.onColorChange} value={this.state.form.color}/>
								</label>
							</div>

							<RoleExplanation/>

							<div>
								<br/>
								<h2>Droits sur ClubConnect</h2>
								<br/>
								<div style={{display:'flex', flexDirection:'row', marginBottom: '1rem', gap: '2rem', justifyContent: 'space-between'}}>
									{ccRights.map( subR => (
										<div style={{display:'flex', flexDirection:'column'}}>
											{subR.map((r) => (
												<label style={{display:'flex', flexDirection:'row'}} key={r}>
													<input type='checkbox' name={r} checked={this.state.form.clubConnectRights.includes(r) ? 'checked' : ''} onChange={this.onCCRightChange}/>
													<span>{r}</span>
												</label>
											))}
										</div>
									))}
								</div>
							</div>
						</MagicForm>

					</div>
				</section>
			);
		}

		if (this.state.step === 'EDIT') {
			const user = this.state.users.filter(
				(x) => x.id === this.state.form.id
			)[0];
			return (
				<section className="club-users-page">
					<header>
						<h1>Changer les droits de {user.login}</h1>
					</header>
					<div className="club-users-add">
						<MagicForm
							editing={true}
							onCancelEdit={this.cancelAddUser}
							onSubmit={this.doPatch}
							className="club-users-form"
						>
							<div>
								<div>
									<br/>
									<h2>Information utilisateur</h2>
									<br/>
									<label>
										<span className="">Nom</span>
										<input
											name="displayName"
											onChange={this.onChange}
											value={this.state.form.displayName || ""}
											type={'text'}
										/>
									</label>
								</div>

								<label>
									<span>Couleur</span>
									<ColorPicker name="color" color={this.state.color || '#ffffff'} onChangeColor={this.onColorChange} color={this.state.form.color}/>
								</label>

								<label>
									<span className="picto-club-type">Role</span>
									<select
										name="role"
										onChange={this.onChange}
										value={this.state.form.role}
									>
										{allowedRolesForEdit.map((r) => (
											<option key={r} value={r}>
												{r}
											</option>
										))}
									</select>
								</label>

							</div>
						</MagicForm>
						<label>
							<h3 className="">Avatar</h3>
							<SimpleImageCropper currentValue={ this.state.form.avatar_url } onDelete={this.deleteAvatar} onChange={this.setAvatar} />
						</label>
						<MagicForm
							title={"Droits sur ClubConnect"}
							editing={true}
							onCancelEdit={this.cancelAddUser}
							onSubmit={this.doPatch}
							className="club-users-form"
						>
							<section>
								<div style={{display:'flex', flexDirection:'row', marginBottom: '1rem', gap: '2rem', justifyContent: 'space-between'}}>
									{ccRights.map( subR => (
										<div style={{display:'flex', flexDirection:'column'}}>
											{subR.map((r) => (
												<label style={{display:'flex', flexDirection:'row'}} key={r}>
													<input type='checkbox' name={r} checked={this.state.form.clubConnectRights.includes(r) ? 'checked' : ''} onChange={this.onCCRightChange}/>
													<span>{r}</span>
												</label>
											))}
										</div>
									))}
								</div>
							</section>
						</MagicForm>

						<RoleExplanation/>
					</div>
				</section>
			);
		}
	}
}

function convert(status) {
	switch (status) {
		case "OK" :
			return "Actif";
		case "WAITING_REGISTER":
			return "En attente de son inscription";
		default :
			console.error("Unknow status:", status);
			return "inconnu-" + status;
	}
}

const UserLine = props => {
	const u = props.user;
	return <tr>
		<td>{u.login}</td>
		<td>{u.role}</td>
		<td>{u.firstname}</td>
		<td>{u.lastname}</td>
		<td>{u.email}</td>
		<td>{convert(u.status)}</td>
		<td>{u.lastLogin && moment(new Date(u.lastLogin)).format("DD/MM/YYYY à HH:MM")}</td>
		<td>
			{props.removeUser &&
			<button className="button-delete" onClick={props.removeUser}>
				<img src={userRemoveIcon} alt="Supprimer"/>
			</button>}
			{props.edit && <button className="button-edit" onClick={props.edit(u)}>
				<img src={whiteEditIcon} alt="Modifier"/>
			</button>}
		</td>
	</tr>;
};

const RoleExplanation = () => (
	<table>
		<thead>
			<tr><th>Role</th><th>Description</th></tr>
		</thead>
		<tbody>
			<tr><td>ADMIN</td><td>Accès total à toutes les fonctionnalités</td></tr>
			<tr><td>MANAGER</td><td>Accès total mais ne peut pas créer/modifier des comptes ADMIN</td></tr>
			<tr><td>COMMERCIAL</td><td>Accès en lecture seule et créer / modifier la Gestion Clientèle</td></tr>
			<tr><td>BASIC</td><td>Accès en lecture seule</td></tr>
		</tbody>
	</table>
);

export default Users;
