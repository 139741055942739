import React from 'react';
import News from '../containers/News';
import PlaceInfo from '../containers/PlaceInfo';
import SubscriptionIncitation from '../containers/SubscriptionIncitation';

class Dashboard extends React.Component {

	render() {
		return (
			<SubscriptionIncitation>

				<section className="dashboard-page">
					<PlaceInfo />
					<section>
						Afin d'assurer une navigation rapide pour les utilisateurs, MaSalleDeSport dispose d'un cache serveur. La contrepartie est que les changements sur cette interface peuvent prendre 1h pour être visibile sur le site web.
					</section>
					{/* Removed because not used by the sales team */}
					{/* <section className="utils"> */}
					{/* 	<News/> */}
					{/* </section> */}
				</section>

			</SubscriptionIncitation>
		);
	}
}


export default Dashboard;
