import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';
import SuperComponent from '../containers/SuperComponent';
import queryString from 'query-string';
import moment from 'moment';

class Sales extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired,
		applicationDatas : PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);
		this.doAction = this.doAction.bind(this);
		this.state =  { page: queryString.parse(this.props.location.search).page || 1 };
	}

	componentWillReceiveProps(nextProps, oldsProps){
		if(nextProps.location.search && queryString.parse(nextProps.location.search).page){
			if(this.state.page !== queryString.parse(nextProps.location.search).page){
				this.setState({page:queryString.parse(nextProps.location.search).page});
			}
		}
	}

	doAction(page){
		// return API.GET(`/user/me/club/${this.context.place.id}/sale?page=${this.state.page}`)
		return API.GET(`/sale?page=${this.state.page}&placeIds=${this.context.place.id}`)
	}


	render() {
		let placeId = this.context.place.id;
		let productDescription = this.context.applicationDatas.productDef;
		return (
			<SuperComponent
				clubId={placeId}
				title="Vos ventes"
				page={this.state.page}
				specificClassName="club-sales-page"
				doAction ={this.doAction}
				pagination={{baseUrl : `${this.props.match.url}?`}}
			>
				<SalesContainer productDescription={productDescription} />
			</SuperComponent>
		);
	}

}

const SalesContainer = (props)=>{
	let sales = props.values;
	if(sales.length === 0){
		return <p className="msds-advice">Vous n'avez pas encore de ventes sur votre salle.</p>;
	}
	return <section>
		<table>
			<thead>
				<tr>
					<th>Date</th>
					<th>Référence</th>
					<th>Client</th>
					<th>Email</th>
					<th>Téléphone</th>
					<th>Produit</th>
					<th>Prix</th>
				</tr>
			</thead>
			<tbody>
			{ sales.map((sale)=>{
				return <SaleLine key={`sale_${sale.id}`} sale = {sale} productDescription={props.productDescription} />
			})}
			</tbody>
		</table>
	</section>
	;
};

const SaleLine = (props) => {
	const sale = props.sale;
	const product = sale.product;


	const productDescription = props.productDescription;
	let productTitle = product.duration.value;
	let typeAsDisplay = productDescription.types
	.filter(t=>{
		return t.code === product.duration.type;
	})[0].display;
	productTitle +=" " +typeAsDisplay;

	if(product.duration.type === "CLASS"){
		productTitle += " de "+ product.duration.subValue+" minutes";
	}


	return <tr>
		<td>{moment(sale.creation).format("DD/MM/YYYY")}</td>
		<td>{sale.reference}</td>
		<td>{sale.client.firstname} {sale.client.lastname}</td>
		<td>{sale.client.email}</td>
		<td>{sale.client.phone}</td>
		<td>{productTitle}</td>
		<td>{sale.price} €</td>
	</tr>;
};

export default Sales;
