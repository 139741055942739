import React from 'react';
import { Link } from 'react-router-dom';

export default props => {
	var goal = props.goal || "accéder à cette fonctionnalité.";

	return (
		<section className="msg-page">
			<div className="upgrade">
				<h1 className="pageTitle">{props.title}</h1>
				<div>
					<p>
						Prenez une souscription <Link to={`/club/${props.clubId}/subscription`}>{props.plan.display}</Link> pour {goal}
						{props.children}
					</p>
				</div>
			</div>
		</section>
	);
};