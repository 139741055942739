import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import API from '../utils/api';
import Loader from '../components/loader';
import moment from 'moment';
import queryString from 'query-string';

import calendarIcon from '../static/icons/picto-calendar.svg';

class Stats extends React.Component {
	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	constructor(props, context){
		super(props, context);
		this.state = {
			step : "LOADING",
			year : queryString.parse(this.props.location.search).year || (moment().format("YYYY"))
		};
	}

	componentWillReceiveProps(nextProps, oldsProps){
		if(nextProps.location.search && queryString.parse(nextProps.location.search).year){
			if(this.state.year !== queryString.parse(nextProps.location.search).year){
				this.reload(queryString.parse(nextProps.location.search).year);
			}
		}
	}

	componentDidMount() {
		this.reload(this.state.year);
	}

	reload =(year) => {
		this.setState({"step":"LOADING", year : year});
		API.GET(`/user/me/club/${this.context.place.id}/stats?year=${year || moment().format("YYYY")}`)
		.then((res)=>{
			this.setState({step:"SUCCESS", stats : res, error : null});
		})
		.catch((e)=>{
			console.error(e);
			this.setState({step:"ERROR"});
		});
	};

	tabs = () => {
		let thisYear = +(moment().format("YYYY"));
		let placeId = this.context.place.id;

		return (
			<ul className="tabs stats-tabs">
				<li><img src={calendarIcon} alt="Année"/></li>
				{
					[0, 1, 2, 3, 4].map((i) => {
						let year = thisYear - i;
						let p = (+this.state.year === year) ? "active" : "";
						return (
							<li key={`statsYear_${i}`}>
								<Link className={p} to={`/club/${placeId}/stats?year=${year}`}>{year}</Link>
							</li>
						);
					})
				}
			</ul>
		);
	};

	render() {
		if(this.state.step === "LOADING"){
			return <Loader className="msg-page club-loading" title="Statistiques"/>;
		}

		if(this.state.step === "ERROR") {
			return <p>Une erreur est survenue</p>;
		}

		return (
			<section className="club-stats-page">
				<h1 className="pageTitle">Statistiques</h1>

				{this.tabs()}
				<div className="tabs-content club-stats-table">
					<table className="statistics">
						<thead>
						<tr>
							<th></th>
							{
								months.map((month)=>{
									return <th key={`header_${month.code}`}>{month.display}</th>;
								})
							}
						</tr>
						</thead>
						<tbody>
						{statTypes.map((type)=>{
							return <StatsLine
								key={`statLine${type.display}`}
								stats={this.state.stats}
								type={type}  />
								;
						})}
						</tbody>
					</table>
				</div>
			</section>
		);
	}
}

function StatsLine(props){
	return (
		<tr>
			<th>{props.type.display}</th>
			{
				months.map((m)=>{
					let sum = null;
					props.type.codes.forEach((code)=>{
						if(props.stats[code] && props.stats[code][m.code]){
							sum = sum || 0;
							sum += props.stats[code][m.code];
						}
					});
					return <td key={`stat_${m.display}`}>{sum}</td>;
				})
			}
		</tr>
	)
}

const months = [
	{code:"JANUARY", display:"Janv."}
	,{code:"FEBRUARY", display:"Fév."}
	,{code:"MARCH", display:"Mars"}
	,{code:"APRIL", display:"Avril"}
	,{code:"MAY", display:"Mai"}
	,{code:"JUNE", display:"Juin"}
	,{code:"JULY", display:"Juil."}
	,{code:"AUGUST", display:"Aout"}
	,{code:"SEPTEMBER", display:"Sept."}
	,{code:"OCTOBER", display:"Oct."}
	,{code:"NOVEMBER", display:"Nov."}
	,{code:"DECEMBER",  display:"Déc."}
];

// They seems useless
// ,{code : "nb_visites_formules", display :""}
// ,{code : "nb_clics_site", display :""}
const statTypes = [
	{ display :"Consultations de la fiche", codes : ["nb_consultations"] }
	,{ display :"Affichages dans les résultats", codes : ["nb_affichages_resultats"] }
	,{ display :"Affichages du téléphone", codes : ["nb_visites_page_contact"] }
	,{ display :"Demandes d'informations", codes : ["nb_demandes_devis","nb_demandes_information"] }
	,{ display :"Demandes d'essais & Leads", codes : ["nb_demandes_essais_gratuits","nb_demandes_essais_payants"] }
];

export default Stats;
