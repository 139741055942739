import React from 'react';
import {Link} from 'react-router-dom';

export default props => {
    let min = +(props.currentPage) - 3
    let max = +(props.currentPage) + 3;

    while (max > props.maxPage) {
        max--;
        min--;
    }
    while (min < 1) {
        max++;
        min++;
    }
    max = max > props.maxPage ? props.maxPage : max;

    let links = [];
    for (let i = min; i <= max; i++) {
        links.push(i);
    }
    return (
        <div className="pagination-container">
            {props.resultsCount !== undefined &&
            <span className="pagination-results-count">{props.resultsCount} résultats</span>
            }
            <nav className={props.maxPage <= 1 ? "pagination-nav--hidden" : "pagination-nav"}>
                <ul>
                    {min !== 1 && (
                        <li key="1">
                            <Link onClick={props.onClick} data-page={1} className={props.currentPage === 1 ? "active":""} to={`${props.baseUrl}page=1`}>1</Link>
                            <span className="pagination-ellipsis--begin">...</span>
                        </li>
                    )}
                    {links.map((l) => {
                        return (
                            <li key={l}>
                                <Link onClick={props.onClick} data-page={l} className={props.currentPage === l ? "active":""} to={`${props.baseUrl}page=${l}`}>{l}</Link>
                            </li>
                        )
                    })}
                    {max !== props.maxPage && (
                        <li key="max">
                            <span className="pagination-ellipsis--end">...</span>
                            <Link onClick={props.onClick} data-page={props.maxPage} className={props.currentPage === props.maxPage ? "active":""}  to={`${props.baseUrl}page=${props.maxPage}`}>{props.maxPage}</Link>
                        </li>
                    )}
                </ul>
            </nav>
        </div>
    );
}
