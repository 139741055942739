import React from 'react';
import { NavLink } from 'react-router-dom';

export default function ClubMenu(props) {
	let links = props.links;

	return (
		<ul className="clubMenu">
			{links.map((l) => (
				<li key={`menuLink-${l.url}`}>
					{l.external ? (
						<a href={l.url} target='_blank'>{l.name}</a>
					) : (
						<NavLink to={l.url}>
							<span>{l.name}</span>
							{l.alert && <span className="label">{l.alert}</span>}
						</NavLink>
					)}
				</li>
			))}
		</ul>
	);
}
