import React from 'react';
import PropTypes from 'prop-types';
import API from '../utils/api';

class ProspectsExport extends React.Component {

	static contextTypes = {
		place: PropTypes.object.isRequired
	};

	export = (event) => {
		event.preventDefault();
		return API.PUT(`/user/me/club/${this.context.place.id}/contact`)
		.then(res=>{
			window.open(res.url);
		})
		.catch(e=>{
			console.error(e, e.stack);
		})
		;
	}

	render(){
		return (
			<article>
				<button className="button-primary club-prospects-export" onClick={this.export}>Export</button>
			</article>
		);
	}
};

export default ProspectsExport;






